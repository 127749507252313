import { SessionToken } from '../services/auth/SessionToken.class';
import { AuthenticationService } from '../services/auth/authentication.service';
import { environment } from '../../../environments/environment';

export const SIDE_CLIENT = 'client';
export const SIDE_ADVISOR = 'advisor';
// Envioronemnt url const
export const ENVIRONMENT_URL_LOCALHOST = 'localhost';
export const ENVIRONMENT_URL_TESTING = 'app.beta.fpalpha.app';
export const ENVIRONMENT_URL_PRODUCTION = 'app.fpalpha.com';
export const ENVIRONMENT_URL_DEVELOPMENT = 'api.dev.fpalpha.xyz';

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
  var definitions = search.split('&');
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return (prop && prop in params) ? params[prop] : params;
}

export function urlPrependRootContext(url: string): string {

  let prependSegment = getSideByContextInPriority();
  if (prependSegment == 'client' || prependSegment == 'advisor') {
    return `/${prependSegment}${url}`;
  } else {
    console.log("ERROR. Coudn't find segment on current path");
    return url;
  }

}

export function getLastUrlSegment(url: string) {
  let urlSections = url.split('/');
  if (urlSections.includes("areas")) return "areas";
  return urlSections.pop();
}

export function isUrlSubsectionOf(url: string, side: string, section: string): boolean {
  let urlSections = url.split('/');
  let sidePos = urlSections.findIndex(value => value == side);
  let sideAndOn = urlSections.slice(sidePos);
  return Boolean(sideAndOn.findIndex(value => value == section) >= 1)
}

export function isTheUrlSubsectionAnyOf(url: string, subsections: ({side: string, section?: string})[]): boolean {
  let urlSections = url.split('/');
  let isUrlSubsection: boolean = false;
  subsections.map(subsection => {
    let sidePos = urlSections.findIndex(value => value == subsection.side);
    let sideAndOn = urlSections.slice(sidePos);
    isUrlSubsection = isUrlSubsection || (sideAndOn.findIndex(value => value == subsection.section) >= 1) || (!subsection.section && sidePos>=0);
  })
  return isUrlSubsection;
}



export function getFirstUrlSegemnt(url: string) {
  return url.split('/')[1];
}

export function getSideByContextInPriority(): string {
  // 1st try get data from session
  let contextSide = '';
  let token = SessionToken.getFromLocalStorage();
  if (token) {
    contextSide = token.userType;
  } else if (sessionStorage.getItem('userType')) {
    contextSide = sessionStorage.getItem('userType');
  } else {
    contextSide = getFirstUrlSegemnt(window.location.pathname);
  }
  if (contextSide == 'client' || contextSide == 'advisor') {
    return contextSide;
  } else {
    console.log("ERROR. Coudn't resolve user side by any context");
    return '';
  }

}
export function getEndPointUrlByContext(endpoint: string, authServiceContext: AuthenticationService = null): string {

  let prependSide = '';

  if (authServiceContext == null) {
    let userSide = getSideByContextInPriority();
    prependSide = userSide == 'advisor' ? 'apiAdvisor' : userSide == 'client' ? 'apiClient' : 'api';
  } else {
    prependSide = authServiceContext.isAdvisor() ? 'apiAdvisor' : authServiceContext.isClient() ? 'apiClient' : 'api';
  }

  let endpointToFind = prependSide + endpoint;
  let endponintUrl = environment[endpointToFind] ? environment[endpointToFind] : '';
  if (!Boolean(endponintUrl)) console.log("ERROR. Endpoint not found: " + endpointToFind);
  return endponintUrl;
}

export function UrlParamEditor(url: string, param: string, paramValue: string): string {
  var _url = new URL(url);
  var search_params = new URLSearchParams(_url.search);
  search_params.set(param, paramValue);
  _url.search = search_params.toString();
  return _url.toString()
}

export function isUrlOutOfClientOrAdvisorContext(url:string): boolean {
  let urlSections = url.split('/');
  return !(urlSections.includes(SIDE_CLIENT) || urlSections.includes(SIDE_ADVISOR) || urlSections.includes('hub') || urlSections.includes('hub-home'));
}

export function urlHelperIsInEnvironment(enviornmentUrlConst: string): boolean {
  let location = window.location.hostname;
  return location === enviornmentUrlConst;
}
