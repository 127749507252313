//Angular
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAdvisorSubmittedComponent } from './modal-advisor/modal-advisor.component';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Area } from 'app/shared/helpers/areas.catalog';
import html2canvas from 'html2canvas';
import { ProspectReport } from './report.class';
import { CONST_DISCLAIMER } from 'app/views/reports/stringConstants';
import { getRandomInt } from 'app/shared/helpers/utils';
import { FullPageSpinnerComponent } from 'app/shared/components/full-page-spinner/full-page-spinner.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-q-new-prospect-submitted',
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss'],
})
export class QNewProspectSubmittedComponent implements OnInit {

    public statusColor: string;

    public advisorProperties: any = {};
    public formattedPhoneNumber: string = '';

    public prospectResponse: any = {};
    public recomendations: ({area: Area, recs: string[], areaScore: number, areaScoreTitle: string, areaScoreColor: number[]}[]) = [];
    public score: number = 0;

    public isLoadingPdf: boolean = false;
    public numberOfRecs: number = getRandomInt(30, 80);

    public prospectDisclaimer: string = '';

    public areaScores: any = {};

    public backgroundImage: string = '';

    public advisorName: string = '';

    public TITLE_AREA_SCORE: any = {
      'Tax Planning':'Tax Wellness Score',
      'Estate Planning':'Estate Wellness Score',
      'Home Insurance':'Home Insurance Wellness Score',
      'Auto Insurance':'Auto Insurance Wellness Score',
      'Disability Insurance':'Disability Insurance Wellness Score',
      'Long Term Care':'Long Term Care Wellness Score',
      'Life Insurance':'Life Insurance Wellness Score',
      'Investment and Retirement':'Investment and Retirement Wellness Score',
    }

  constructor(public dialog: MatDialog,
              public http: HttpClient) { }

  ngOnInit() {
    document.title = "Discover Your Planning Score";
    this.advisorProperties = JSON.parse(localStorage['advisorResponse']);

    if('phone' in this.advisorProperties && Boolean(this.advisorProperties.phone)){
      let phonePartOne   =  this.advisorProperties.phone.substring(0, 3);
      let phonePartTwo   =  this.advisorProperties.phone.substring(3, 6);
      let phonePartThree =  this.advisorProperties.phone.substring(6, 10);
      this.formattedPhoneNumber = `(${phonePartOne}) ${phonePartTwo}-${phonePartThree}`;
    }

    this.prospectDisclaimer = Boolean(this.advisorProperties.leadDisclaimer) ? this.advisorProperties.leadDisclaimer : CONST_DISCLAIMER;
    this.advisorName =  Boolean(this.advisorProperties.fullName) ? this.advisorProperties.fullName : '';

    this.loadProspectsRecs();

  }


  getStautsColor(area) {


  }

  contactMeModal(){
    const dialogRef = this.dialog.open(ModalAdvisorSubmittedComponent, {
      disableClose: false,
      panelClass: 'modal-dialog-questionnaire',
      height: '490px',
      width: '750px',
    });
  }

  loadProspectsRecs(){
    this.openFullPageLoader();
    this.prospectResponse = JSON.parse(localStorage.getItem('prospectResponse'));

    let baseURl: string = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://api.fpalpha.com' : 'https://api.beta.fpalpha.app';

    this.http.get<any>(environment.prospectAdvisor + this.prospectResponse.idHash).subscribe(prospectResponse => {
      this.prospectResponse.clientIDFirstName = prospectResponse.clientIDFirstName;
      this.prospectResponse.clientIDLastName = prospectResponse.clientIDLastName;
    });

    setTimeout(() =>{
      this.http.get(`${baseURl}/v1/advisor/prospect/lead-report/${this.prospectResponse.idHash}`).subscribe(response => {
        //Load score
        this.score = response['score'];
        //this.score = 63;
        this.calculateStatusColor();
        // Transform the response into a new format
        let areaRecs = response['recommendations'];
        this.areaScores = response['areaScores'];
        Object.keys(areaRecs).map(areaId => {
          this.recomendations.push({
            area: Area.findAreaById(areaId),
            recs: areaRecs[areaId],
            areaScore: this.areaScores[areaId],
            areaScoreTitle: this.getAreaScoreTitle(this.areaScores[areaId]),
            areaScoreColor: this.getScoreColor(this.areaScores[areaId]),
          })
        });


        if(Object.keys(areaRecs).length === 0 || this.score == 0){
          this.loadProspectsRecs();
        }

      })
      this.fullPageSpinnerModal.close();
    },3000)

  }

  getAreaScoreTitle(score){
    let areaScoreTitle = '';
    if(score <= 33.33){
      areaScoreTitle = 'Needs attention';
    }
    if(score > 33.33 && score <= 50){
      areaScoreTitle = 'You can improve';
    }
    if(score > 50 && score <= 100){
      areaScoreTitle = 'A few details';
    }
    return areaScoreTitle;
  }

  async downloadReport(){

    this.isLoadingPdf = true

    let canvasScore =  await html2canvas(document.getElementById('scoreCard'), { scale: 2, useCORS: true });
    let canvasContact =  await html2canvas(document.getElementById('contactCard'), { scale: 2, useCORS: true });

    let report = await ProspectReport.generate({
      logoUrl: this.advisorProperties.companyLogo,
      wellnessCardUrl: canvasScore.toDataURL(),
      contactCardUrl: canvasContact.toDataURL(),
      disclaimer: this.prospectDisclaimer,
      recomendations: this.recomendations,
      clientsName: this.prospectResponse.clientIDFirstName + ' ' + this.prospectResponse.clientIDLastName,
      numberOfRecs: this.numberOfRecs
    });

    saveAs(report.doc.output('blob'), `${this.prospectResponse.clientIDFirstName} report`);

    this.isLoadingPdf = false;
  }


  calculateStatusColor(){
    if(Number(this.score) > 1 && Number(this.score) <= 9.9999) this.statusColor = 'danger-bar';
    if(Number(this.score) >= 10 && Number(this.score) <= 19.9999) this.statusColor = 'warn-bar-10';
    if(Number(this.score) >= 20 && Number(this.score) <= 29.9999) this.statusColor = 'warn-bar-20';
    if(Number(this.score) >= 30 && Number(this.score) <= 39.9999) this.statusColor = 'warn-bar-30';
    if(Number(this.score) >= 40 && Number(this.score) <= 49.9999) this.statusColor = 'warn-bar-40';
    if(Number(this.score) >= 50 && Number(this.score) <= 59.9999) this.statusColor = 'warn-bar';
    if(Number(this.score) >= 60 && Number(this.score) <= 69.9999) this.statusColor = 'ok-bar-60';
    if(Number(this.score) >= 70 && Number(this.score) <= 79.9999) this.statusColor = 'ok-bar-70';
    if(Number(this.score) >= 80 && Number(this.score) <= 89.9999) this.statusColor = 'ok-bar-80';
    if(Number(this.score) >= 90 && Number(this.score) <= 99) this.statusColor = 'ok-bar-90';
    if(Number(this.score) === 100) this.statusColor = 'ok-bar-90';
  }

  getScoreColor(score){
    let statusColor = [];
    if(Number(score) > 1 && Number(score) <= 9.9999) statusColor = [255, 104, 89];
    if(Number(score) >= 10 && Number(score) <= 19.9999) statusColor = [255, 89, 38];
    if(Number(score) >= 20 && Number(score) <= 29.9999) statusColor = [255, 119, 28];
    if(Number(score) >= 30 && Number(score) <= 39.9999) statusColor = [255, 146, 19];
    if(Number(score) >= 40 && Number(score) <= 49.9999) statusColor = [255, 177, 9];
    if(Number(score) >= 50 && Number(score) <= 59.9999) statusColor = [255, 208, 68];
    if(Number(score) >= 60 && Number(score) <= 69.9999) statusColor = [212, 205, 13];
    if(Number(score) >= 70 && Number(score) <= 79.9999) statusColor = [168, 204, 26];
    if(Number(score) >= 80 && Number(score) <= 89.9999) statusColor = [124, 204, 39];
    if(Number(score) >= 90 && Number(score) <= 99) statusColor = [81, 205, 53];
    if(Number(score) === 100) statusColor = [81, 205, 53];
    return statusColor;
  }

  public fullPageSpinnerModal;

  openFullPageLoader(){
      this.fullPageSpinnerModal = this.dialog.open(FullPageSpinnerComponent, {
        disableClose: true,
        backdropClass: 'modal-dark-background',
        data: 'Preparing your results...',
      });
      this.fullPageSpinnerModal.afterClosed().subscribe(response => {

      })
    }

}
