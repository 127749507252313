import { getRandomStr } from "app/shared/helpers/utils";

export function divideFamilyGraph(familyGraph: any, estateFlag?: boolean) {
  let familyPages = [];

  let addPage = () =>{
    let page = {
      title: familyGraph[0].title,
      data: [],
      uid: getRandomStr(12),
      type: 'GRAPH_MIX',
    }
    familyPages.push(page);
  }
  addPage();

  let concatFamilyData = (section: any) => {
    familyPages[familyPages.length - 1]['data'].push(section);
    console.log('familyPages pussshhh after', JSON.parse(JSON.stringify(familyPages[familyPages.length - 1])));
  }

  // console.log('familyGraph to divide**', JSON.parse(JSON.stringify(familyGraph)));
  let dictionaryRows = {
    1: 5,
    2: 4,
    3: 4
  }
  let rowsAvailable = estateFlag ? dictionaryRows[familyGraph[0].data.length] : 5; //5;
  let maxRowsPerPage = rowsAvailable;

  let rowsByFamilySection = 0;
  familyGraph[0].data.map((sectionFamily: any, idx) =>{
    rowsByFamilySection = sectionFamily.grid.rowsNodesById.length;
    // console.log('rowsAvailableeeee', rowsAvailable, 'sectionFamily to up', sectionFamily);

    if(rowsByFamilySection <= dictionaryRows[familyGraph[0].data.length]){
      if(rowsAvailable >= 0 && (rowsAvailable >= rowsByFamilySection)) {
        concatFamilyData(sectionFamily);
        rowsAvailable -= sectionFamily.grid.rowsNodesById.length;
      }else{
        let copyOfSection = JSON.parse(JSON.stringify(sectionFamily));
        let copyOfGridNodes = JSON.parse(JSON.stringify(copyOfSection.grid.rowsNodesById))
        if(rowsAvailable > 0) {

          let gridToConcat = copyOfGridNodes.splice(0, rowsAvailable);
          sectionFamily.grid.rowsNodesById = gridToConcat;
          concatFamilyData(JSON.parse(JSON.stringify(sectionFamily)));
        }
        sectionFamily.grid.rowsNodesById = copyOfGridNodes;
        addPage();
        concatFamilyData(JSON.parse(JSON.stringify(sectionFamily)));
        rowsAvailable = estateFlag ? ((familyGraph[0].data.length === 1) ? 5 : 4) : 5;
        rowsAvailable -= sectionFamily.grid.rowsNodesById.length;
        // console.log('rowsAvailable after concat***', rowsAvailable);
      }
    }else{
      //numero de secciones de 5 rows (lo que entra en una pagina)
      let subsectionsRows = Math.ceil(sectionFamily.grid.rowsNodesById.length/(estateFlag ? dictionaryRows[familyGraph[0].data.length] : 5));
      let copyOfSection = JSON.parse(JSON.stringify(sectionFamily));
      let copyOfGridNodes = JSON.parse(JSON.stringify(copyOfSection.grid.rowsNodesById));
      // console.log('subsectionsRows', subsectionsRows);

      let parentsCopy = [];
      for(let i = 0; i < subsectionsRows; i++){
        //secciones de 5 rows
        let dataGrid = copyOfGridNodes.splice(0, (estateFlag ? dictionaryRows[familyGraph[0].data.length] : 5));
        let actualRows = familyPages[familyPages.length - 1]['data'].grid?.rowsNodesById?.length ? familyPages[familyPages.length - 1]['data'].grid?.rowsNodesById?.length : 0;
        if((actualRows + dataGrid.length <= maxRowsPerPage)){
          actualRows = 0;
          if(sectionFamily.title === 'Family'){
            let auxSectionFamily = JSON.parse(JSON.stringify(sectionFamily));
            auxSectionFamily.grid.rowsNodesById = dataGrid;

            if(i === 0){
              parentsCopy = JSON.parse(JSON.stringify(dataGrid[0]));
            }else{
              console.log('auxSectionFamily', auxSectionFamily);
              auxSectionFamily.title = 'Children';
              // auxSectionFamily.grid.rowsNodesById.unshift(parentsCopy);
            }
            concatFamilyData(JSON.parse(JSON.stringify(auxSectionFamily)));
            actualRows = auxSectionFamily.grid.rowsNodesById.length;
          }
          if(actualRows >= maxRowsPerPage){
            addPage();
            // console.log('crea una pagggg');
          }else{
            rowsAvailable = maxRowsPerPage - actualRows;
            // console.log('no añade pagina y quedan ', rowsAvailable, ' rows en', JSON.parse(JSON.stringify(familyPages)));
          }
        }
      }
    }
  })

  console.log('family pages**', JSON.parse(JSON.stringify(familyPages)));
  return familyPages;

}
