// Angular
import {Component, OnInit, Input, SimpleChanges} from '@angular/core';

//Project
import { AdvisorService } from '../../../../../../shared/services/advisor.service';
import { Router } from '@angular/router';
import { MatSnackBar} from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { environment } from 'environments/environment';

@Component({
  selector: 'orion-integration-component',
  templateUrl: './orion-integration.component.html'
})

export class OrionIntegrationComponent implements OnInit {

  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;

  public isProduction: any;
  public location: any;
  public clientListView: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar
    ) { }

  ngOnInit() {
    if(sessionStorage.getItem('comesFromIntegration') === 'orion' && this.comeFromLogin){
      this.importMoreClients();
    }

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  connectOrion(){

    let client_id = '';
    let redirectUri = '';

    if(this.location == 'localhost:4200'){
      //Local
      client_id = '1932'
      redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/orion/callback'
    }else if(this.isProduction){
      //Production
      client_id = '1932'
      redirectUri = 'https://app.fpalpha.com/orion/pending'
    }else{
       //Testing
      client_id = '1932'
      redirectUri = 'https://app.beta.fpalpha.app/orion/pending'
    }

    let orionState = (new Date().getTime()).toString();
    localStorage.setItem('orionState', orionState);

    let orionApiUrl = this.isProduction ? 'https://api.orionadvisor.com' : 'https://stagingapi.orionadvisor.com';

    window.location.replace(`${orionApiUrl}/api/oauth/?response_type=code&redirect_uri=${redirectUri}&client_id=${client_id}&state=${orionState}`);
  }

  importMoreClients(){
    this.clientListView = true;

    /*if(typeof this.dialogRef.close === 'function'){
      this.dialogRef.close('cancelled');
    }

    this.router.navigate(['orion/success']);*/
  }

  disconnectOrion() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiOrionDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'Orion disconnected');
          this.snackBarSuccess.open("Your Orion account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.isIntegrated = false;
        },
        error => {
          console.log('Error: Orion disconnect');
          this.snackBarError.open("Couldn't disconnect your Orion account. Try again in a moment, if the problem persists contact support." + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }

}

