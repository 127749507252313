// Angular
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

// Project
import { AdvisorService } from '../../../../../../shared/services/advisor.service';
import { environment } from "../../../../../../../environments/environment";
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-riskalyze-integration',
  templateUrl: './riskalyze-integration.component.html',
  styleUrls: ['./riskalyze-integration.component.scss']
})
export class RiskalyzeIntegrationComponent implements OnInit {

  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;

  public isRiskalyzeConnected: boolean;

  //public isCheckedTaskSync:boolean = false;
  public isDisabledCheckedTaskSync: boolean = false;

  public isProduction: any;
  public location: any;
  public clientListView: boolean = false;

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    //If come from login
    if(sessionStorage.getItem('comesFromIntegration') === 'riskalyze' && this.comeFromLogin){
      this.importMoreClients();
    }

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;

    let integrations = JSON.parse(localStorage.getItem('external-providers-interactions'));
    this.isRiskalyzeConnected = integrations.riskalyze;
    this.getAdvisorData();
  }

  // When back to itegrations list hide client view
  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  getAdvisorData() {
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
        },
        error => {
          console.log(error);
          this.snackBarError.open("Cannot get your data. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        });
  }

  connectRiskalyze(){

      let client_id = '';
      let redirectUri = '';

      if(this.location == 'localhost:4200'){
        //Local
        client_id = 'test-fp-alpha'
        redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/riskalyze/callback&'
      }else if(this.isProduction){
        //Production
        client_id = 'fp-alpha'
        redirectUri = 'https://app.fpalpha.com/riskalyze/pending'
      }else{
         //Testing
        client_id = 'test-fp-alpha'
        redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/riskalyze/callback&'
        //redirectUri = 'https://app.beta.fpalpha.app/riskalyze/pending'
      }

      let riskalyzeState = (new Date().getTime()).toString();
      localStorage.setItem('riskalyzeState', riskalyzeState);

      window.location.replace(`https://pro.riskalyze.com/oauthconnect?response_type=code&state=${riskalyzeState}&client_id=${client_id}&redirect_uri=${redirectUri}`)

  }

  disconnectRiskalyze(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiRiskalyzeDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'Riskalyze disconnected');
          this.snackBarSuccess.open("Your Riskalyze account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.isIntegrated = false;
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Something went wrong disconnecting your Riskalyze account. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }

  onNoClick(): void {
    console.log('Nay');
  }

  importMoreClients(){
    // this.dialogRef.close('cancelled');
    //this.router.navigate(['riskalyze/success']);
    console.log('riskalye***')
    this.clientListView = true;
  }

}
