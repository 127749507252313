import * as io from "socket.io-client";
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from "../helpers/url.helper";

const MAX_RECONNECTION_ATTEMPTS = 2 //reconnectionCycleAttemptsLimit
const MAX_RECONNECTION_CYCLES = 3

export class SocketIoWrapper{

  private static socketInstance: SocketIoWrapper;
  public static onUnstableBehaviour: any;
  public socket: SocketIOClient.Socket
  //
  private smallReconnectionAtemptsCount: number = 0;
  private reconnectionCycleAttemptsCount: number = 0;



  private stablishingConnection: boolean = false; // Flag to avoid a second socket if the first is waiting to be connected

  //Singleton
  private constructor() {}

  //Obtener instancia
  public static getInstance(): SocketIoWrapper {


    if(!Boolean(this.socketInstance) || !Boolean(this.socketInstance.socket)){
      this.socketInstance = new SocketIoWrapper();
      this.socketInstance.initNewSocket();
      this.socketInstance.connectSocket();
    }else if(!this.socketInstance.socket.connected){
      this.socketInstance.stablishingConnection = false;
      this.socketInstance.reconnetSocket();
    }


    return this.socketInstance;

  }

  private connectSocket(){
    console.log(`Socket. Connect. Is Attempt blocked: ${this.stablishingConnection ? 'TRUE' : 'FALSE'}`)
    if(this.stablishingConnection) return;
    this.socket.connect();
    console.log('Socket. connecting')
    this.stablishingConnection = true;
  }

  //Create a new socket
  public initNewSocket(): SocketIOClient.Socket {

    let isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);

    //let isBetaEnv: boolean = (window.location.hostname == "app.beta.fpalpha.app" || window.location.port === "4300");

    //let socketScheme = ( isBetaEnv ? 'https://socket.beta.fpalpha.app' : 'https://socket.beta.fpalpha.app') + `?token=${localStorage.getItem('sessionToken')}`;

    let socketScheme = ( isProduction ?  'https://socket.beta.fpalpha.app' : 'socket.beta.fpalpha.app') + `?token=${localStorage.getItem('sessionToken')}`;

    let socket = io(socketScheme, { transports: ['websocket'], autoConnect: false });

    let iid = Math.floor(Math.random() * 1000);

    socket.on('connect',          ()       => { this.onConnect(iid) });
    socket.on('disconnect',       (reason) => { this.onDisconnect(iid, reason) });
    socket.on('error',            (reason) => { this.onError(iid, reason) });
    socket.on('reconnect_error',  (reason) => { this.onReconnectionError(reason) });
    socket.on('reconnect_failed', (reason) => { this.onReconnectionError(reason) });
    socket.on('connect_error',    (reason) => { this.onConnectError(iid, reason) });

    console.log(`New socket instance: iID[${iid}]`);

    this.socket = socket;

    return socket
  }

  private onConnect(iid?){
    console.log(`Socket. iID[${iid}] [${this.socket.id}]: Connected`);
    this.stablishingConnection = false; // Connection already stablished.
  }

  private onConnectError(iid, reason){
    console.log(`Socket. iID[${iid}]: Connection Error\n Reason: ${reason}`);
    this.stablishingConnection = false;
    this.reconnetSocket();
  }

  private onDisconnect(iid, reason){

    console.log(`Socket. iID[${iid}] [${this.socket.id}]: Disconnected.\n Reason: ${reason}`);

    if(reason == 'io server disconnect' || reason == 'ping timeout' || reason == 'ping timeout' || reason == 'transport close' || reason == 'transport error'){
      this.reconnetSocket();
    }else if(reason == 'io client disconnect'){
      if(this.socket) this.socket.close();
    }else{
      console.log(`Socket. Unmatched case for onDisconnect. Reason: ${reason}`)
    }

  }

  private onError(iid, reason){
    console.log(`Socket. iID[${iid}]. Error:\n ${reason}`);
  }

  private onReconnectionError(reason){
    console.log(`Socket[${this.socket.id}]: Error..\n Reason: ${reason}`);
    this.stablishingConnection = false;
  }

  private callOnUnstableConnectionDetection(){
    console.log('Socket. UNSTABLE CONNECTION DETECTED')
    if(SocketIoWrapper.onUnstableBehaviour != undefined){
      SocketIoWrapper.onUnstableBehaviour();
    }
  }

  private reconnetSocket(){

    console.log(
      `Socket. Reconnecting.
      smallReconnectionAtemptsCount: ${this.smallReconnectionAtemptsCount}
      reconnectionCycleAttemptsCount: ${this.reconnectionCycleAttemptsCount}
      Is Attempt blocked: ${this.stablishingConnection ? 'TRUE' : 'FALSE'}
      `)
      console.log('Socket Defined as:', this.socket);

    if(this.smallReconnectionAtemptsCount < MAX_RECONNECTION_ATTEMPTS){
      this.smallReconnectionAtemptsCount++;
      this.connectSocket();
    }else if(this.reconnectionCycleAttemptsCount < MAX_RECONNECTION_CYCLES){
      this.smallReconnectionAtemptsCount = 0;
      this.reconnectionCycleAttemptsCount++;

      if(this.socket) this.socket.close();
      this.initNewSocket();
      this.connectSocket();
    }else{
      if(this.socket) this.socket.close();
      this.callOnUnstableConnectionDetection();
    }
  }

  public close(){
    if(this.socket) this.socket.close();
    this.smallReconnectionAtemptsCount = 0;
    this.reconnectionCycleAttemptsCount = 0;
  }

}
