// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { environment } from 'environments/environment';

import { AdvisorService } from '../../../../../../shared/services/advisor.service';

//Project
@Component({
  selector: 'wealthbox-integration-component',
  templateUrl: './wealthbox-integration.component.html'
})

export class WealthboxIntegrationComponent implements OnInit {

  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;

  public isProduction: any;
  public location: any;
  public clientListView: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar
    ) { }


  ngOnInit() {
    //If come from login
    if(sessionStorage.getItem('comesFromIntegration') === 'wealthbox' && this.comeFromLogin){
      this.importMoreClients();
    }

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
  }


  // When back to itegrations list hide client view
  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  connectWealthbox(){

    let client_id = '';
    let redirectUri = '';

    if(this.location == 'localhost:4200'){
      //Local
      client_id = 'e6ae05b601293124f36e9e05e4606746da942fa4937adde17d068ec3916c4505'
      redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/wealthbox/callback'
    }else if(this.isProduction){
      //Production
      client_id = 'db6ede4d3c474908bc42e12a70a3d566d782aaf27a1b1118274f93860b5771bb'
      redirectUri = 'https://app.fpalpha.com/wealthbox/pending'
    }else{
       //Testing
      client_id = 'e6ae05b601293124f36e9e05e4606746da942fa4937adde17d068ec3916c4505'
      redirectUri = 'https://app.beta.fpalpha.app/wealthbox/pending'
    }

    window.location.replace(`https://app.crmworkspace.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirectUri}&response_type=code&scope=login+data`)
  }

  goBack() {
  }

  goForward() {

  }

  importMoreClients(){
    // this.dialogRef.close('cancelled');
    //this.router.navigate(['wealthbox/success']);
    this.clientListView = true;
  }

  disconnectWealthbox() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiWealthboxDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'Wealthbox disconnected');
          this.snackBarSuccess.open("Your Wealthbox account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.isIntegrated = false;
        },
        error => {
          console.log('Error: Wealthbox disconnect');
          this.snackBarError.open("Couldn't disconnect your Wealthbox account. Try again in a moment, if the problem persists contact support." + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }

}

