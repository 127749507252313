import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { pushIntoArrayIfObjectIsUnique } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-card-estate',
  templateUrl: './card-estate.component.html',
  styleUrls: ['./card-estate.component.scss']
})
export class CardEstateComponent implements OnInit, AfterViewInit {
  @Input() titleCard: string;
  @Input() data: any;
  @Input() isPrintable: boolean;
  @Input() resetGraphSignal: boolean = false;

  public orientation: string = 'horizontal';
  public tableData: any = [];

  constructor() { }

  ngAfterViewInit(): void {
    if(this.data){
      this.orientation = this.data.type;
      this.tableData = [];
    }
  }

  ngOnInit(): void {
    console.log('dataCard', this.data, ' isPrintable', this.isPrintable, ' titleCard', this.titleCard);
    if(this.data){
     // this.orientation = this.data.type;
    }
  }

}
