import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html'
})
export class PendingComponent implements OnInit, AfterViewInit {

  public paramCode = ''
  public state = '';
  public isProduction: any;
  public location: any;

  public currentIntegration = '';

  constructor( private http: HttpClient,
               private route: ActivatedRoute,
               public snackBarSuccess: MatSnackBar,
               public snackBarError: MatSnackBar,
               public snackBarWarning: MatSnackBar,
               private router: Router) {
                this.route.queryParams.subscribe(params => {
                    this.paramCode = params['code'];
                    this.state = params['state'];
                });

              }

  ngOnInit() {

        console.log("ENTRE AL PENDING")
        let location = window.location.hostname;
        this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
        location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;

        let pathname = window.location.pathname;
        console.log("MY PATH NAME IS", pathname);

        if(pathname == '/salesforce/pending'){
          this.currentIntegration = 'salesforce';
        }

        if(pathname == '/wealthbox/pending'){
          this.currentIntegration = 'wealthbox'
        }

        if(pathname == '/riskalyze/pending'){
          this.currentIntegration = 'riskalyze'
        }

        if(pathname == '/precisefp/pending'){
          this.currentIntegration = 'precisefp'
        }

        if(pathname == '/orion/pending'){
            this.currentIntegration = 'orion'
          }
        sessionStorage.setItem('comesFromIntegration', this.currentIntegration);

  }

  ngAfterViewInit(){

    if(this.currentIntegration == 'riskalyze'){
      let tempCode = localStorage.getItem('riskalyzeState');

      //ONLY FOR RISKALYZE
      if(!this.isProduction){
        let [code, state] = this.paramCode.split(',state=');
        this.paramCode = code;
        this.state = state;
      }

      if(tempCode !== this.state){
        this.router.navigate(['riskalyze/fail']);
        return;
      }

    }

    if(this.currentIntegration == 'orion'){
      let tempCode = localStorage.getItem('orionState');

      // if(!this.isProduction){
      //   let [code, state] = this.paramCode.split(',state=');
      //   this.paramCode = code;
      //   this.state = state;
      //   console.log('El STATE', this.state)
      // }

      if(tempCode !== this.state){
        console.log('C MURIO ===>', this.state)
        this.router.navigate(['orion/fail']);
        return;
      }
    }


    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let redirectUri = '';

    if(this.location == 'localhost:4200'){
        //Local
        redirectUri = `https://api.beta.fpalpha.app/v1/devtools/${this.currentIntegration}/callback`
      }else if(this.isProduction){
        //Production
        redirectUri = `https://app.fpalpha.com/${this.currentIntegration}/pending`
      }else{
        //Testing
        redirectUri = `https://app.beta.fpalpha.app/${this.currentIntegration}/pending`
        //redirectUri = `https://api.beta.fpalpha.app/v1/devtools/${this.currentIntegration}/callback`
        //redirectUri = `https://app.beta.fpalpha.app/${this.currentIntegration}/pending`
      }

    let body = {
        code: this.paramCode,
        redirectUri: redirectUri
    }

    let endpoint = '';

    if(this.currentIntegration == 'wealthbox'){
      endpoint = environment.apiWealthboxOauthExchange;
    }

    if(this.currentIntegration == 'salesforce'){
      endpoint = environment.apiSalesforceOauth;
    }

    if(this.currentIntegration == 'riskalyze'){
      endpoint = environment.apiRiskalyzeOauth;
    }

    if(this.currentIntegration == 'precisefp'){
      endpoint = environment.apiPreciseFPOauth;
    }

    if(this.currentIntegration == 'orion'){
      endpoint = environment.apiOrionOauth;
    }



    this.http.post<any>(`${endpoint}`, body, options)
      .subscribe(
        success => {
          console.log(success, `Connected to ${this.currentIntegration}`);
          this.snackBarSuccess.open(`You will be taken to ${this.currentIntegration} to complete the connection process.`, "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });
          this.router.navigateByUrl(`/settings/external-providers-interactions?currentIntegration=${this.currentIntegration}`);

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot connect. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );

  }
}
