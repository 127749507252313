import { Component, OnInit } from '@angular/core';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';

@Component({
  selector: 'app-wealthbox-instructions',
  templateUrl: './wealthbox-instructions.component.html',
  styleUrls: ['./wealthbox-instructions.component.css']
})
export class WealthboxInstructionsComponent implements OnInit {
  public isProduction: any;
  public location: any;

  constructor() { }

  ngOnInit(): void {
    let location = window.location.hostname;
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
  }

  connectWealthbox(){

    let client_id = '';
    let redirectUri = '';

    if(this.location == 'localhost:4200'){
      //Local
      client_id = 'e6ae05b601293124f36e9e05e4606746da942fa4937adde17d068ec3916c4505'
      redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/wealthbox/callback'
    }else if(this.isProduction){
      //Production
      client_id = 'db6ede4d3c474908bc42e12a70a3d566d782aaf27a1b1118274f93860b5771bb'
      redirectUri = 'https://app.fpalpha.com/wealthbox/pending'
    }else{
       //Testing
      client_id = 'e6ae05b601293124f36e9e05e4606746da942fa4937adde17d068ec3916c4505'
      redirectUri = 'https://app.beta.fpalpha.app/wealthbox/pending'
    }

    window.location.replace(`https://app.crmworkspace.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirectUri}&response_type=code&scope=login+data`)
  }

}
