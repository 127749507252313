import { Component, Input, OnInit } from '@angular/core';
import { getRandomStr, readFromStoragedObject, scaleCanvas } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { ClientDataService } from 'app/shared/services/client-data-service';
import { balanceSheetCutter } from '../balance-sheet/components/balanceSheetCutter';
import { updateFamilyGraph } from '../../estate-snapshot-v3/family-box/updateFamilyGraph';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { PdfFormattedPageConfig } from '../../pdf-formatted-page/pdf-formatted-page.component';
import { divideFamilyGraph } from '../../estate-snapshot-v3/estate-snapshot-report/divideFamilyGraph';
import html2canvas from 'html2canvas';
import { CONST_STATES_KEY } from 'app/views/questionnaires/Questionnaire.constants';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

  @Input() pdfDataInput: any;
  @Input() scenarios: any;

  public estateLabData: any;
  public clientId
  public familyResponse;
  public responseStatusStrategies
  public graphData
  public graphDataFirstDeath
  public graphDataPdfArray;
  public headersRelationAssets: string[] = ['Beneficiaries', 'Relationship', 'Assets Received'];
  public dictionaryRelationAssets: any = [
    'beneficiary',
    'relationship',
    'total'
  ];
  // taxes
  public taxesStrategies
  public lastExemptionFederal
  public lastExemptionFederalSpouse
  public exemptionFederal
  public exemptionState
  public withStrategies
  public currentScenario: number = 1;
  //disclaimer
  public fullDisclaimer: any;
  public formattedPagerConfig: PdfFormattedPageConfig = new PdfFormattedPageConfig();
  public sections: any = {};
  public isLoading: boolean = false;
  public renderReady:boolean = false; //Tell to puppeteer to start with the pdf conversion.
  public balanceDataFormatted: any;
  public statesArray = CONST_STATES_KEY;
  public livingState: any;
  public hasSecondDeath: boolean = true;
  public hasSecondDeathReady: boolean = false;
  public scenariosDataCharged: boolean = false;

  constructor(
    public advisorService: AdvisorService,
    private settingsServive: ESettingsService,
    public clientService: ClientDataService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.scenariosDataCharged = false;
    //formattedPagerConfig init default values
    this.formattedPagerConfig.title = "";
    this.formattedPagerConfig.subtitle = "";
    this.formattedPagerConfig.footerNote = "See full disclaimer at the end";

    let onPdfDataResponse = async (response) => {
      this.estateLabData = response;
      let sectionsOfScenarios = this.estateLabData.components.filter(section => {
        if(section.sectionId.includes('GRAPH') || (section => section.sectionId.includes('TAXES'))){
          return section;
        }
      });
      sectionsOfScenarios.map(section => {
        if(section.sectionId.includes('GRAPH') || section.sectionId.includes('TAXES')){
          this.sections[section.sectionId] = section;
        }
      });
      console.log('sections from oninit', this.sections);
      console.log('sectionsOfScenarios', sectionsOfScenarios);
      let dataBalance = this.estateLabData.components.find(section => section.sectionId  === 'BALANCE_SHEET');
      console.log('dataBalance balancee', dataBalance);
      let familyData = this.estateLabData.components.find(section => section.sectionId  === 'FAMILY_TREE');
      console.log('familyData response', familyData);
      this.livingState = familyData.livingState;
      this.fullDisclaimer = response.disclaimer.data.paragraph;
      this.formattedPagerConfig.title = response.client.fullName;
      this.formattedPagerConfig.logoURL = response.client.logo;
      this.fullDisclaimer = response.disclaimer.data.paragraph;
      this.loadFamilyData(familyData);
      let graphData = this.estateLabData.components.find(section => section.sectionId.includes('GRAPH'));
      this.hasSecondDeath = Boolean(Object.keys(graphData.data.secondDeath).length);
      setTimeout(()=>{
        this.hasSecondDeathReady = true;
      }, 100);
      console.log('DATAAAAA***', this.hasSecondDeath, Boolean(Object.keys(graphData.data.secondDeath).length), graphData);


      if(dataBalance) {
        console.log('Data por procesar', JSON.parse(JSON.stringify(dataBalance)));
        // this.balanceDataFormatted = await balanceSheetCutter(dataBalance.data[0]);
       /*  dataBalance.data[0].today.doughnutLiabilitiesBreakdown = null;
        dataBalance.data[0].today.tableLiabilities = null; */
        setTimeout(() => {
          console.log('dataBalance sin datos', JSON.parse(JSON.stringify(dataBalance)));
          this.balanceDataFormatted =  balanceSheetCutter(dataBalance.data[0]);
          this.isLoading = false;
        }, 700);
      }else{
        //if dataBalance is undefined
        this.isLoading = false;
      }

      setTimeout(()=>{
        this.scenariosDataCharged = true;
        this.generateCanvases();
      }, 1000);
    }

    //Load the data from the input or make a request to the backend.
    if(this.pdfDataInput){
      onPdfDataResponse(this.pdfDataInput);
    }else{ //Para el preview de el tab de report (solo dev)
      console.log('aqui en datos de plataforma')
      this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
      this.advisorService.getExternalEstateLab(this.clientId, this.scenarios).then((response: any) => {
        onPdfDataResponse(response);
      },error =>{
        console.log('Error in getExternalBalanceSheet', error);
        this.isLoading = false;
      })
    }
    // Facilita la vida, esta para testing
    // Solament es necesario generar un token y especificar los escenarios.
    // this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    // await this.advisorService
    // .getExternalEstateLab(this.clientId, [1, 2, 3], "2b9ecb709e61bd26be1881496bd88764a5d1f1c1e85730d71f6053a4a77adc65640e1813620e370578312bf8a4fd1c36f48862ec8bcf09d706bde2dea6036255fe8cc448b2f767a3369510373803685701b0a8f863000b50f4814dfe6e7a84b03ca3e9ca0883ebf8c081ee09494102ee4fb97b2c16676d35ab1948c222db5e0a")
    // .then((response: any) => {
    //   console.log("response cool", response);
    //   onPdfDataResponse(response);
    // });
  }

  async generateCanvases(){
    console.log('generateCanvases start');
    let canvasPromises: Promise<any>[] = [];
    let keys = [
      'todayView',
      'firstDeath',
    ]
    if(this.hasSecondDeath){
      keys.push('secondDeath')
    }

    let ids = [];

    this.scenarios.map(num=>{
      ids.push(...keys.map(key=>{return key+'_'+num}))
    })

    console.log('generateCanvases keys', ids);

    ids.map(id=>{
      console.log('generateCanvases element', document.getElementById(id));
      let canvasPromise = html2canvas(document.getElementById(id), { scale: 2, useCORS: true }).then(canvas=>{
        scaleCanvas(canvas, 960, 648);
        document.getElementById(id+'_canvas').appendChild(canvas);
      });
      canvasPromises.push(canvasPromise)
    })
    const start = Date.now();

    await Promise.all(canvasPromises);
    const end = Date.now();
    console.log(`Execution generateCanvases: ${end - start} ms`);

    setTimeout(()=> {
      this.renderReady = true;
      console.log('generateCanvases in report', this.renderReady, JSON.parse(JSON.stringify(canvasPromises)));
    }, 500 * canvasPromises.length);
  }

  async loadFamilyData(existentData?){
    let familyData = existentData;
    console.log('familyData', familyData);
    familyData.data.map((section: any, idx) => {
      console.log('section family', section);
      familyData.data[idx] = updateFamilyGraph(section);
    });
    let familyGraphUpdated =  [{
      title: '',
      data: familyData.data,
      uid: getRandomStr(12),
      type: 'GRAPH_MIX',
    }]
    if(familyGraphUpdated){
      this.familyResponse =  divideFamilyGraph(familyGraphUpdated, true);
      console.log('this.familyResponse updated', this.familyResponse);
    }
    // this.familyResponse = familyData;
    /* if(this.familyResponse.data.length === 1){
      this.familyResponse.data.push({});
    } */
    console.log('this.familyResponse updated', this.familyResponse);
  }

  getSection(sectionNumber: number, type: ('TAXES' | 'GRAPH')){
    let sectionKey = `${type}:${sectionNumber}`;
    /* if(this.sections[sectionKey] === undefined){
      this.scenariosDataCharged = false;
      if(this.estateLabData){
        this.sections[sectionKey] = this.estateLabData.components.find(section => section?.sectionId  === `${type}:${sectionNumber}`);
      }
    }else{
      this.scenariosDataCharged = true;
    }
    return this.sections[sectionKey]; */
  }

  findState(keyState: string): string{
    let state: any = this.statesArray.find(element => element.key === keyState);
    return state ? state.value : 'State not found';
  }
}
