import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';

@Component({
  selector: 'app-orion-instructions',
  templateUrl: './orion-instructions.component.html',
  styleUrls: ['./orion-instructions.component.css']
})
export class OrionInstructionsComponent implements OnInit {

  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;

  public isProduction: any;
  public location: any;
  public clientListView: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar
    ) { }

  ngOnInit() {

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  connectOrion(){

    let client_id = '';
    let redirectUri = '';

    if(this.location == 'localhost:4200'){
      //Local
      client_id = '1932'
      redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/orion/callback'
    }else if(this.isProduction){
      //Production
      client_id = '1932'
      redirectUri = 'https://app.fpalpha.com/orion/pending'
    }else{
       //Testing
      client_id = '1932'
      redirectUri = 'https://app.beta.fpalpha.app/orion/pending'
    }

    let orionState = (new Date().getTime()).toString();
    localStorage.setItem('orionState', orionState);

    let orionApiUrl = this.isProduction ? 'https://api.orionadvisor.com' : 'https://stagingapi.orionadvisor.com';

    window.location.replace(`${orionApiUrl}/api/oauth/?response_type=code&redirect_uri=${redirectUri}&client_id=${client_id}&state=${orionState}`);
  }
}
