import { Question } from 'app/shared/model/questionnaires/Questionnaires.model'
import { FormGroup, Validators } from '@angular/forms'


export const maritalStatusCatalog = [
    {
        value: 'Single',
        label: 'Single'
    },
    {
        value: 'Married',
        label: 'Married'
    },
    {
        value: 'Widowed',
        label: 'Widowed'
    },
    {
        value: 'Divorced',
        label: 'Divorced'
    },
    {
        value: 'Domestic Partner',
        label: 'Domestic Partner'
    },
]

export const netWorthRanges = [
    {
        value: '[0-1]',
        label: '<1 Million'
    },
    {
        value: '[1-3]',
        label: '1 - 3 Million'
    },
    {
        value: '[3-5]',
        label: '3 - 5 Million'
    },
    {
        value: '[5-10]',
        label: '5 - 10 Million'
    },
    {
        value: '[10-20]',
        label: '10 - 20 Million'
    },
    {
        value: '[20-]',
        label: '>20 Million'
    }
]

export const maritalStatusesWithCoClient = [
    'Married', 'Domestic Partner'
]

export const validateStringField = (field: string) => {
    if(field == 'undefined' || field == '' || field == 'null'){
        return ''
    }
    return field
}

export const validateBooleanField = (field: string) => {
    if(field == 'Yes'){
        return 'Yes'
    }
    if(field == 'No'){
        return 'No'
    }
    if(field == 'undefined'){
        return 'undefined'
    }
    return field
}

export const validateIfIsRange = (field: string) => {
    return field == 'range'
}

export const validateMaritalStatus = (field: string) => {
    if(field == 'undefined'){
        return 'Single';
    }
    else{
        return field;
    }
}

export const adjustEpochForTimezone = (epochTime) => {
    const date = new Date(parseInt(epochTime));
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneInHours = timezoneOffset / 60;
    if (timezoneInHours === 7 || timezoneInHours === 8 || timezoneInHours === 9) {
        const adjustedEpochTime = parseInt(epochTime) + (3 * 60 * 60 * 1000);
        return adjustedEpochTime.toString();
    }
    return epochTime;
}

export const  validateDate = (dateString: string): Date | string => {
    let date;

    if(dateString == '0'){
        return ''
    }

    let adjustedForTimezoneDate  = adjustEpochForTimezone(dateString);
    
    // Intenta parsear como fecha ISO
    date = new Date(adjustedForTimezoneDate);
    if (!isNaN(date.getTime())) {
        return date;
    }

    // Intenta parsear como timestamp
    const timestamp = parseInt(dateString, 10);
    if (!isNaN(timestamp)) {
        date = new Date(parseInt(adjustedForTimezoneDate));
        if (!isNaN(date.getTime())) {
            return date;
        }
    }

    // Intenta parsear como fecha completa
    date = new Date(Date.parse(adjustedForTimezoneDate));
    if (!isNaN(date.getTime())) {
        return date;
    }

    throw new Error('Invalid date format');
}


export const clientPersonalInformationDTO = (clientFromService: any)=>{

    const clientToForm = {}
    clientFromService.data.forEach(( item) => {
        clientToForm[item.name] = item.answer
    })

    return {
        clientMaritalStatus : validateMaritalStatus(clientToForm['clientIDMaritalStatus']),
        clientFirstName : validateStringField(clientToForm['clientIDFirstName']),
        clientLastName : validateStringField(clientToForm['clientIDLastName']),
        clientEmail : validateStringField(clientToForm['email']),
        clientGender : clientToForm['clientIDGender'] ,
        clientBirthDate : validateDate(clientToForm['clientIDBirthday']),
        clientState : validateStringField(clientToForm['clientIDLivingState']),
        clientIsRetired : validateBooleanField(clientToForm['clientIDRetiredOrNotWorking']),
        clientOccupation : validateStringField(clientToForm['clientIDOccupation']),
        clientNetWorth : validateIfIsRange(clientToForm['clientIDNetWorthType']) ? (clientToForm['clientIDNetWorthRange']) : (clientToForm['clientIDNetWorth']),
        clientIsNonResidentAlien : clientToForm['clientIDNonResidentAlien'] == 'undefined'? 'No':validateBooleanField(clientToForm['clientIDNonResidentAlien']),
        coClientFirstName : validateStringField(clientToForm['clientIDSpouseName']),
        coClientSameLastname : validateBooleanField(clientToForm['clientIDSpouseUsesSameLastName']),
        coClientLastName : validateStringField(clientToForm['clientIDSpouseLastName']),
        coClientGender : clientToForm['clientIDSpouseGender'],
        coClientBirthDate : validateDate(clientToForm['clientIDSpouseBirthday']),
        coClientSameNetWorth: validateStringField(clientToForm['clientIDSpouseNetWorthManagedTogether']),
        coClientNetWorth : validateIfIsRange(clientToForm['clientIDSpouseNetWorthType']) ? (clientToForm['clientIDSpouseNetWorthRange']) : (clientToForm['clientIDSpouseNetWorth']),
        coClientState : validateStringField(clientToForm['clientIDSpouseLivingState']),
        coClientSameState : validateStringField(clientToForm['clientIDSpouseAdressSame']),
        coClientIsRetired : validateBooleanField(clientToForm['clientIDSpouseRetired']),
        coClientOccupation : validateStringField(clientToForm['clientIDSpouseOcupation']),
        coClientIsNonResidentAlien : validateBooleanField(clientToForm['clientIDSpouseNonResidentAlien'])
      }
}

export const formatToQuestion = (value: string) => {
    return {
        "answer": value?.toString() || '',
        "whoAnswered": "advisor",
        "type": "string"
    }
}

export const formatNetWorth = (value: number | string) => {
    if(typeof value === 'number'){
        return value.toString()
    }
    else{
        return ''
    }
}

export const formatNetWorthType = (value: number | string) => {
    if(typeof value === 'number'){
        return 'number'
    }
    else{
        return 'range'
    }
}

export const formatNetWorthRange = (value: number | string) => {
    if(typeof value === 'number'){
        return ''
    }
    else{
        return value
    }
}

export const validatNetworthType = (value: string) => {
    if(typeof value === 'string' && value.includes('-')){
        return 'range'
    }
    else{
        return 'number'
    }
}

export const servicePersonalInformationDTO = (clientFromForm: FormGroup) => {

    return {
        clientIDMaritalStatus : formatToQuestion( clientFromForm.controls.clientMaritalStatus.value),
        clientIDFirstName : formatToQuestion( clientFromForm.controls.clientFirstName.value),
        clientIDLastName : formatToQuestion( clientFromForm.controls.clientLastName.value),
        email : formatToQuestion( clientFromForm.controls.clientEmail.value),
        clientIDGender : formatToQuestion( clientFromForm.controls.clientGender.value),
        clientIDBirthday : formatToQuestion( clientFromForm.controls.clientBirthDate.value),
        clientIDLivingState : formatToQuestion( clientFromForm.controls.clientState.value),
        clientIDRetiredOrNotWorking : formatToQuestion( clientFromForm.controls.clientIsRetired.value),
        clientIDOccupation : formatToQuestion( clientFromForm.controls.clientOccupation.value),
        clientIDNetWorth : formatToQuestion(
            validatNetworthType(clientFromForm.controls.clientNetWorth.value) == 'number'?
            clientFromForm.controls.clientNetWorth.value :
            ''
        ),
        clientIDNetWorthType : formatToQuestion( 
            validatNetworthType(clientFromForm.controls.clientNetWorth.value)
        ),
        clientIDNetWorthRange : formatToQuestion(
            validatNetworthType(clientFromForm.controls.clientNetWorth.value) == 'range'?
            clientFromForm.controls.clientNetWorth.value :
            ''
        ),
        clientIDNonResidentAlien : formatToQuestion( clientFromForm.controls.clientIsNonResidentAlien.value),
        clientIDSpouseName : formatToQuestion( clientFromForm.controls.coClientFirstName.value),
        clientIDSpouseUsesSameLastName : formatToQuestion( clientFromForm.controls.coClientSameLastname.value),
        clientIDSpouseLastName : formatToQuestion( clientFromForm.controls.coClientLastName.value),
        clientIDSpouseGender : formatToQuestion( clientFromForm.controls.coClientGender.value),
        clientIDSpouseBirthday : formatToQuestion( clientFromForm.controls.coClientBirthDate.value),
        clientIDSpouseNetWorthManagedTogether: formatToQuestion( clientFromForm.controls.coClientSameNetWorth.value),
        clientIDSpouseNetWorth : formatToQuestion(
            validatNetworthType(clientFromForm.controls.coClientNetWorth.value) == 'number'?
            clientFromForm.controls.coClientNetWorth.value :
            ''
        ),
        clientIDSpouseNetWorthType : formatToQuestion(validatNetworthType(clientFromForm.controls.coClientNetWorth.value)),
        clientIDSpouseNetWorthRange : formatToQuestion(
                validatNetworthType(clientFromForm.controls.coClientNetWorth.value) == 'range'?
                clientFromForm.controls.coClientNetWorth.value :
                ''
            ),
        clientIDSpouseLivingState : formatToQuestion( clientFromForm.controls.coClientState.value),
        clientIDSpouseAdressSame : formatToQuestion( clientFromForm.controls.coClientSameState.value),
        clientIDSpouseRetired : formatToQuestion( clientFromForm.controls.coClientIsRetired.value),
        clientIDSpouseOcupation : formatToQuestion( clientFromForm.controls.coClientOccupation.value),
        clientIDSpouseNonResidentAlien : formatToQuestion( clientFromForm.controls.coClientIsNonResidentAlien.value),
      }

}
