import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { getElementHeight, getRandomStr, pushIntoArrayIfObjectIsUnique, readFromStoragedObject } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { Router } from '@angular/router';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { GraphMap } from '../../graph-tool/GraphTool.class';
import { GraphToolComponent } from '../../graph-tool/graph-tool.component';
import { DataTablePDF, TableFiduciaryPDF } from '../data-table/data-table.component';
import { createNode, updateFamilyGraph } from '../family-box/updateFamilyGraph';
import { FLOWCHART_EXAMPLE } from '../flowchart-box/flowchart-box.interface';
import { Page } from '../format-page-pdf/format-page-pdf.class';
import { MaterialInfoInterface } from '../material-info/material-info.interface';
import { GraphToolIterativeCutterComponent } from '../../graph-tool-iterative-cutter/graph-tool-iterative-cutter.component';
import { graphVerticalCutter } from '../../graph-tool-iterative-cutter/graphVerticalCutter';
import { ClusterActionWrapperComponent } from '../../cluster-action-wrapper/cluster-action-wrapper.component';
import { divideFamilyGraph } from './divideFamilyGraph';


const SECTIONS_INDEX_ORDERED: string[] = ['Will', 'POA', 'Healthcare', 'Revocable', 'Irrevocable', 'Bundled', 'Other'];
const SECTIONS_INDEX_ORDERED_SECOND_SECTION: string[] = ['Revocable', 'Irrevocable'];


@Component({
  selector: 'app-estate-snapshot-report',
  templateUrl: './estate-snapshot-report.component.html',
  styleUrls: ['./estate-snapshot-report.component.scss']
})
export class EstateSnapshotReportComponent implements OnInit {

  @Input() dataIndex: number = 1; //The asset id 1, 2, 3, etc
  @Input() setData: any;

  @Output() onUpdateStatus: EventEmitter<string> = new EventEmitter();
  @Output() onLoaderStatusChange: EventEmitter<string> = new EventEmitter();
  @Output() onPagesReady: EventEmitter<any> = new EventEmitter();

  @ViewChildren(GraphToolComponent) graphComponentsViews!: QueryList<GraphToolComponent>;
  @ViewChildren(GraphToolIterativeCutterComponent) graphCutterComponents!: QueryList<GraphToolIterativeCutterComponent>;
  @ViewChildren(ClusterActionWrapperComponent) clusterActionWrapperComponents!: QueryList<ClusterActionWrapperComponent>;

  public documentListOrdered: any;

  public documentList: DocumentType[] = [];
  public dataResponseTables: TableFiduciaryPDF[] = [];

  //public sectionsToShow: string[] = ['Will', 'Revocable', 'POA', 'Healthcare', 'Irrevocable'];
  public sectionsToShow: string[] = ['FIDUCIARIES:WILL', 'FIDUCIARIES:TRUST', 'FIDUCIARIES:DIRECTIVES'];

  public firstSectionPages: any[] = [];
  public dataTable: any = [];
  public dataTableByPages: any = [];
  public allPagesData: any = [];
  public graphMixSectionPages_firstRender: any = [];
  public responseFromServer: any;
  public isLoading: boolean = true;
  public isLoadingPDF: boolean = false;
  public status: string = 'Not requested';
  public clientName: string = '';
  //public clientID: any;
  public estateSnapshotStatus: any = {};
  public advisorComments: any;
  public disclaimer: any;
  public snapshotVersion: any;
  public flowchartData: GraphMap = FLOWCHART_EXAMPLE;
  public splitedGraphs: GraphMap[] = [];
  public renderedGraphsFullHeight: EventEmitter<any> = new EventEmitter();
  public isFirstGraphRender: boolean = false;
  public isPagesRenderDone: boolean = false;
  public familyGraphUpdated;
  public finalGraphsCutted = [];
  public graphsCutedCluster: ActionsCluster = new ActionsCluster();
  public materialInfoCluster: ActionsCluster = new ActionsCluster();
  public graphCuttedResultCluster: ActionsCluster = new ActionsCluster();
  public finalCluster: ActionsCluster = new ActionsCluster();
  public graphCuttedResultsGroupByTag = [];
  public executedLoadData: boolean = false;
  public imageURL: string = '../../../../../../assets/images/fp-logo-gray.png';

  constructor(
   public advisorService: AdvisorService,
   public settingsService: ESettingsService,
   public router: Router
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    if(!this.executedLoadData){
      console.log('Se ejecuta oninit**');
      this.loadData(this.setData);
    }

    /**
     * Subscribe to the action clusters.
     * An Action cluster is a group of parallelizable "jobs" that will return a final result.
     * The cluster is also an Action that could be part of other clusters.
     */

    //Wait for the graphVerticallCutter cluster and group the results by the intial page id.
    this.graphsCutedCluster.whenAll.subscribe((graphsCutedCluster: ActionsCluster)  => {
      console.log('graphsCutedCluster all done', graphsCutedCluster.actionsGroup[0].finalState);
      graphsCutedCluster.actionsGroup.map(graphCutterAction => {
        this.graphCuttedResultsGroupByTag.push({
          action_tags: ['graphVCutted', ...graphCutterAction.action_tags, 'initialGraph_'+graphCutterAction.action_uid],
          graphData: [graphCutterAction.finalState[graphCutterAction.finalState.length - 1]]
        })
      })
      //console.log('graphCuttedResultsGroupByTag', this.graphCuttedResultsGroupByTag);
    })

    // Wait for all the material info to be rendered
    /*
    this.materialInfoCluster.whenAll.subscribe((materialInfoCluster: ActionsCluster)  => {
      console.log('materialInfoCluster all done', materialInfoCluster);
    })
    */

    // Wait for all the cutted Graphs Responses to be rendered
    this.graphCuttedResultCluster.whenAll.subscribe((graphCuttedResultCluster: ActionsCluster)  => {
      console.log('graphCuttedResultCluster all done');
      this.buildReportPages();
    })

    //When the cutted Graphs Responses and Material info has been rendered.

    /*
    this.finalCluster.addToGroup(this.materialInfoCluster);
    this.finalCluster.addToGroup(this.graphCuttedResultCluster);

    this.finalCluster.whenAll.subscribe(response => {
      console.log('finalCluster all done');
      this.buildReportPages();
    })
    */

  }

  ngAfterViewInit() {

    if(this.graphCutterComponents) this.graphCutterComponents.changes.subscribe((r) => {
      console.log('graphCutterComponents changes');
      this.graphCutterComponents.map(graphCutterComponent => {
        this.graphsCutedCluster.addToGroup(graphCutterComponent);
      })
    })

    if(this.clusterActionWrapperComponents) this.clusterActionWrapperComponents.changes.subscribe((r) => {
      this.clusterActionWrapperComponents.map(clusterActionWrapperComponent => {
        //if(clusterActionWrapperComponent.action_tags.includes('materialInfo')) this.materialInfoCluster.addToGroup(clusterActionWrapperComponent);
        if(clusterActionWrapperComponent.action_tags.includes('graphVCutted')) this.graphCuttedResultCluster.addToGroup(clusterActionWrapperComponent);
      })
    })

  }

  buildReportPages(){

      let graphMixSectionCutted = this.divideMixGraphsFirstSectionPages();

        this.dataTableByPages = this.orderTablesInPages(this.dataTable);
        //console.log('orderTablesInPages', this.dataTableByPages);
        if(this.familyGraphUpdated){
          //console.log('this.familyGraphUpdated before to concat**', this.familyGraphUpdated);
          this.allPagesData = this.allPagesData.concat(this.familyGraphUpdated);
        }
        this.allPagesData = this.allPagesData.concat(this.dataTableByPages);
        if(graphMixSectionCutted.length){
          this.allPagesData = this.allPagesData.concat(graphMixSectionCutted);
        }
        if(this.advisorComments) {
          this.allPagesData = this.allPagesData.concat(this.advisorComments);
        }
        if(this.disclaimer){
          this.allPagesData = this.allPagesData.concat(this.disclaimer);
        }
        console.log('this.allPagesData*****', this.allPagesData);
        //console.log('advisorComments***', JSON.parse(JSON.stringify(this.advisorComments)));
        this.updateIsLoadingStatusTo(false);

        this.onPagesReady.emit(this.allPagesData);

        setTimeout(()=>{
          this.isPagesRenderDone = true;
        }, 6500)
  }




  /**
   * Loads the data and process it.
   * @param existentData
   */
  async loadData(existentData?){
    this.executedLoadData = true;
    console.log('data in loadData***', existentData);
    // this.updateIsLoadingStatusTo(true);
    this.responseFromServer = undefined;
    let assetId: number = this.dataIndex;

    let handleData = async(data) => {
      //console.log('data in handleData**', data);
      this.responseFromServer = await data;

      this.clientName = data.client.fullName;
      //console.log('clientName', this.clientName);

      //console.log('this.responseFromServer handle**', this.responseFromServer);
      this.imageURL = this.responseFromServer['client']['logo'] ? this.responseFromServer['client']['logo'] : '../../../../../../assets/images/fp-logo-gray.png';

      //Transformation for the family tree

      this.responseFromServer.components.map( (section: any, idxSection) => {
        if(section.sectionId === 'FAMILY_TREE'){
          //console.log('encontró la sección**', section);
           section.data.map( (familyTreeGraph: any, indexfamilyTreeGraph) => {
            //console.log('section to update**', indexfamilyTreeGraph, familyTreeGraph);
            this.responseFromServer.components[idxSection].data[indexfamilyTreeGraph] = updateFamilyGraph(familyTreeGraph);
            //console.log('Se ha actualizado**', indexfamilyTreeGraph, this.responseFromServer.components[idxSection].data[indexfamilyTreeGraph]);
          });
          this.familyGraphUpdated =  [{
            title: section.sectionTitle,
            data: section.data,
            uid: getRandomStr(12),
            type: 'GRAPH_MIX',
          }]
          //console.log('familyGraphUpdated', this.familyGraphUpdated);
          if(this.familyGraphUpdated){
            this.familyGraphUpdated =  divideFamilyGraph(this.familyGraphUpdated);
            //console.log('familyGraphUpdatedByPages**', this.familyGraphUpdated);
          }
        }
      });

      let docListServer = this.responseFromServer.components.filter(element => element.sectionType  === 'DOCUMENT_LIST');
      if(docListServer){
        this.documentList = docListServer[0].data;
        //console.log('this.documentList server*', this.documentList);
        this.firstSectionPages = this.distributeDocsPerPage(this.documentList);
        if (this.firstSectionPages) {
          this.allPagesData=[...this.firstSectionPages];
        }
      }

      let fiduciariesResponse = this.responseFromServer.components.filter(element => element.sectionType  === 'FIDUCIARIES');
      //console.log('fiduciariesResponse server*', fiduciariesResponse);

      this.dataResponseTables = [];
      fiduciariesResponse.map((section: any) => {
        section['dataTable'] = [{section: section.sectionTitle, data: section.data}];
        section['data'].map((tableData: any) => {
          tableData['sectionTitle'] = section.sectionTitle;
          tableData['section'] = section.sectionId
        });
        this.dataResponseTables.push(...section.data);
      });

      this.dataTable = this.compileDataBySection(this.dataResponseTables);
      //console.log('this.dataResponseTables**', this.dataResponseTables, this.dataTable);

      //Load Comments section
      let commentsSection = this.responseFromServer.components.filter(element => element.sectionType  === 'COMMENTS');
      if(commentsSection.length > 0){
        let comment = commentsSection[0];
        this.advisorComments = {
          title: comment.data.title,
          comments: comment.data.paragraph,
          type: 'advisorComments'
        }

      }

      //Load Disclaimer section
      let disclaimerSection = this.responseFromServer.components.filter(element => element.sectionType  === 'DISCLAIMER');
      if(disclaimerSection.length > 0){
        let disclaimer = disclaimerSection[0];
        this.disclaimer = {
          isActive: true,
          title: disclaimer.title,
          body: disclaimer.data.paragraph,
          type: 'disclaimer'
        }
      }

      //Load GraphMix sections.
      // First render of full height.
      let graphMixSections = this.responseFromServer.components.filter(element => element.sectionType  === 'GRAPH_MIX');
      let graphMixSectionPages = [];
      //console.log('graphMixSections', graphMixSections);
      graphMixSections.map((graphMix, idx) => {

        graphMix.data.map(element => {
          if(element.dataType == 'MaterialInfo'){
            element['rootID'] = getRandomStr(9);
          }
        })


       let pageUID = getRandomStr(12);
          graphMixSectionPages.push(
            {
              type: 'GRAPH_MIX',
              data: JSON.parse(JSON.stringify(graphMix.data)),//graphMixElement,
              title: graphMix.sectionTitle,
              uid: pageUID
            }
          )
      })

      this.graphMixSectionPages_firstRender = graphMixSectionPages;
      console.log('graphMixSectionPages', this.graphMixSectionPages_firstRender);


    }

    // If the data is loaded from the component input, as in the external snapshot.
    if(existentData){
      this.snapshotVersion = existentData.client.version;
      handleData(existentData);
    }else{
      this.settingsService.getCompanyData().subscribe((data) => {
          this.imageURL = data.logo ? data.logo : "";
      });
      let clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
      await this.advisorService.getEstateSnapshotData(clientId, assetId, true).then( async (response: any) => {
        this.snapshotVersion = await response.client?.version ? response.client?.version : response.version;
        console.log('this.snapshotVersion*', this.snapshotVersion, response);
        if(response.hasOwnProperty('documents')){
          // this.router.navigate(['/advisor/summary_new/estate']);
          this.snapshotVersion = 3;
          this.isLoading = false;
          console.log('Ya no esta cargando version 3*', this.isLoading)
        }else if(this.snapshotVersion === 4 && response.components.length){
          handleData(response);
        }
      })
    }
  }

  updateIsLoadingStatusTo(isLoading: boolean){
    this.isLoading = isLoading;
  }

 divideMixGraphsFirstSectionPages(): any[]{

  let newPages = [];
  let alreadyAdded = false;
  //Iterate over the graphMix section (rendered preview)

  this.graphMixSectionPages_firstRender.map((pageData, firstRenderIndex) => {

    let pagesCounter = {count: 0};

    let subPages = [];

    let getNewPage = (localCounter?: ({count: number}))=>{
      let pagesCount: string = '';
      if(localCounter != undefined){
        pagesCount = ` (${++localCounter.count}/#totalPages)`
      }
      return{
        type: 'GRAPH_MIX',
        data: [],
        title: pageData.title + pagesCount,
        uid: pageData.uid
      }
    }

    //Find graphCutted cluster, graphCuttedRendered cluster  and material info cluster with the same page tag.

    let pageGraphs = this.graphsCutedCluster.actionsGroup.filter(action => action.action_tags.includes(pageData.uid));
    //console.log('Cutter results fot page: ' + pageData.uid, pageGraphs);
    let pageGraphsCuttedRender = this.graphCuttedResultCluster.actionsGroup.filter(action => action.action_tags.includes(pageData.uid));
    //console.log('pageGraphsCuttedRender', pageGraphsCuttedRender);

    //Si se inicia con un Material Info: Nueva pagina de material info.
    //Se inicia con un graph y el sig elemento es un graph. Nueva pagina de graph.
    //Se inicia con un graph y el sig elemento es un material. Nueva pagina con graphs y concatenar a ultima hoja material info.

    console.log('Inside page data', pageData.data);

    let isPrevSectionGraph: boolean = false;

    let lastGraphRenderedID: string;

    pageData.data.map((dataElementInPage, index) => {

      if(dataElementInPage.dataType == 'graph'){

        let graph_to_graphCutter = pageGraphs.shift();

        lastGraphRenderedID = pageGraphsCuttedRender.filter(action => action.action_tags.includes('initialGraph_'+graph_to_graphCutter.action_uid))[0].action_uid;
        //console.log('graph_to_graphCutter', graph_to_graphCutter);
        graph_to_graphCutter.finalState.map(graph=>{
          //console.log('New graph page. Graph cuttedID', graph.cuttedID)
          let page = getNewPage(pagesCounter);
          page.data.push(graph);
          subPages.push(page);
        })
      }else if(dataElementInPage.dataType == 'MaterialInfo'){
        let initialPage = isPrevSectionGraph ? subPages[subPages.length - 1] : getNewPage(pagesCounter);
        //console.log('initialPage', initialPage, 'subPages', subPages);
        let lastGraphHeight = isPrevSectionGraph ? getElementHeight(document.getElementById(lastGraphRenderedID)) : undefined;
        let materialSectionsPerPage = this.distributeMaterialInfoInChunks(dataElementInPage, 600, 600 - lastGraphHeight);

        let lastRowNumber: number = 0;

        materialSectionsPerPage.map((materialSection, idx) => {
          let page = (idx == 0 && isPrevSectionGraph) ? initialPage : getNewPage(pagesCounter);
          //let prevRowsCount = idx == 0 ? 0 : materialSectionsPerPage[idx -1].rows.length;
          materialSection['numberContinue'] = lastRowNumber;
          page.data.push(materialSection);
          !(idx == 0 && isPrevSectionGraph) && subPages.push(page);
          lastRowNumber += materialSection.rows.length;
        })
      }
      isPrevSectionGraph = dataElementInPage.dataType == "graph";

    })

    subPages.map(page=>{
      page.title = page.title.replace('#totalPages', pagesCounter.count);
    })

    newPages.push(...subPages);

  })
  console.log('divideMixGraphsFirstSectionPages', newPages);
    return newPages;
 }


  distributeDocsPerPage(listDocs: any){
    //console.log('All sections', JSON.parse(JSON.stringify(listDocs)));
    let pages = [[]];
    let pagesFormatted = [];
    let maxDocsPerPage: number = 11;

    let distributeDocsOfSameSectionInPages = (docsListInPairs: any[]) =>{
      let currentPage = pages[pages.length - 1];
      let remainRowsInPage = maxDocsPerPage - currentPage.length;
      // console.log('docsListInPairs*', docsListInPairs);

      let subsequentElementsQty = docsListInPairs.length - remainRowsInPage;

      //Los elementos entran en la pagina actual.
      if(remainRowsInPage > 0){
        //Insertar elementos tantos como espacio disponible tenga la pagina actual.
        currentPage.push(...docsListInPairs.slice(0,remainRowsInPage));
        if(subsequentElementsQty > 0){ //Todavia restan elememtos por distribuir?
          distributeDocsOfSameSectionInPages(docsListInPairs.slice(-subsequentElementsQty))
        }else{ //Ya no hay elemento por procesar, retornar
          return;
        }
      }else{
        pages.push([]);
        distributeDocsOfSameSectionInPages(docsListInPairs)
      }
    }

    SECTIONS_INDEX_ORDERED.map(sectionIndex => {
      let docsListInPairs: any[] = [];
      let sectionDocs = listDocs.filter(doc => doc.section === sectionIndex);
      let docsClient = sectionDocs.filter(doc => doc.type === 'Client');
      let docsCoClient = sectionDocs.filter(doc => doc.type === 'Co-Client');
      let longestDocsList = docsClient.length > docsCoClient.length ? docsClient : docsCoClient;

      longestDocsList.map((doc, index) => {
        docsListInPairs.push([docsClient[index], docsCoClient[index]]);
      });

      if(longestDocsList.length === 0){
        let emptyDocument: any = {
          section: sectionIndex,
          nameDocument: 'No document uploaded',
          date: new Date(),
          type: 'No docs',
          iconType: 'pdf',
        };
        // insert doc with other type
        // docsListInPairs.push([emptyDocument])
        // console.log('Section without items*', sectionIndex, docsListInPairs);
      }

      distributeDocsOfSameSectionInPages(docsListInPairs);
    });


    let docsJoint = listDocs.filter(doc => doc.type === 'Joint');
    // console.log('joint docs*', docsJoint, listDocs);
    if(docsJoint.length) {
      //pages.push([]);
      SECTIONS_INDEX_ORDERED_SECOND_SECTION.map( section => {
        let sectionDocs = listDocs.filter(doc => doc.section === section && doc.type === 'Joint');
        distributeDocsOfSameSectionInPages(sectionDocs);
      });
    }


    pages.map((page: any) => {
      let auxItemsPerPage: any = {
        type:'listDocs',
        itemsPerPage: []
      };
      page.map((row: any) => {
        if(row.length){
          let items = row.filter(item => item !== null && item != undefined);
          auxItemsPerPage.itemsPerPage.push(...items);
        }else{
          auxItemsPerPage.itemsPerPage.push(row)
        }
      });
      pagesFormatted.push(auxItemsPerPage);
    });
    // console.log('pagesFormatted*****', JSON.parse(JSON.stringify(pagesFormatted)));


    // console.log('Pages*****', JSON.parse(JSON.stringify(pages)));
    return pagesFormatted;

  }

  compileDataBySection(dataTable): DataTablePDF[] {
    // console.log('compileDataBySection*', dataTable);
    let dataPDF = [];
    this.sectionsToShow.map(section => {
      let auxPage = {
        type: 'table',
        section,
        itemsPerPage: []
      }
      let auxSection = dataTable.filter(section_ => section_.section === section);
      if (auxSection.length > 0) {
        auxPage.itemsPerPage.push({
          section,
          data: auxSection
        });
        dataPDF.push(auxPage)
        // console.log('SECTION*', JSON.parse(JSON.stringify(auxPage)));
        auxPage['sectionTitle'] = auxSection[0].sectionTitle;
      }
    });
    return dataPDF;
  }

  distributeTableSectionInPages(sectionData): Page[]{
    //console.log('sectionData', sectionData);
    let pages: Page[] = [new Page()];
    const subSectionsKeys = ["Joint", "Client", "Co-Client"];

    subSectionsKeys.map(subSectionKey =>{
      let subSectionKeyLowerCase = subSectionKey.toLowerCase();
      let subSectionTables = sectionData.filter(section=>{return section.type === subSectionKey} );
      // console.log('subSectionTables', subSectionKey, subSectionTables);

      subSectionTables.map((element: any, i) => {
        let itemAdded: boolean = false;
        // console.log(`element* -> ${element.section}_${subSectionKeyLowerCase}_${i}`);
        pages.map((page: Page, indexPage)=> {
          // console.log('Page*', indexPage, page, 'added', itemAdded);
          page.section = element.section;
          if(!itemAdded){
            let htmlElement = document.getElementById(`${element.section}_${subSectionKeyLowerCase}_${i}`);
            if(htmlElement == undefined){ return }
            let elementHeigth = htmlElement.clientHeight;
            let spaceFree = page.getAvailableSpace(subSectionKeyLowerCase) - elementHeigth;
            // console.log('spaceFree', spaceFree, 'spacePage', page.getAvailableSpace(subSectionKeyLowerCase), 'htmlElement' + element.section +'_'+ subSectionKeyLowerCase +'_'+ i, htmlElement.clientHeight);
            if (spaceFree >= 0) {
              page.updateSpace(subSectionKeyLowerCase, elementHeigth);
              page.data.push(element)
              itemAdded = true;
            }else if (indexPage === pages.length - 1){
              let newPage = new Page();
              newPage.data.push(element);
              newPage.section = element.section;
              newPage.updateSpace(subSectionKeyLowerCase, elementHeigth);
              pages.push(newPage);
              // console.log('page.getSpace', page.getAvailableSpace(subSectionKeyLowerCase), 'Pages updated', pages);
              itemAdded = true;
            }
            // console.log('Se añadió?', itemAdded , 'Estamos en page', indexPage);
          }
        });
      });
    })
    return pages;
  }

  orderTablesInPages(dataTables){
    let pages: Page[] = [];
    let pagesFormatted = [];

    Object.keys(dataTables).map((key)=> {
      dataTables[key].itemsPerPage.map((item: any) => {
        //se itera por cada seccion encontrada
        // console.log('dataTables[key].itemsPerPage*', dataTables[key].section,  dataTables[key].itemsPerPage);
        let distributedElement = this.distributeTableSectionInPages(item.data);
        distributedElement.map((pages: any) => {
          pages['sectionTitle'] = dataTables[key].sectionTitle;
        })
        //console.log('distributedElement', distributedElement);
        pages.push(...distributedElement)
      });
    });
    pages.map((page: Page) => {
      //console.log('page to format*', page);
      let auxPage = {
        type: 'table',
        section: page.section,
        sectionTitle: page.sectionTitle,
        itemsPerPage: [{
          section: page.section,
          data: page.data
        }]
      }
      // console.log('auxPage order**', JSON.parse(JSON.stringify(auxPage)));
      pagesFormatted.push(auxPage);
    });

    return pagesFormatted;
  }

/**
 *
 * @param renderHtmlID
 * @param materialInfoData
 * @param cutHeight
 * @param initialCutHeight
 * @returns
 */
  distributeMaterialInfoInChunks(materialInfoData: any, cutHeight: number, initialCutHeight?: number): any[]{
    let renderHtmlID = materialInfoData.rootID;
    //console.log('materialInfoData', materialInfoData);
    //Determine the subsection height
    //console.log('materialRenderID', renderHtmlID);
    let htmlContainer = document.getElementById(renderHtmlID);
    let subSections = htmlContainer.getElementsByClassName("section");
    let accumulator: number = 0;
    let pagesSubsectionsHeight: any[] = [];
    let finalMaterialSections: any[] = [];
    let initialIsReady = initialCutHeight == undefined;

    let newMaterialSection = ()=>{
      let newSection = JSON.parse(JSON.stringify(materialInfoData));
      newSection.rows = [];
      return newSection;
    }

    finalMaterialSections.push(newMaterialSection());

    for(let i = 0; i < subSections.length; i++){
      pagesSubsectionsHeight.push(getElementHeight(subSections[i]));
    }

    //console.log('pagesSubsectionsHeight', pagesSubsectionsHeight);

    pagesSubsectionsHeight.map((elementHeight, idx) => {
      let maxHeight = !initialIsReady ? initialCutHeight : cutHeight;
      accumulator += elementHeight;
      //console.log('maxHeight', maxHeight);

      if(accumulator > maxHeight){
        finalMaterialSections.push(newMaterialSection());
        accumulator = 0;
        initialIsReady = true;
      }

      finalMaterialSections[finalMaterialSections.length - 1].rows.push(materialInfoData.rows[idx]);

    })


    //console.log('finalMaterialSections', JSON.parse(JSON.stringify(finalMaterialSections)));
    return finalMaterialSections
  }

  getTablesByType(type: string, tables: TableFiduciaryPDF[]): TableFiduciaryPDF[] {
    if (tables) {
      return tables.filter(table_ => table_.type === type);
    }
  }

  changeStatusSection(section){
    section.isCollapsed = !section.isCollapsed;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(Boolean(changes.setData)){
      if(!this.executedLoadData){
        this.loadData(changes.setData.currentValue);
      }
    }
  }

}

 /**
 * An Action cluster is a group of parallelizable "jobs" that will return a final result.
 * The cluster is also an Action that could be part of other clusters.
 */
class ActionsCluster implements ClusterAction{

  public action_uid: string;
  public action_tags: string[] = []
  public actionsGroup: ClusterAction[] = [];
  public whenAll: EventEmitter<ActionsCluster> = new EventEmitter();
  action_event: EventEmitter<any>;
  public action_allDone: boolean = false;
  private subscribeFilter: string[] = [];
  finalState: any;

  constructor(filters: string[] = []) {
    this.subscribeFilter = filters;
    this.action_event = this.whenAll;
    if(this.action_uid == undefined) this.action_uid = getRandomStr(16);
  }


  public addToGroup(action: ClusterAction){

    console.log('ActionsCluster. addToGroup', action.action_uid);

    if(this.subscribeFilter.length > 0 && !action.action_tags.some(tag => this.subscribeFilter.includes(tag))){
      return;
    }

    if(pushIntoArrayIfObjectIsUnique(this.actionsGroup, action, 'action_uid')){
      console.log('ActionsCluster. added');
      action.action_event.subscribe(result => {
        this.clusterHasFinished(result);
      })
    }else{
      console.warn('ActionCluster. The element is already subscribed', action.action_uid);
    }

  }

  private clusterHasFinished(actionElement: any){

    //console.log('clusterHasFinished?', this.action_uid, this.actionsGroup);

    if(this.actionsGroup.every(action => action.action_allDone == true)){
      this.action_allDone = true;
      this.whenAll.emit(this);
    }

    this.finalState = this;

  }

}

export interface ClusterAction{
  action_uid: string;
  action_tags?: string[]
  action_event: EventEmitter<any>
  action_allDone: boolean;
  finalState: any;
}


