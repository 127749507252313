import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';

import { Questionnaire } from '../Questionnaire.model';
import { FieldsMetadata } from './q-tax.metadata';
import { ArrayAminusB } from 'app/shared/helpers/utils';

export interface FullValidationFlag{
  keyFlag: string,
  url: any,
  isActive: boolean,
  metadata: {description:string, line: string, lineNextYear?: string} | any
}

export class taxfull_questions extends Questionnaire {


  // *** Questionnaire header *** //
  public clientIDTaxYear: string = '';
  public clientIDFillingStatus: string= "";
  public clientIDWantsToInputTaxData: string = 'No';
  public clientIDUploadTaxReturnOnly: string = 'No';

  // --> Uploaded documents
  public clientIDFulltaxPolicy2019: string = '';
  public clientIDFulltaxPolicy2020: string = '';
  public clientIDFulltaxPolicy2021: string = '';
  public clientIDFulltaxPolicy2022: string = '';
  public clientIDFulltaxPolicy2023: string = '';

  //public clientIDFulltaxPolicy2019Status: string = '';
  //public clientIDFulltaxPolicy2020Status: string = '';

  // --> Type of analysis
  public clientIDTypeOfAnalysis: string = 'Instant tax analysis';

  // --> Derived from extractions
  public scheduleCDetected: string = '';
  public taxableIRAdistForRoth: number = 0;
  public passiveIncomeK1: string = '';

  // *** Progress bar *** //
  public progressBar: number = 0;
  public progressCompleted: boolean = false;
  public progressTab: number = 0;

  // *** Extractions *** //                         // 2019  -----  2020
  // --> Form 1040
  public clientHasForm1040: string = '';           // Check if sch exists
  public wages: number = 0;                         // Line 1
  public householdWages: number = 0;
  public tipIncome: number = 0;
  public medicalWaiverPayments: number = 0;
  public taxableDependentBenefits: number = 0;
  public employerAdoptionBenefits: number = 0;
  public wagesFrom8919: number = 0;
  public otherEarnedIncome: number = 0;
  public sum1a1h: number = 0;
  public taxExemptInterest: number = 0;             // Line 2a
  public taxInterest: number = 0;                   // Line 2b
  public qualifiedDividends: number = 0;            // Line 3a
  public totalDividends: number = 0;                // Line 3b
  public iraDistribution: number = 0;               // Line 4a
  public taxableIRA: number = 0;                    // Line 4b
  public pension: number = 0;                       // Line 4c      Line 5a
  public taxablePension: number = 0;                // Line 4d      Line 5b
  public socialSecurityBenefits: number = 0;        // Line 5a      Line 6a
  public socialSecurity: number = 0;                // Line 5b      Line 6b
  public capitalGainOrLoss: number = 0;             // Line 6       Line 7
  public hasTotalIncome: string = '';               // Check if has line
  public otherIncome: number = 0;
  public totalIncome: number = 0;                   // Line 7b      Line 9
  public adjustedIncome: number = 0;                // Line 8a      Line 10c
  public adjustedGrossIncome: number = 0;           // Line 8b      Line 11
  public standardDeductions: number = 0;            // Line 9       Line 12
  public charitableContributions1040: number = 0;   // Line 12b
  public sumStandardAndCharitable: number = 0
  public qbIncome: number = 0;                      // Line 10      Line 13
  public sumDeductions: number = 0
  public taxableIncome: number = 0;                 // Line 11b     Line 15
  public taxes: number = 0;                         // Line 12a     Line 16
  public line17_1040: number = 0
  public line18_1040: number = 0
  public qualifyingDependentCreditClaimed: number = 0
  public line20_1040: number = 0
  public line21_1040: number = 0
  public line22_1040: number = 0
  public otherTaxesWithSelfEmployment: number = 0
  public totalTax: number = 0;                      // Line 16      Line 24
  //Added Aug 8 2022 for tax projections
  public federalIncomeTaxWithheld: number = 0;                  // Line 25d
  public estimatedTaxPaymentsAmountForLastYear: number = 0;     // Line 26
  public earnedIncomeCredit: number = 0;                        // Line 27
  public additionalChildTaxCredit: number = 0;                  // Line 28
  public americanOpportunityCreditTaken: number = 0;            // Line 29
  public recoveryRebateCredit: number = 0;                      // Line 30
  public amountfromSchedule3line15: number = 0;                 // Line 31
  public totalOtherPayments: number = 0;                        // Line 32
  public totalPayments: number = 0;                             // Line 33

  // --> Schedule 1
  public clientHasSchedule1: string = '';           // Check if sch exists
  public taxableRefunds: number = 0;                // Line 1
  public alimonyReceived: number = 0;               // Line 2a
  public businessIncome: number = 0;                // Line 3
  public otherGainsOrLosses: number = 0;            // Line 4
  public rentalRealEstate: number = 0;              // Line 5
  public farmIncome: number = 0;              // Line 6
  public unemployementCompensation: number = 0;              // Line 7
  public otherIncomeSchedule1: number = 0;          //line 8
  public otherIncome8a: number = 0;                 //line 8a
  public otherIncome8b: number = 0;                 //line 8b
  public otherIncome8c: number = 0;                 //line 8c
  public otherIncome8d: number = 0;                 //line 8d
  public otherIncome8e: number = 0;                 //line 8e
  public otherIncome8f: number = 0;                 //line 8f
  public otherIncome8g: number = 0;                 //line 8g
  public otherIncome8h: number = 0;                 //line 8h
  public otherIncome8i: number = 0;                 //line 8i
  public otherIncome8j: number = 0;                 //line 8j
  public otherIncome8k: number = 0;                 //line 8k
  public otherIncome8l: number = 0;                 //line 8l
  public otherIncome8m: number = 0;                 //line 8m
  public otherIncome8n: number = 0;                 //line 8n
  public otherIncome8o: number = 0;                 //line 8o
  public otherIncome8p: number = 0;                 //line 8p
  public otherIncome8q: number = 0;
  public otherIncome8r: number = 0;
  public otherIncome8s: number = 0;
  public otherIncome8t: number = 0;
  public otherIncome8u: number = 0;
  public otherIncome8z: number = 0;
  public Income8z: number = 0;                 //line 8z
  public additionalIncomeTotal: number = 0;         //Line 9
  public sum1to9: number = 0;                       //Line 10
  public educatorExpenses: number = 0;              //Line 11
  public certainBusinessExpenses: number = 0;       //Line 12
  public healthSavings: number = 0;                 // Line 12
  public movingExpenses: number = 0;                // Line 14
  public deductibleOfSelfEmployment: number = 0;    // Line 15
  public selfEmployedSEP: number = 0;               // Line 15
  public selfEmployedHealthInsurance: number = 0;   // Line 17
  public penaltyEarlyWithdrawal: number = 0;        // Line 18
  public alimonyPaid: number = 0;                   // Line 18a
  public iraDeduction: number = 0;                  // Line 19
  public studentLoanInterestDeduction: number = 0;  // Line 20
  public forFutureUseSch1: number = 0;              // Line 22
  public archerMSADeduction: number = 0;            // Line 23
  public juryDuty: number = 0;                      // Line 24a
  public deductibleExpenses: number = 0;            // Line 24b
  public nontaxableAmountOlympicMedals: number = 0; // Line 24c
  public reforestationAmortization: number = 0;     // Line 24d
  public repaymentOfUnemployedBenefits: number = 0; // Line 24e
  public contributionsToSection501: number = 0;     // Line 24f
  public contributionsToSection403: number = 0;     // Line 24g
  public attorneyFeesDiscriminationClaims: number = 0; // Line 24h
  public attorneyFeesIRSHelp: number = 0;           // Line 24i
  public housingDeduction: number = 0;              // Line 24j
  public excessDeductions: number = 0;              // Line 24k
  public otherAdjustmens: number = 0;               // Line 24z
  public totalOtherAdjustments: number = 0;         // Line 1
  public adjustmentToIncome: number = 0;            // Line 22

  // --> Schedule 2
  public clientHasSchedule2: string = '';           // Check if sch exists
  public alternativeMinTax: number = 0;             // Line 1
  public excessAdvPremiumTaxCredit: number = 0;     // Line 2
  public sum1and2: number = 0;                      // Line 3
  public selfEmploymentTaxSch3: number = 0;         // Line 4
  public socialSecurityTip: number = 0;             // Line 5
  public uncollectedSocialSecurity: number = 0;     // Line 6
  public totalAdditionalSocialSecurity: number = 0; // Line 7
  public additionalTaxOnIRA: number = 0;            // Line 8
  public householdEmploymentTaxes: number = 0;      // Line 9
  public repaymentFirstTimeBuyer: number = 0;       // Line 10
  public additionalMedicare: number = 0;            // Line 11
  public netInvestmentIncomeTax: number = 0;        // Line 12
  public uncollectedSocialSecurityOnTips: number = 0; // Line 13
  public interestOnTax: number = 0;                 // Line 14
  public interestOnDeferredTax: number = 0;         // Line 15
  public recaptureLowIncome: number = 0;            // Line 16
  public recaptureOtherCredits: number = 0;         // Line 17a
  public recaptureFederalMortage: number = 0;       // Line 17b
  public additionalTaxHSA: number = 0;              // Line 17c
  public additionalTaxHSAdidntRemainElegibleIndividual: number = 0;     // Line 17d
  public additionalTaxArcherMSA: number = 0;                            // Line 17e
  public additionalTaxMedicareAdvantageMSA: number = 0;                 // Line 17f
  public recaptureOfCharitableContributions: number = 0;                // Line 17g
  public incomeFromNonqualifiedCompesationPlan: number = 0;             // Line 17h
  public incomeFromNonqualifiedCompesationPlan457A: number = 0;         // Line 17i
  public section72ExcessBenefitsTax: number = 0;                        // Line 17j
  public goldenParachutePayments: number = 0;                           // Line 17k
  public taxAccumulationDistributionOfTrusts: number = 0;               // Line 17l
  public exciseTaxOnInsiderStock: number = 0;                           // Line 17m
  public lookbackInterestSection167or460: number = 0;                   // Line 17n
  public taxNonEffectivelyConnectedIncome: number = 0;                  // Line 17o
  public interestForm8621line16f: number = 0;                           // Line 17p
  public interestForm8621line24: number = 0;                            // Line 17q
  public anyOtherTaxes: number = 0;                                     // Line 17z
  public totalAdditionalTaxes: number = 0;                              // Line 18
  public reservedForFutureUse: number = 0;                              // Line 19
  public section965: number = 0;                                        // Line 20
  public totalOtherTaxes: number = 0;                                   // Line 21

  // --> Schedule 3
  public clientHasSchedule3: string = '';           // Check if sch exists
  public creditForChildCareTF: string = '';         // Check if has line
  public foreignTax: number = 0;                    // Line 1
  public creditForChildCare: number = 0;            // Line 2
  public educationCredits: number = 0;              // Line 3
  public retiramentSavings: number = 0;
  public energyCredits: number = 0;                 // Line 5
  public otherCredits: number = 0;                  // Line 5
  public schedule3line5a: number = 0;               // Line 5a
  public schedule3line5b: number = 0;               // Line 5b
  public generalBusinessCredit: number = 0;         // Line 6a
  public creditForPriorYearMinimunTax: number = 0;  // Line 6b
  public adoptionCredit: number = 0;                // Line 6c
  public creditForEldery: number = 0;               // Line 6d
  public alternativeMotorVehicleCredit: number = 0; // Line 6e
  public qualifiedPlugInMotorVehicleCredit: number = 0;  // Line 6f
  public mortgageInterestCredit: number = 0;        // Line 6g
  public columbiaFirstTimeBuyer: number = 0;        // Line 6h
  public qualifiedElectricVehicleCredit: number = 0;// Line 6i
  public alternativeFuelVehicleCredit: number = 0;  // Line 6j
  public creditToHoldersTaxCreditsBonds: number = 0; // Line 6l
  public schedule3line6m: number = 0;              // Line 6m
  public amountOnForm8978: number = 0;              // Line 6z
  public otherNonRefundableCredits: number = 0;     // Line 6
  public nonrefundableCredits: number = 0;          // Line 7
  public estimatedTax: number = 0;                  // Line 8
  public netPremiumTaxCredit: number = 0;           // Line 9
  public requestForExtension: number = 0;           // Line 10
  public excessSocialSecurity: number = 0;          // Line 11
  public creditForFederalTaxFuels: number = 0;      // Line 12
  public form2439: number = 0;                      // Line 13a
  public qualifiedSickCredits: number = 0;          // Line 13b
  public healthCoverageCredit: number = 0;          // Line 13c
  public creditForRepayment: number = 0;            // Line 13d
  public futureUse13e: number = 0;                  // Line 13e
  public deferredAmount965: number = 0;             // Line 13f
  public creditChildDependent2441: number = 0;      // Line 13g
  public qualifiedSickCreditsSchH: number = 0;      // Line 13h
  public otherPaymentsOrRefundableCredits: number = 0;  // Line 13z
  public refundableCredits: number = 0;             // Line 14
  public sum9to14: number = 0;                      // Line 15


  // --> Schedule A
  public clientHasScheduleA: string = '';           // Check if sch exists
  public medicalExpenses: number = 0;               // Line 1
  public medicalExpenses2: number = 0;              // Line 3
  public medicalExpenses3: number = 0;              // Line 4
  public taxesYouPaid5a: number = 0;                // Line 5
  public taxesYouPaid5b: number = 0;                // Line 5b
  public taxesYouPaid5c: number = 0;                // Line 5c
  public taxesYouPaid5d: number=0;                  // Line 5d
  public taxesYouPaid5e: number = 0;                // Line 5e
  public taxesYouPaid: number = 0;                  // Line 7
  public mortgageInterests: number = 0;             // Line 8a
  public mortgageInterestsNotReported: number = 0;  // Line 8b
  public pointsNotReported: number = 0;             // Line 8c
  public mortgageInsurancePremiums: number = 0;     // Line 8d
  public sumOfLines8: number = 0;                   // Line 8e
  public investmentInterest: number = 0;            // Line 9
  public interestYouPaid: number = 0;               // Line 10
  public giftsByCash: number = 0;                   // Line 11
  public giftsByLand: number = 0;                   // Line 12
  public giftsToCharityTF: string = '';             // Check if has line
  public giftsToCharity: number = 0;                // Line 14
  public casualtyLosses: number = 0;                // Line 15
  public summationsItemizedDeductions: number = 0;  // Line 17s

    // --> Schedule B
    public clientHasScheduleB: string = '';

    // Schedule B. Common

    // Keep 1 and 5 together
    public scheduleB_1: string = ''; //Used only to complete how the flags work. Not used as response or extraction
    public scheduleB_5: string = ''; //Used only to complete how the flags work. Not used as response or extraction

    //
    public scheduleb_3: number = 0;
    public scheduleb_6: number = 0;

    //Schedule B. Part I

    public scheduleb_1_0_text: string = '';
    public scheduleb_1_0_amount: number = 0;
    // Keep 2 and 4 together
    public scheduleb_2: number = 0;
    public scheduleb_4: number = 0;
    public scheduleb_1_1_text: string = '';
    public scheduleb_1_1_amount: number = 0;
    public scheduleb_1_2_text: string = '';
    public scheduleb_1_2_amount: number = 0;
    public scheduleb_1_3_text: string = '';
    public scheduleb_1_3_amount: number = 0;
    public scheduleb_1_4_text: string = '';
    public scheduleb_1_4_amount: number = 0;
    public scheduleb_1_5_text: string = '';
    public scheduleb_1_5_amount: number = 0;
    public scheduleb_1_6_text: string = '';
    public scheduleb_1_6_amount: number = 0;
    public scheduleb_1_7_text: string = '';
    public scheduleb_1_7_amount: number = 0;
    public scheduleb_1_8_text: string = '';
    public scheduleb_1_8_amount: number = 0;
    public scheduleb_1_9_text: string = '';
    public scheduleb_1_9_amount: number = 0;
    public scheduleb_1_10_text: string = '';
    public scheduleb_1_10_amount: number = 0;
    public scheduleb_1_11_text: string = '';
    public scheduleb_1_11_amount: number = 0;
    public scheduleb_1_12_text: string = '';
    public scheduleb_1_12_amount: number = 0;
    public scheduleb_1_13_text: string = '';
    public scheduleb_1_13_amount: number = 0;
    public scheduleb_1_14_text: string = '';
    public scheduleb_1_14_amount: number = 0;


    //Schedule B. Part II

    public scheduleb_5_0_text: string = '';
    public scheduleb_5_0_amount: number = 0;
    public scheduleb_5_1_text: string = '';
    public scheduleb_5_1_amount: number = 0;
    public scheduleb_5_2_text: string = '';
    public scheduleb_5_2_amount: number = 0;
    public scheduleb_5_3_text: string = '';
    public scheduleb_5_3_amount: number = 0;
    public scheduleb_5_4_text: string = '';
    public scheduleb_5_4_amount: number = 0;
    public scheduleb_5_5_text: string = '';
    public scheduleb_5_5_amount: number = 0;
    public scheduleb_5_6_text: string = '';
    public scheduleb_5_6_amount: number = 0;
    public scheduleb_5_7_text: string = '';
    public scheduleb_5_7_amount: number = 0;
    public scheduleb_5_8_text: string = '';
    public scheduleb_5_8_amount: number = 0;
    public scheduleb_5_9_text: string = '';
    public scheduleb_5_9_amount: number = 0;
    public scheduleb_5_10_text: string = '';
    public scheduleb_5_10_amount: number = 0;
    public scheduleb_5_11_text: string = '';
    public scheduleb_5_11_amount: number = 0;
    public scheduleb_5_12_text: string = '';
    public scheduleb_5_12_amount: number = 0;
    public scheduleb_5_13_text: string = '';
    public scheduleb_5_13_amount: number = 0;
    public scheduleb_5_14_text: string = '';
    public scheduleb_5_14_amount: number = 0;


  // --> Schedule C
  // public clientHasScheduleC: string = '';           // Check if sch exists
  // public contractLabor: number = 0;;
  // public employeeBenefit: number = 0;;
  // public wagesLessEmployeeCredits: number = 0;;
  // public expensesForHomeBusinessTF: string = '';    // Check if has line
  // public expensesForHomeBusiness: number = 0;       // Line 30
  // public netProfitOrLoss: number = 0;               // Line 31


  // --> Schedule C v2.0
                                                // LINE
  public grossReceipts: number = 0;             // 1
  public returns: number = 0;                   // 2
  public line3SchC: number = 0;                 // 3
  public costOfGoods: number = 0;               // 4
  public grossProfit: number = 0;               // 5
  public otherIncomeSchC: number = 0;           // 6
  public grossIncome: number = 0;               // 7
  public advertising: number = 0;               // 8
  public carExpenses: number = 0;               // 9
  public commissions: number = 0;               // 10
  public contractLabor: number = 0;             // 11
  public depletion: number = 0;                 // 12
  public depreciation: number = 0;              // 13
  public employeeBenefit: number = 0;           // 14
  public insurance: number = 0;                 // 15
  public interestMortgage: number = 0;          // 16a
  public interestOther: number = 0;             // 16b
  public legal: number = 0;                     // 17
  public officeExpenses: number = 0;            // 18
  public pensionPlans: number = 0;              // 19
  public vehiclesMachinery: number = 0;         // 20a
  public otherBusinessProperty: number = 0;     // 20b
  public repairs: number = 0;                   // 21
  public supplies: number = 0;                  // 22
  public taxesAndLicenses: number = 0;          // 23
  public travel: number = 0;                    // 24a
  public meals: number = 0;                     // 24b
  public utilities: number = 0;                 // 25
  public wagesLessEmployeeCredits: number = 0;  // 26
  public otherExpenses_schC: number = 0;        // 27a
  public totalExpenses: number = 0;             // 28
  public tentativeProfit: number = 0;           // 29
  public expensesForHomeBusiness: number = 0;   // 30
  public netProfitOrLoss: number = 0;           // 31
  public expensesForHomeBusinessTF: string = '';
  public clientHasScheduleC: string = '';

  // --> Schedule D
  public clientHasScheduleD: string = '';
  public shortTermLossCarryover: number = 0;
  public netShortTermCapitalGains: number = 0;      // Line 7
  public longTermLossCarryover: number = 0;         // Check if sch exists
  public scheduleD13: number = 0;              // Line 13
  public netLongTermCapitalGains: number = 0;       // Line 15
  public scheduleD16: number = 0;              // Line 16

  // --> Schedule E
  public clientHasScheduleE: string = '';                     // Check if sch exists
  public totalAmounts23a: number = 0;                         // Line 23q
  public totalAmounts23b: number = 0;                         // Line 23b
  public totalAmounts23c: number = 0;                         // Line 23c
  public totalAmounts23d: number = 0;                         // Line 23d
  public totalAmounts23e: number = 0;                         // Line 23e
  public incomeSchE: number = 0;                              // Line 24
  public lossesSchE: number = 0;                              // Line 25
  public totalRentalRealEstate: number = 0;                   // Line 26
  public line30schE: number = 0;                              // Line 30
  public line31schE: number = 0;                              // Line 31
  public line32schE: number = 0;                              // Line 32
  public line35schE: number = 0;                              // Line 35
  public line36schE: number = 0;                              // Line 36
  public line37schE: number = 0;                              // Line 37
  public line39schE: number = 0;                              // Line 39
  public line40schE: number = 0;                              // Line 40
  public line41schE: number = 0;                              // Line 41



  // --> Schedule SE
  public clientHasScheduleSE: string = '';                    // Check if sch exists
  public netFarmProfitOrLoss: number = 0;                     // Line 1a
  public conservationReserveProgramPayments: number = 0;      // Line 1b
  public netProfitOrLossFromSchC: number = 0;                 // Line 2
  public combinelines1a1b2: number = 0;                       // Line 3
  public line4aSchSE: number = 0;                             // Line 4a
  public line4bSchSE: number = 0;                             // Line 4b
  public line4cSchSE: number = 0;                             // Line 4c
  public churchEmployeeIncome: number = 0;                    // Line 5a
  public line5bSchSE: number = 0;                             // Line 5b
  public addLines4c5b: number = 0;                            // Line 6
  public maximumAmountofCombinedWages: number = 0;            // Line 7
  public totalSocialSecurityWages: number = 0;                // Line 8a
  public unreportedTips: number = 0;                          // Line 8b
  public wagesSubjectToSocialSecurity: number = 0;            // Line 8c
  public add8a8b8c: number = 0;                               // Line 8d
  public line9SchSE: number = 0;                              // Line 9
  public line10SchSE: number = 0;                             // Line 10
  public line11SchSE: number = 0;                             // Line 11
  public selfEmploymentTax: number = 0;                       // Line 12
  public deductionForOneHalfOfSelfemployment: number = 0;     // Line 13



  // --> Other
  //public clientHasScheduleE: string = '';
  //public clientHasScheduleSE: string = '';
  public clientHas1040Box6c: string =  '';
  public clientHasForm1120: string = '';
  public clientHasForm1120S: string = '';
  public clientHasForm2441: string = '';
  public line3form2441: number = 0;
  public clientHasForm5695: string = '';
  public clientHasForm6251: string = '';
  public clientHasForm8863: string = '';
  public clientHasForm8839: string = '';
  public reiteligibleDeduction: number = 0;
  public contributionMade457_1: number = 0;
  public contributionMade457_2: number = 0;
  public netProfitOrLoss_se: number = 0;

  // --> Legacy extractions
  public scheduleSELine2:string = '';
  public clientHasSchedALine1Q1:string= '';
  public clientHasSchedALine1Q3:string= '';
  public clientHasForm1040Line29:string= '';
  public clientHasForm1040SchedCLine9PartIVForm4562:string= '';

  // *** Extraction flags ***
  // reference1: {answer: "Yes,schedule1.jpg", whoAnswered: "Advisor", type: "string"}
  // reference2: {answer: "No,url", whoAnswered: "Advisor", type: "string"}
  public flag_livingState:string= '';
  public flag_wages:string= '';
  public flag_householdWages:string= '';
  public flag_tipIncome:string= '';
  public flag_medicalWaiverPayments:string= '';
  public flag_taxableDependentBenefits:string= '';
  public flag_employerAdoptionBenefits:string= '';
  public flag_wagesFrom8919:string= '';
  public flag_otherEarnedIncome:string= '';
  public flag_sum1a1h:string= '';
  public flag_taxExemptInterest:string= '';
  public flag_taxInterest:string= '';
  public flag_qualifiedDividends:string = '';
  public flag_totalDividends:string = '';
  public flag_taxableIRA:string = '';
  public flag_taxablePension:string = '';
  public flag_socialSecurityBenefits:string = '';
  public flag_socialSecurity:string = '';
  public flag_iraDistribution:string = '';
  public flag_pension:string = '';
  public flag_capitalGainOrLoss:string = '';
  public flag_totalIncome:string = '';
  public flag_adjustedIncome:string = '';
  public flag_adjustedGrossIncome:string = '';
  public flag_standardDeductions:string = '';
  public flag_qbIncome:string = '';
  public flag_taxableIncome:string = '';
  public flag_taxes:string = '';
  public flag_totalTax:string = '';
  public flag_medicalExpenses:string = '';
  public flag_medicalExpenses2:string = '';
  public flag_medicalExpenses3:string = '';
  public flag_investmentInterest:string = '';
  public flag_interestYouPaid:string = '';
  public flag_giftsToCharity:string = '';
  public flag_casualtyLosses:string = '';
  public flag_recoveryRebateCredit: string = '';
  public flag_summationsItemizedDeductions:string = '';
  public flag_netProfitOrLoss:string = '';
  public flag_netShortTermCapitalGains:string = '';
  public flag_longTermLossCarryover:string = '';
  public flag_netLongTermCapitalGains:string = '';
  public flag_alimonyReceived:string = '';
  public flag_businessIncome:string = '';
  public flag_rentalRealEstate:string = '';
  public flag_healthSavings:string = '';
  public flag_selfEmployedSEP:string = '';
  public flag_alimonyPaid:string = '';
  public flag_iraDeduction:string = '';
  public flag_studentLoanInterestDeduction:string = '';
  public flag_adjustmentToIncome:string = '';
  public flag_foreignTax:string = ''; //flag sch 3
  public flag_estimatedTax:string = ''; //flag sch 3
  public flag_commissions:string = ''; //flag sch c
  public flag_contractLabor:string = ''; //flag sch c
  public flag_insurance:string = ''; //flag sch c
  public flag_creditForChildCare:string = '';
  public flag_educationCredits:string = '';
  public flag_energyCredits:string = '';
  public flag_otherIncome:string = '';
  public flag_taxesYouPaid: string = '';
  public flag_taxesYouPaid5a: string = '';
  public flag_taxesYouPaid5b: string = '';
  public flag_taxesYouPaid5c: string = '';
  public flag_taxesYouPaid5d: string = '';
  public flag_taxesYouPaid5e: string = '';
  public flag_mortgageInterests: string = '';
  public flag_mortgageInterestsNotReported: string = '';
  public flag_pointsNotReported: string = '';
  public flag_mortgageInsurancePremiums: string = '';
  public flag_sumOfLines8: string = '';
  public flag_giftsByCash: string = '';
  public flag_giftsByLand: string = '';


  //Sch 1 flags

  public flag_otherIncome8a: string = '';
  public flag_otherIncome8b: string = '';
  public flag_otherIncome8c: string = '';
  public flag_otherIncome8d: string = '';
  public flag_otherIncome8e: string = '';
  public flag_otherIncome8f: string = '';
  public flag_otherIncome8g: string = '';
  public flag_otherIncome8h: string = '';
  public flag_otherIncome8i: string = '';
  public flag_otherIncome8j: string = '';
  public flag_otherIncome8k: string = '';
  public flag_otherIncome8l: string = '';
  public flag_otherIncome8m: string = '';
  public flag_otherIncome8n: string = '';
  public flag_otherIncome8o: string = '';
  public flag_otherIncome8p: string = '';
  public flag_otherIncome8q: string = '';
  public flag_otherIncome8r: string = '';
  public flag_otherIncome8s: string = '';
  public flag_otherIncome8t: string = '';
  public flag_otherIncome8u: string = '';
  public flag_otherIncome8z: string = '';
  public flag_otherGainsOrLosses: string = '';
  public flag_farmIncome: string = '';
  public flag_unemployementCompensation: string = '';
  public flag_otherIncomeSchedule1: string = '';
  public flag_totalOtherAdjustments: string = '';
  public flag_additionalIncomeTotal: string = '';
  public flag_sum1to9: string = '';
  public flag_educatorExpenses: string = '';
  public flag_certainBusinessExpenses: string = '';
  public flag_movingExpenses: string = '';
  public flag_selfEmployedHealthInsurance: string = '';
  public flag_penaltyEarlyWithdrawal: string = '';
  public flag_forFutureUseSch1: string = '';
  public flag_archerMSADeduction: string = '';
  public flag_juryDuty: string = '';
  public flag_deductibleExpenses: string = '';
  public flag_nontaxableAmountOlympicMedals: string = '';
  public flag_reforestationAmortization: string = '';
  public flag_repaymentOfUnemployedBenefits: string = '';
  public flag_contributionsToSection501: string = '';
  public flag_contributionsToSection403: string = '';
  public flag_attorneyFeesDiscriminationClaims: string = '';
  public flag_attorneyFeesIRSHelp: string = '';
  public flag_housingDeduction: string = '';
  public flag_excessDeductions: string = '';
  public flag_otherAdjustmens: string = '';

  // Schedule 2 flags
  //public flag_clientHasSchedule2: string = '';
  public flag_alternativeMinTax: string = '';
  public flag_excessAdvPremiumTaxCredit: string = '';
  public flag_sum1and2: string = '';
  public flag_selfEmploymentTaxSch3: string = '';
  public flag_socialSecurityTip: string = '';
  public flag_uncollectedSocialSecurity: string = '';
  public flag_totalAdditionalSocialSecurity: string = '';
  public flag_additionalTaxOnIRA: string = '';
  public flag_householdEmploymentTaxes: string = '';
  public flag_repaymentFirstTimeBuyer: string = '';
  public flag_additionalMedicare: string = '';
  public flag_netInvestmentIncomeTax: string = '';
  public flag_uncollectedSocialSecurityOnTips: string = '';
  public flag_interestOnTax: string = '';
  public flag_interestOnDeferredTax: string = '';
  public flag_recaptureLowIncome: string = '';
  public flag_recaptureOtherCredits: string = '';
  public flag_recaptureFederalMortage: string = '';
  public flag_additionalTaxHSA: string = '';
  public flag_additionalTaxHSAdidntRemainElegibleIndividual: string = '';
  public flag_additionalTaxArcherMSA: string = '';
  public flag_additionalTaxMedicareAdvantageMSA: string = '';
  public flag_recaptureOfCharitableContributions: string = '';
  public flag_incomeFromNonqualifiedCompesationPlan: string = '';
  public flag_incomeFromNonqualifiedCompesationPlan457A: string = '';
  public flag_section72ExcessBenefitsTax: string = '';
  public flag_goldenParachutePayments: string = '';
  public flag_taxAccumulationDistributionOfTrusts: string = '';
  public flag_exciseTaxOnInsiderStock: string = '';
  public flag_lookbackInterestSection167or460: string = '';
  public flag_taxNonEffectivelyConnectedIncome: string = '';
  public flag_interestForm8621line16f: string = '';
  public flag_interestForm8621line24: string = '';
  public flag_anyOtherTaxes: string = '';
  public flag_totalAdditionalTaxes: string = '';
  public flag_reservedForFutureUse: string = '';
  public flag_section965: string = '';
  public flag_totalOtherTaxes: string = '';

  //

  public flag_scheduleB_1: string = ''; // Activates the flags subcomponent
  public flag_scheduleb_2: string = '';
  public flag_scheduleb_4: string = '';
  public flag_scheduleB_5: string = ''; // Activates the flags subcomponent
  public flag_scheduleb_6: string = '';
  public flag_shortTermLossCarryover: string = '';
  public flag_additionalChildTaxCredit: string = '';
  public flag_amountfromSchedule3line15: string = '';
  public flag_totalPayments: string = '';
  public flag_advertising: string = '';
  public flag_carExpenses: string = '';
  public flag_legal: string = '';
  public flag_taxableRefunds: string = '';
  public flag_deductibleOfSelfEmployment: string = '';


  //Schedule 3 flags
  public flag_retiramentSavings: string = '';
  public flag_otherCredits: string = '';
  public flag_generalBusinessCredit: string = '';
  public flag_schedule3line5a: string = '';
  public flag_schedule3line5b: string = '';
  public flag_creditForPriorYearMinimunTax: string = '';
  public flag_adoptionCredit: string = '';
  public flag_creditForEldery: string = '';
  public flag_alternativeMotorVehicleCredit: string = '';
  public flag_qualifiedPlugInMotorVehicleCredit: string = '';
  public flag_mortgageInterestCredit: string = '';
  public flag_columbiaFirstTimeBuyer: string = '';
  public flag_qualifiedElectricVehicleCredit: string = '';
  public flag_alternativeFuelVehicleCredit: string = '';
  public flag_creditToHoldersTaxCreditsBonds: string = '';
  public flag_schedule3line6m: string = '';
  public flag_amountOnForm8978: string = '';
  public flag_otherNonRefundableCredits: string = '';
  public flag_nonrefundableCredits: string = '';
  public flag_netPremiumTaxCredit: string = '';
  public flag_requestForExtension: string = '';
  public flag_excessSocialSecurity: string = '';
  public flag_creditForFederalTaxFuels: string = '';
  public flag_form2439: string = '';
  public flag_qualifiedSickCredits: string = '';
  public flag_healthCoverageCredit: string = '';
  public flag_creditForRepayment: string = '';
  public flag_futureUse13e: string = '';
  public flag_deferredAmount965: string = '';
  public flag_creditChildDependent2441: string = '';
  public flag_qualifiedSickCreditsSchH: string = '';
  public flag_otherPaymentsOrRefundableCredits: string = '';
  public flag_sum9to14: string = '';
  public flag_refundableCredits: string = '';


  //Flags sch C
  public flag_line3SchC: string = '';
  public flag_grossReceipts: string = '';
  public flag_returns: string = '';
  public flag_costOfGoods: string = '';
  public flag_grossProfit: string = '';
  public flag_otherIncomeSchC: string = '';
  public flag_grossIncome: string = '';
  public flag_totalExpenses: string = '';
  public flag_tentativeProfit: string = '';
  public flag_depletion: string = '';
  public flag_depreciation: string = '';
  public flag_employeeBenefit: string = '';
  public flag_interestMortgage: string = '';
  public flag_interestOther: string = '';
  public flag_officeExpenses: string = '';
  public flag_pensionPlans: string = '';
  public flag_vehiclesMachinery: string = '';
  public flag_otherBusinessProperty: string = '';
  public flag_repairs: string = '';
  public flag_supplies: string = '';
  public flag_taxesAndLicenses: string = '';
  public flag_travel: string = '';
  public flag_meals: string = '';
  public flag_utilities: string = '';
  public flag_wagesLessEmployeeCredits: string = '';
  public flag_otherExpenses_schC: string = '';
  public flag_expensesForHomeBusiness: string = '';

  //sch D

  public flag_scheduleD13: string = '';
  public flag_scheduleD16: string = '';


  //Flags form 1040 -> 10/05/23
  public flag_charitableContributions1040:string = '';
  public flag_sumStandardAndCharitable:string = '';
  public flag_sumDeductions:string = '';
  public flag_line17_1040:string = '';
  public flag_line18_1040:string = '';
  public flag_qualifyingDependentCreditClaimed:string = '';
  public flag_line20_1040:string = '';
  public flag_line21_1040:string = '';
  public flag_line22_1040:string = '';
  public flag_otherTaxesWithSelfEmployment:string = '';
  public flag_federalIncomeTaxWithheld:string = '';
  public flag_estimatedTaxPaymentsAmountForLastYear: string = '';
  public flag_earnedIncomeCredit:string = '';
  public flag_americanOpportunityCreditTaken:string = '';
  public flag_totalOtherPayments:string = '';


  // Flags form 8582 -> 1/08/23
  public flag_form8582SectionOne1a: string = '';
  public flag_form8582SectionOne1b: string = '';
  public flag_form8582SectionOne1c: string = '';
  public flag_form8582SectionOne1d: string = '';
  public flag_form8582SectionOne2a: string = '';
  public flag_form8582SectionOne2b: string = '';
  public flag_form8582SectionOne2c: string = '';
  public flag_form8582SectionOne2d: string = '';
  public flag_form8582SectionOne3: string = '';
  public flag_form8582SectionTwo4: string = '';
  public flag_form8582SectionTwo8: string = '';
  public flag_form8582SectionTwo9: string = '';
  public flag_form8582SectionThree11: string = '';
  public flag_form8582SectionSevena: string = '';
  public flag_form8582SectionSevenc: string = '';
  public flag_form8582SectionEighta: string = '';
  public flag_form8582SectionEightb: string = '';
  public flag_form8582SectionEightc: string = '';

  // Flags sch E
  public flag_totalAmounts23a: string = '';
  public flag_totalAmounts23b: string = '';
  public flag_totalAmounts23c: string = '';
  public flag_totalAmounts23d: string = '';
  public flag_totalAmounts23e: string = '';
  public flag_incomeSchE: string = '';
  public flag_lossesSchE: string = '';
  public flag_totalRentalRealEstate: string = '';  // Flag Line 26
  public flag_line30schE: string = '';
  public flag_line31schE: string = '';
  public flag_line32schE: string = '';
  public flag_line35schE: string = '';
  public flag_line36schE: string = '';
  public flag_line37schE: string = '';
  public flag_line39schE: string = '';
  public flag_line40schE: string = '';
  public flag_line41schE: string = '';   // FLag Line 41

  //Flags sch SE

  public flag_netFarmProfitOrLoss: string = '';
  public flag_conservationReserveProgramPayments: string = '';
  public flag_netProfitOrLossFromSchC: string = '';
  public flag_combinelines1a1b2: string = '';
  public flag_line4aSchSE: string = '';
  public flag_line4bSchSE: string = '';
  public flag_line4cSchSE: string = '';
  public flag_churchEmployeeIncome: string = '';
  public flag_line5bSchSE: string = '';
  public flag_addLines4c5b: string = '';
  public flag_maximumAmountofCombinedWages: string = '';
  public flag_totalSocialSecurityWages: string = '';
  public flag_unreportedTips: string = '';
  public flag_wagesSubjectToSocialSecurity: string = '';
  public flag_add8a8b8c: string = '';
  public flag_line9SchSE: string = '';
  public flag_line10SchSE: string = '';
  public flag_line11SchSE: string = '';
  public flag_selfEmploymentTax: string = '';
  public flag_deductionForOneHalfOfSelfemployment: string = '';

  // Flags Form 8995 A
  public flag_form8995A27: string = '';
  public flag_form8995A39: string = '';
  public flag_form8995A40: string = '';
  public flag_form8995A28: string = '';
  public flag_form8995A29: string = '';
  public flag_form8995A30: string = '';
  public flag_form8995A31: string = '';
  public flag_form8995A32: string = '';
  public flag_form8995A33: string = '';
  public flag_form8995A34: string = '';
  public flag_form8995A35: string = '';
  public flag_form8995A36: string = '';
  public flag_form8995A37: string = '';
  public flag_form8995A38: string = '';


  // *** Questionnaire tabs *** //
  // --> IRA
  public clientIDIfIsSelfEmployedOrBusinessOwnerAndExpectsIncomeOf200000ForNextThreeYears: string= '';
  public clientIDContributingToSEPIRA: string= '';

  // --> Income
  public clientIDIncomeExpectationsFollowingYear: string= '';
  public clientIDExpectsToHaveLargerIncomeGoingForward: string= '';
  public clientIDExpectsToHaveWayLowerIncomeGoingForward: string= '';
  public clientID2018Income: number = 0;
  public clientIDIncomeREITorQPTP: string = '';
  public clientIDIncomeREITorQPTP_2: string = '';
  /// -> Internal use only no rec triggered
  public clientIDHasQuestionsAboutTaxRecordRetention: string = '';
  public clientIDOwnAnyStocks: string = '';

  // --> Business Owner
  public clientPrincipalAssetIsEmpoyees: string =  '';
  public clientIDBusinessProvideProfessionalConsultingServices: string = '';
  public clientIDWorksAsAProfessionalInYourOwnSCorporation: string = '';
  public clientIDWorksInOwnBusinessWhichRetainsIncome: string = '';
  public clientIDEmployerDomestic: string = '';
  public clientIDSelfEmployedOrSCorp: string = '';
  public clientIDSelfEmployedOrSCorp_2: string = '';
  public clientIDBusinessProvidesMoreThan1OtherBusinessActivity: string = '';
  public clientIDValuableAssetsToSellIn5YearsOrMore: string = '';
  public clientIDFormingBusinessLeverageRealEstate: string = '';
  public clientIDFormingInvestorsCTrustForeign: string = '';
  public clientIDFutureExpectedLosses: string = '';
  public clientIDHomeOfficeSqft: string = '';
  public clientIDHomeOffice: string = '';
  public clientIDClubOrg: string = '';
  public clientIDMantainRetPlanWithholdWage: string = '';
  public clientIDSelfEMployedAlone: string = '';
  public clientIDExpensesMealsEntertain: string = '';
  public clientIDHiredEmployees: string = '';
  public clientIDIncomeProfession: string = '';
  public clientIDCapitalizingBusiness: string = '';
  public clientIDCCorpCapitalizingBusiness: string = '';
  public clientIDCarPersonalBusiness: string = '';
  public clientIDExpenseCarriedFuture: string = '';
  /// -> New questions Cares act (Aug 10 2020 - RT)
  public clientIDEmployeePayrollTaxes: string = '';
  public clientIDEmployeeCovidExpenses: string = '';
  public clientIDEmployeeCovidWages: string = '';

  // --> Charity and gifts
  public clientIDHasQuestionsAboutCharitableGifting: string = ''; /// -> Internal use only no rec triggered
  public clientIDWillingToDonate5000: string = '';
  public clientIDCharitableGiftsTicketsorMembership: string = '';
  public clientIDHouseOrFarmToCharity: string = '';
  public clientIDGiftToPayEducation: string = '';
  public clientIDGiftToDisabled: string = '';
  public clientIDFinanciallySupportsToAdultWhoIsOver18: string= '';
  public clientIDFinanciallySupportsToAdultWhoIsOver18_2: string =  '';
  public clientID2020ItemizedCharitableDonations: string = ''; /// -> New question Cares act (Aug 10 2020 - RT)
  public clientIDSignificantCharitableDonations: string = ''; /// -> New question Cares act (Aug 10 2020 - RT)

  // --> Investment
  public clientIDShortTermCapGainAndUnrealizedLosses: string = '';
  public clientIDShortTermCapGainAndUnrealizedLosses_2: string = '';
  public clientIDNetLossesCapGainLosses: string = '';
  public clientIDNetLossesCapGainLosses_2: string = '';
  public clientIDHasUnrealizedLosses: string = '';
  public clientIDHasUnrealizedLosses_2: string = '';
  public clientIDSellAtLoss: string = '';
  public clientIDSellAtLoss_2: string = '';
  public clientIDLongTimeHorizon: string = '';
  public clientIDLongTimeHorizon_2: string = '';
  public clientIDHasOldTradeBusinessStock: string = '';
  public clientIDHasOldTradeBusinessStock_2: string = '';
  public clientIDLittleValueNonLiquidAssets: string = '';
  public clientIDLittleValueNonLiquidAssets_2: string = '';
  public clientHasForm1040SchedCLineG: string = '';
  public clientIDHasForm1040SchedCLineG_2: string = '';
  public clientIDHasInvestmentIncome: string = '';
  public clientIDHasInvestmentIncome_2: string = '';
  public clientIDInvestmentInterestExpenseCarryover: string = '';
  public clientIDInvestmentInterestExpenseCarryover_2: string = '';
  public clientIDPurchaseInvestmentNotSold: string = '';
  public clientIDPurchaseInvestmentNotSold_2: string = '';
  public clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture: string= '';
  public clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture_2: string =  '';

  // --> Employer
  public clientIDEmployerDependentCareBenefit: string = '';
  public clientIDEmployerDependentCareBenefit_2: string = '';
  public clientIDEmployerHealthCareBenefit: string = '';
  public clientIDEmployerHealthCareBenefit_2: string = '';
  public clientIDEmployerFSA: string = '';
  public clientIDEmployerFSA_2: string = '';
  public clientIDEmployerProvidesStockOptions: string = ''; /// -> Internal use only no rec triggered
  public clientIDStocOptCompPack: string = '';
  public clientIDStocOptCompPack_2: string = '';
  public clientIDStockOptCorpEmployer: string = '';
  public clientIDStockOptCorpEmployer_2: string = '';
  public clientIDISOEmployer: string = '';
  public clientIDISOEmployer_2: string = '';
  public clientIDExerciseISO: string = '';
  public clientIDExerciseISO_2: string = '';
  public clientIDExcersiceISOPrior: string = '';
  public clientIDExcersiceISOPrior_2: string = '';
  public clientIDTerminateEmployISO: string = '';
  public clientIDTerminateEmployISO_2: string = '';
  public clientIDVestProperty: string = '';
  public clientIDVestProperty_2: string = '';
  public clientIDIndependentContractorISO: string = '';
  public clientIDIndependentContractorISO_2: string = '';
  public clientIDIsOnMedicare: string= '';
  public clientIDIsOnMedicare_2: string= '';
  public clientIDHasOptionToChooseHighDeductibleMedicalPlan: string= '';
  public clientIDHasOptionToChooseHighDeductibleMedicalPlan_2: string= '';
  public clientIDIsOnHighDeductibleMedicanPlan: string= '';
  public clientIDIsOnHighDeductibleMedicanPlan_2: string =  '';
  public clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount: string= '';
  public clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount_2: string =  '';

  // --> Retirement
  public clientIDAboutToRetire: string = '';
  public clientIDAboutToRetire_2: string = '';
  public clientIDHasAccessToWorkplaceRetirementPlan: string= '';
  public clientIDHasAccessToWorkplaceRetirementPlan_2: string= '';
  public clientIDIsMaximizingContributions: string= '';
  public clientIDIsMaximizingContributions_2: string= '';
  public clientIDHasOrSpouseAccessToRoth401k: string= '';
  public clientIDPlanPermitsToMakeAfterTaxContributionsTo401k: string= '';
  public clientIDPlanPermitsToMakeAfterTaxContributionsTo401k_2: string= '';
  public clientIDHasAccessToFlexibleSpendingAccount: string= '';
  public clientIDHasAccessToFlexibleSpendingAccount_2: string =  '';
  public clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses: string= '';
  public clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses_2: string =  '';
  public clientIDWorksForNonProfitOrGovernmentalEntity: string= '';
  public clientIDWorksForNonProfitOrGovernmentalEntity_2: string =  '';
  public clientIDHasAccessTo457Plan: string= '';
  public clientIDHasAccessTo457Plan_2: string =  '';
  public clientIDIsMaximizingContributionsOn457Plan: string= '';
  public clientIDIsMaximizingContributionsOn457Plan_2: string =  '';
  public clientIDHasTraditionalOrRolloverIRA: string= '';
  public clientIDHasTraditionalOrRolloverIRA_2: string =  '';
  public clientIDWithdrawNoPenalty: string = '';
  public clientIDWithdrawNoPenalty_2: string = '';
  public clientIDDistPrincipalResidence: string = '';
  public clientIDDistPrincipalResidence_2: string = '';
  public clientIDDistMedExpenses: string = '';
  public clientIDDistMedExpenses_2: string = '';
  public clientIDDistHealthInsurance: string = '';
  public clientIDDistHealthInsurance_2: string = '';
  public clientIDDistEducExpense: string = '';
  public clientIDDistEducExpense_2: string = '';
  public clientIDTakeDist: string = '';
  public clientIDTakeDist_2: string = '';
  public clientID70WorkNoOwner: string = '';
  public clientID70WorkNoOwner_2: string = '';
  public clientIDExpensesIRA: string = '';
  public clientIDExpensesIRA_2: string = '';
  public clientIDBorrowFromRetPlan: string = '';
  public clientIDBorrowFromRetPlan_2: string = '';
  public clientIDConsideringContributionIRA: string = '';
  public clientIDConsideringContributionIRA_2: string = '';
  // public clientIDIncomeHighToRothIRA: string = '';
  // public clientIDIncomeHighToRothIRA_2: string = '';
  public clientIDContributionNonWorkingSpouseIRA: string = '';
  public clientIDContributionNonWorkingSpouseIRA_2: string = '';
  public clientIDIRAInvestCollectibles: string = '';
  public clientIDIRAInvestCollectibles_2: string = '';
  public clientIDIRAInvestMasterLtdPartner: string = '';
  public clientIDIRAInvestMasterLtdPartner_2: string = '';
  public clientIDTrusteeOwnRetPlan: string = '';
  public clientIDTrusteeOwnRetPlan_2: string = '';
  public clientIDIRAInvestIliquidAssets: string = '';
  public clientIDIRAInvestIliquidAssets_2: string = '';
  public clientIDSEPSameRulesPSP: string = '';
  public clientIDSEPSameRulesPSP_2: string = '';
  public clientIDSetRAForYearEnded: string = '';
  public clientIDSetRAForYearEnded_2: string = '';
  public clientIDDistDeathDisab: string = '';
  public clientIDDistDeathDisab_2: string = '';
  public clientIDRMDRetPlan: string = ''; /// -> New question Cares act (Aug 10 2020 - RT)
  public clientIDHasAccessTo401KPlan: string = '';

  // --> Social Security
  public clientIDPayMedicare: string = '';
  public clientIDPayMedicare_2: string = '';
  public clientIDMoreMinimumMedicare: string = '';
  public clientIDMoreMinimumMedicare_2: string = '';
  public clientIDOutBD: string = '';
  public clientIDOutBD_2: string = '';
  public clientIDWorkProvideMedicare: string = '';
  public clientIDWorkProvideMedicare_2: string = '';
  public clientIDSSBenDefer: string = '';
  public clientIDSSBenDefer_2: string = '';

  // --> Family
  public clientIDChildrenCreditAllowed: string = '';
  public clientIDChildrenCreditAllowed_2: string = '';
  public clientIDSellingExchangeProperty: string = '';
  public clientIDSellingExchangeProperty_2: string = '';
  public clientIDQualifyingExpensesIncurred: string = '';
  public clientIDQualifyingExpensesIncurred_2: string = '';
  public clientIDNewChildCredit2000: string = '';
  public clientIDNewChildCredit2000_2: string = '';
  public clientIDIncomeNetInvestmentTaxLowChildIncome: string = '';
  public clientIDIncomeNetInvestmentTaxLowChildIncome_2: string = '';
  public clientIDSavingsLowRateChildHighInterest: string = '';
  public clientIDSavingsLowRateChildHighInterest_2: string = '';
  public clientIDHaveBusinessChildWOrk: string = '';
  public clientIDHaveBusinessChildWOrk_2: string = '';
  public clientIDSelfEmployChildAbleWork: string = '';
  public clientIDSelfEmployChildAbleWork_2: string = '';

  // --> Children
  public clientIDHasAccessThroughEmployerToDependentCareAccount: string= '';
  public clientIDHasAccessThroughEmployerToDependentCareAccount_2: string =  '';
  public clientIDIsMaximizzingContributionsDependentCareAccount: string= '';
  public clientIDIsMaximizzingContributionsDependentCareAccount_2: string =  '';

  // --> Tuition
  public clientIDWillBePayingForTuitionOfChildrenOrGrandchildren: string= '';
  public clientIDWillBePayingForPrivateTuitionOfChildrenOrGrandchildren: string= '';
  public clientIDIsTheirTuitionFundedFullyFundedThrough529PlanAccounts: string= '';
  public clientIDHasMoneyBeenContributedTo529PlanToFundUpTo10000AYearOfExpenses: string= '';

  // --> Lending
  public clientIDPayStudentForDeductionJointly: string = '';
  public clientIDPayStudentForDeductionJointly_2: string = '';
  public clientIDNotgiftButLoan: string = '';
  public clientIDNotgiftButLoan_2: string = '';
  public clientIDLoanForResidence: string = '';
  public clientIDLoanForResidence_2: string = '';
  public clientIDForgiveLoanAsGift: string = '';
  public clientIDForgiveLoanAsGift_2: string = '';
  public clientIDPayStudentLoanInterestsOtherIncurred: string = '';
  public clientIDPayStudentLoanInterestsOtherIncurred_2: string = '';
  public clientIDInterestDebtBuyInv: string = '';
  public clientIDInterestDebtBuyInv_2: string = '';
  public clientIDBorrowInvestPersonal: string = '';
  public clientIDBorrowInvestPersonal_2: string = '';
  public clientIDBorrowBuySecuritiesInvestLtdPart: string = '';
  public clientIDBorrowBuySecuritiesInvestLtdPart_2: string = '';
  public clientIDInvestmentIncomePayPersonalDebt: string = '';
  public clientIDInvestmentIncomePayPersonalDebt_2: string = '';
  public clientIDInterestExpenseInvestmentMunicipal: string = '';
  public clientIDInterestExpenseInvestmentMunicipal_2: string = '';
  public clientIDBadDebtLosses: string = '';
  public clientIDBadDebtLosses_2: string = '';

  // --> Other
  public clientIDDoYouHaveAnInterestInRentalProperty: string = '';
  public clientIDWorthlessInvestments: string = '';
  public clientIDSpouseUnknowledgeableOtherIncomeOrReporting: string = '';
  public clientIDSpouseUnknowledgeableOtherIncomeOrReporting_2: string = '';
  public clientIDMoveToLowerTaxState: string = '';
  public clientIDABLEAccountAGI: string = '';
  public clientIDBuyElectirCar: string = '';
  public clientIDSubjectToAMT: string = '';
  public clientIDTheftCasualtyLosses: string = '';
  public clientIDPayTaxPreparationPlanning: string = '';
  public clientIDUSInvestmentIncomeLimitedIncome: string = '';
  public clientIDSeparateMAGI: string = '';
  public clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS: string = ''; /// -> Internal use only no rec triggered
  public clientIDForeignResident: string = '';
  public clientIDIncomeForeignAssets: string = '';
  public clientIDForeignBalance: string = '';
  public clientIDMarriedAlien: string = '';
  public clientIDImproveNoResidential: string = '';
  public clientIDInvestmentExpenses: string = '';
  public clientIDHasLTMedInsurance: string = '';

  // --> Advisor section
  public advisorIDYearToDateRealizedGains: number = 0;
  public clientIDInvManagerManagesClientsIRAAndManagementFeesDeductedProrataBasis: string= '';
  public clientIDInvManagerManagesClientsIRAAndManagementFeesDeductedProrataBasis_2: string =  '';
  public clientIDRealizedLongTermCapitalGains: number = 0;
  public advisorIDUnrealizedLongTermCapitalGains: string= '';
  public advisorIDUnrealizedLongTermCapitalGains_2: string =  '';
  public advisorIDUnrealizedLongTermCapitalGainsAmount: number = 0;
  public advisorIDUnrealizedLongTermCapitalLosses: string= '';
  public advisorIDUnrealizedLongTermCapitalLosses_2: string =  '';
  public advisorIDClientsHasSeparatelyManagedTaxableInvAccount: string= '';
  public advisorIDClientsHasSeparatelyManagedTaxableInvAccount_2: string =  '';
  public advisorIDOneSpouseWorks: string= '';
  public advisorIDClientHasSubstantialPortfolioLargerThanNeeds: string= '';
  public advisorIDClientHasSubstantialPortfolioLargerThanNeeds_2: string =  '';
  public clientIDDualResident: string = '';
  public clientIDResidentAlien: string = '';
  public advisorIDDoesClientHasMunicipalBonds: string= '';
  public advisorIDDoesClientHasMunicipalBonds_2: string =  '';
  public clientIDBusinessNY: string = '';
  public clientIDCorporationqualifiesAsSCorp: string = '';
  public clientIDEmployeeBenefitsAsC: string = '';
  public clientIDLossesWagesControlledCorp: string = '';
  public clientIDPersonalPropertyBusiness: string = '';
  public clientIDBusinessMaintainInventory: string = '';
  public clientIDLineBusiness: string = '';
  public clientIDNOLCarryover: string = '';
  public clientIDRDExpenses: string = '';
  public clientIDOperatesBusinessTaxedAsCCorpAndNotLikelyToBeInHighTaxBraket: string = '';
  public clientIDPartnerOfLLCAndReceiveAGuaranteedPaymentForServices: string = '';
  public clientIDBuyingAssetsUnincorporatedBusiness: string = '';
  public clientIDPartnershipsDepreciate: string = '';
  public clientIDBusinessLoss: string = '';
  public clientIDRelatedLendBorrow: string = '';

  // --> Stranded
  public clientIDOtherThanHouseOrFarmToCharity: string = '';



  // --> NO general quest
  public filingStatus: string = '';
  public livingState: string = '';
  public hasChildren: string = '';
  public clientHasForm8880: string = '';
  public clientHasForm1099: string = '';

  // Form 8995
  public clientHasForm8995: string = 'No';           // Check if sch exists
  public totalQualifiedBusinessIncome: number = 0;
  public qualifiedBusinessIncomeDeduction: number = 0;
  public totalReit: number = 0;

  // Form 8995 A
  public clientHasForm8995A: string = 'No';
  public form8995A27: number = 0;
  public form8995A39: number = 0;
  public form8995A40: number = 0;
  public form8995A28: number = 0;
  public form8995A29: number = 0;
  public form8995A30: number = 0;
  public form8995A31: number = 0;
  public form8995A32: number = 0;
  public form8995A33: number = 0;
  public form8995A34: number = 0;
  public form8995A35: number = 0;
  public form8995A36: number = 0;
  public form8995A37: number = 0;
  public form8995A38: number = 0;
  public clientHasForm8995Aalert: string = '';

  // Form 709
  public clientHasForm709: string = '';           // Check if Form 709 exists
  public creditForForeignGift: string = '';
  public totalCreditsGift: string = '';
  public balanceGifts: string = '';
  public generationSkippingTransferTaxes: string = '';
  public totalTaxGifts: string = '';
  public generationSkippingTransferTaxesPrepaid: string = '';

  // Form 5498
  public clientHasForm5498: string = '';           // Check if sch exists
  public contributionsMade: number = 0;
  public currentContributionToWorkplaceRetirementPlanSEP: number = 0;
  public currentContributionToWorkplaceRetirementPlanSIMPLE: number = 0;


  // Form 8582
  public clientHasForm8582: string = '';
  public form8582SectionOne1a: number = 0;
  public form8582SectionOne1b: number = 0;
  public form8582SectionOne1c: number = 0;
  public form8582SectionOne1d: number = 0;
  public form8582SectionOne2a: number = 0;
  public form8582SectionOne2b: number = 0;
  public form8582SectionOne2c: number = 0;
  public form8582SectionOne2d: number = 0;
  public form8582SectionOne3: number = 0;
  public form8582SectionTwo4: number = 0;
  public form8582SectionTwo8: number = 0;
  public form8582SectionTwo9: number = 0;
  public form8582SectionThree11: number = 0;
  public form8582SectionSevena: number = 0;
  public form8582SectionSevenc: number = 0;
  public form8582SectionEighta: number = 0;
  public form8582SectionEightb: number = 0;
  public form8582SectionEightc: number = 0;

  // Other from extractions
  public isFormSR: string = '';

  // *** Extractions aux data *** //
  public ignoreExtractionsData: boolean = false;

  // Metadata for the front
  public allFlagsValidatedByUser: boolean = false;

public getExtractionsAtributes(includeFlags: boolean = true): string[] {
  let extractions = [];
  FieldsMetadata.forEach((field, key)=>{
    if(field.isExtraction === true){
      extractions.push(key)
    }
    if(key.includes('scheduleb_1') || key.includes('scheduleb_5') ){
      extractions.push(key + '_amount');
      extractions.push(key + '_text')
    }
  })

  //add attribute excluded from FieldsMetadata
  extractions.push('isFormSR');
  extractions.push('clientHasSchedule1');
  extractions.push('clientHasSchedule2');
  extractions.push('clientHasSchedule3');
  extractions.push('clientHasScheduleA');
  extractions.push('clientHasScheduleB');
  extractions.push('clientHasScheduleD');
  extractions.push('clientHasForm8582');
  extractions.push('clientHasForm8995');
  extractions.push('clientHasForm5498');
  extractions.push('clientHasForm709');
  extractions.push('clientHasForm1099');
  extractions.push('clientHasForm8880');
  extractions.push('clientHasForm8863');
  extractions.push('clientHasForm8839');
  extractions.push('clientHasForm1120');
  extractions.push('clientHasForm1120s');
  extractions.push('clientHasForm2441');
  extractions.push('clientHasForm5695');
  extractions.push('clientHasForm6251');
  extractions.push('clientHasForm8995A');


// Flags. Get the flags part for each field listed in the metadata file
// Warning. If its no listed, the flag should be addded here
let flags = taxfull_questions.getFlagKeys().map(extraction => 'flag_' + extraction);

return includeFlags ? extractions.concat(flags): extractions;

}

 constructor(
  private isProduction: boolean = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION)
 ) {
   super();
   this._classInitialState = Object.assign({}, this);
 }

  getQName(): string {
    return 'taxfull';
  }

  getIgnoredFlags(): string[]{
      return []
  }



  getIgnoredAttributesForSave():string[]{

    let ignoreExtractionFlags = []; //this.getExtractionsAtributes().map( extraction => 'flag_' + extraction);

    let ignoreOthersAttributes = ['clientIDFulltaxPolicy', 'ignoreExtractionsData', 'clientIDFulltaxPolicy2019', 'clientIDFulltaxPolicy2020', 'clientIDFulltaxPolicy2021', 'clientIDFulltaxPolicy2022', 'clientIDFulltaxPolicy2023', 'saveFlag', 'readFlag', 'isProduction', 'flagsMetadata', 'scheduleB_1', 'scheduleB_5', 'clientHasForm8995Aalert'].concat(ignoreExtractionFlags);
    //let ignoreOthersAttributes = ['clientIDFulltaxPolicy', 'ignoreExtractionsData', 'clientIDFulltaxPolicy2019']
    return ignoreOthersAttributes;

    /*
    let ignoredAttributes =  this.ignoreExtractionsData ?  ignoreOthersAttributes.concat(this.getExtractionsAtributes()) : ignoreOthersAttributes;
    return ignoredAttributes;
    */
  }

  getAttributesToClearCommas(): string[] {
    return [
      //-->Form1040
      'wages',
      'householdWages',
      'tipIncome',
      'medicalWaiverPayments',
      'taxableDependentBenefits',
      'employerAdoptionBenefits',
      'wagesFrom8919',
      'otherEarnedIncome',
      'sum1a1h',
      'taxExemptInterest',
      'taxInterest',
      'qualifiedDividends',
      'totalDividends',
      'iraDistribution',
      'taxableIRA',
      'pension',
      'taxablePension',
      'socialSecurityBenefits',
      'socialSecurity',
      'capitalGainOrLoss',
      'totalIncome',
      'adjustedIncome',
      'adjustedGrossIncome',
      'standardDeductions',
      'qbIncome',
      'taxableIncome',
      'taxes',
      'totalTax',
      'otherIncome',
      'charitableContributions1040',
      'sumStandardAndCharitable',
      'sumDeductions',
      'line17_1040',
      'line18_1040',
      'qualifyingDependentCreditClaimed',
      'line20_1040',
      'line21_1040',
      'line22_1040',
      'otherTaxesWithSelfEmployment',
      'federalIncomeTaxWithheld',
      'estimatedTaxPaymentsAmountForLastYear',
      'earnedIncomeCredit',
      'additionalChildTaxCredit',
      'americanOpportunityCreditTaken',
      'recoveryRebateCredit',
      'amountfromSchedule3line15',
      'totalOtherPayments',
      'totalPayments',

      //-->Schedule1
      'alimonyReceived',
      'businessIncome',
      'rentalRealEstate',
      'otherIncome8a',
      'otherIncome8b',
      'otherIncome8c',
      'otherIncome8d',
      'otherIncome8e',
      'otherIncome8f',
      'otherIncome8g',
      'otherIncome8h',
      'otherIncome8i',
      'otherIncome8j',
      'otherIncome8k',
      'otherIncome8l',
      'otherIncome8m',
      'otherIncome8n',
      'otherIncome8o',
      'otherIncome8p',
      'otherIncome8q',
      'otherIncome8r',
      'otherIncome8s',
      'otherIncome8t',
      'otherIncome8u',
      'otherIncome8z',
      'healthSavings',
      'selfEmployedSEP',
      'alimonyPaid',
      'iraDeduction',
      'studentLoanInterestDeduction',
      'adjustmentToIncome',
      'juryDuty',
      'deductibleExpenses',
      'nontaxableAmountOlympicMedals',
      'reforestationAmortization',
      'repaymentOfUnemployedBenefits',
      'contributionsToSection501',
      'contributionsToSection403',
      'attorneyFeesDiscriminationClaims',
      'attorneyFeesIRSHelp',
      'housingDeduction',
      'excessDeductions',
      'otherAdjustmens',
      'taxableRefunds',
      'otherGainsOrLosses',
      'farmIncome',
      'unemployementCompensation',
      'otherIncomeSchedule1',
      'additionalIncomeTotal',
      'sum1to9',
      'educatorExpenses',
      'certainBusinessExpenses',
      'movingExpenses',
      'deductibleOfSelfEmployment',
      'selfEmployedHealthInsurance',
      'penaltyEarlyWithdrawal',
      'forFutureUseSch1',
      'archerMSADeduction',
      'totalOtherAdjustments',

      //--Schedule 2
      'alternativeMinTax',
      'excessAdvPremiumTaxCredit',
      'sum1and2',
      'selfEmploymentTaxSch3',
      'socialSecurityTip',
      'uncollectedSocialSecurity',
      'totalAdditionalSocialSecurity',
      'additionalTaxOnIRA',
      'householdEmploymentTaxes',
      'repaymentFirstTimeBuyer',
      'additionalMedicare',
      'netInvestmentIncomeTax',
      'uncollectedSocialSecurityOnTips',
      'interestOnTax',
      'interestOnDeferredTax',
      'recaptureLowIncome',
      'recaptureOtherCredits',
      'recaptureFederalMortage',
      'additionalTaxHSA',
      'additionalTaxHSAdidntRemainElegibleIndividual',
      'additionalTaxArcherMSA',
      'additionalTaxMedicareAdvantageMSA',
      'recaptureOfCharitableContributions',
      'incomeFromNonqualifiedCompesationPlan',
      'incomeFromNonqualifiedCompesationPlan457A',
      'section72ExcessBenefitsTax',
      'goldenParachutePayments',
      'taxAccumulationDistributionOfTrusts',
      'exciseTaxOnInsiderStock',
      'lookbackInterestSection167or460',
      'taxNonEffectivelyConnectedIncome',
      'interestForm8621line16f',
      'interestForm8621line24',
      'anyOtherTaxes',
      'totalAdditionalTaxes',
      'reservedForFutureUse',
      'section965',
      'totalOtherTaxes',

      //-->Schedule3
      'creditForChildCare',
      'educationCredits',
      'energyCredits',
      'otherCredits',
      'schedule3line5a',
      'schedule3line5b',
      'generalBusinessCredit',
      'creditForPriorYearMinimunTax',
      'adoptionCredit',
      'creditForEldery',
      'alternativeMotorVehicleCredit',
      'qualifiedPlugInMotorVehicleCredit',
      'mortgageInterestCredit',
      'columbiaFirstTimeBuyer',
      'qualifiedElectricVehicleCredit',
      'alternativeFuelVehicleCredit',
      'creditToHoldersTaxCreditsBonds',
      'schedule3line6m',
      'amountOnForm8978',
      'otherNonRefundableCredits',
      'form2439',
      'qualifiedSickCredits',
      'healthCoverageCredit',
      'creditForRepayment',
      'futureUse13e',
      'deferredAmount965',
      'creditChildDependent2441',
      'qualifiedSickCreditsSchH',
      'otherPaymentsOrRefundableCredits',
      'estimatedTax',
      'retiramentSavings',
      'foreignTax',
      'nonrefundableCredits',
      'netPremiumTaxCredit',
      'requestForExtension',
      'excessSocialSecurity',
      'creditForFederalTaxFuels',
      'sum9to14',
      'refundableCredits',

      //-->ScheduleA
      'medicalExpenses',
      'medicalExpenses2',
      'medicalExpenses3',
      'taxesYouPaid5d',
      'taxesYouPaid',
      'investmentInterest',
      'interestYouPaid',
      'giftsByCash',
      'giftsByLand',
      'giftsToCharity',
      'casualtyLosses',
      'summationsItemizedDeductions',

      'taxesYouPaid5a',
      'taxesYouPaid5b',
      'taxesYouPaid5c',
      'taxesYouPaid5e',
      'mortgageInterests',
      'mortgageInterestsNotReported',
      'pointsNotReported',
      'mortgageInsurancePremiums',
      'sumOfLines8',

      //-->ScheduleB

      'scheduleb_2',
      'scheduleb_3',
      'scheduleb_4',
      'scheduleb_6',

      'scheduleb_1_0_amount',
      'scheduleb_1_1_amount',
      'scheduleb_1_2_amount',
      'scheduleb_1_3_amount',
      'scheduleb_1_4_amount',
      'scheduleb_1_5_amount',
      'scheduleb_1_6_amount',
      'scheduleb_1_7_amount',
      'scheduleb_1_8_amount',
      'scheduleb_1_9_amount',
      'scheduleb_1_10_amount',
      'scheduleb_1_11_amount',
      'scheduleb_1_12_amount',
      'scheduleb_1_13_amount',
      'scheduleb_1_14_amount',

      'scheduleb_5_0_amount',
      'scheduleb_5_1_amount',
      'scheduleb_5_2_amount',
      'scheduleb_5_3_amount',
      'scheduleb_5_4_amount',
      'scheduleb_5_5_amount',
      'scheduleb_5_6_amount',
      'scheduleb_5_7_amount',
      'scheduleb_5_8_amount',
      'scheduleb_5_9_amount',
      'scheduleb_5_10_amount',
      'scheduleb_5_11_amount',
      'scheduleb_5_12_amount',
      'scheduleb_5_13_amount',
      'scheduleb_5_14_amount',

      //-->ScheduleC
      'grossReceipts',
      'returns',
      'line3SchC',
      'costOfGoods',
      'grossProfit',
      'otherIncomeSchC',
      'grossIncome',
      'advertising',
      'carExpenses',
      'commissions',
      'contractLabor',
      'depletion',
      'depreciation',
      'employeeBenefit',
      'insurance',
      'interestMortgage',
      'interestOther',
      'legal',
      'officeExpenses',
      'pensionPlans',
      'vehiclesMachinery',
      'otherBusinessProperty',
      'repairs',
      'supplies',
      'taxesAndLicenses',
      'travel',
      'meals',
      'utilities',
      'wagesLessEmployeeCredits',
      'otherExpenses_schC',
      'totalExpenses',
      'tentativeProfit',
      'expensesForHomeBusiness',
      'netProfitOrLoss',

      //-->ScheduleD
      'netShortTermCapitalGains',
      'netLongTermCapitalGains',
      'shortTermLossCarryover',
      'longTermLossCarryover',
      'scheduleD13',
      'scheduleD16',

      //-->Schedule E
      'totalAmounts23a',
      'totalAmounts23b',
      'totalAmounts23c',
      'totalAmounts23d',
      'totalAmounts23e',
      'incomeSchE',
      'lossesSchE',
      'totalRentalRealEstate',
      'line30schE',
      'line31schE',
      'line32schE',
      'line35schE',
      'line36schE',
      'line37schE',
      'line39schE',
      'line40schE',
      'line41schE',

      // --> Schedule SE
      'netFarmProfitOrLoss',
      'conservationReserveProgramPayments',
      'netProfitOrLossFromSchC',
      'combinelines1a1b2',
      'line4aSchSE',
      'line4bSchSE',
      'line4cSchSE',
      'churchEmployeeIncome',
      'line5bSchSE',
      'addLines4c5b',
      'maximumAmountofCombinedWages',
      'totalSocialSecurityWages',
      'unreportedTips',
      'wagesSubjectToSocialSecurity',
      'add8a8b8c',
      'line9SchSE',
      'line10SchSE',
      'line11SchSE',
      'selfEmploymentTax',
      'deductionForOneHalfOfSelfemployment',

      //-->Form 8582
      'form8582SectionOne1a',
      'form8582SectionOne1b',
      'form8582SectionOne1c',
      'form8582SectionOne1d',
      'form8582SectionOne2a',
      'form8582SectionOne2b',
      'form8582SectionOne2c',
      'form8582SectionOne2d',
      'form8582SectionOne3',
      'form8582SectionTwo4',
      'form8582SectionTwo8',
      'form8582SectionTwo9',
      'form8582SectionThree11',
      'form8582SectionSevena',
      'form8582SectionSevenc',
      'form8582SectionEighta',
      'form8582SectionEightb',
      'form8582SectionEightc',

      //-->Form 8995
      'totalQualifiedBusinessIncome',
      'qualifiedBusinessIncomeDeduction',
      'totalReit',

      //-->Form 8995 A
      'form8995A27',
      'form8995A39',
      'form8995A40',
      'form8995A28',
      'form8995A29',
      'form8995A30',
      'form8995A31',
      'form8995A32',
      'form8995A33',
      'form8995A34',
      'form8995A35',
      'form8995A36',
      'form8995A37',
      'form8995A38',

      //-->Form 709
      'creditForForeignGift',
      'totalCreditsGift',
      'balanceGifts',
      'generationSkippingTransferTaxes',
      'totalTaxGifts',
      'generationSkippingTransferTaxesPrepaid',

      //-->Form 5498
      'contributionsMade',
      'currentContributionToWorkplaceRetirementPlanSEP',
      'currentContributionToWorkplaceRetirementPlanSIMPLE',


      //-->Legacyextractions
      'scheduleSELine2',

      // --> Questionnaire
      'taxableIRAdistForRoth',
      'clientID2018Income',
      'advisorIDYearToDateRealizedGains',
      'clientIDRealizedLongTermCapitalGains',
      'line3form2441',
      'advisorIDUnrealizedLongTermCapitalGainsAmount',

      //other
      'reiteligibleDeduction',
      'contributionMade457_1',
      'contributionMade457_2',
      'netProfitOrLoss_se'
    ];
  }

  saveFlag(flag: FullValidationFlag): boolean{
    let flagFromBackend: string = this['flag_' + flag.keyFlag];
    if(flagFromBackend){

      // Set value as a ques reponse of type: "Yes,schedule1.jpg"
      this['flag_' + flag.keyFlag] = `${flag.isActive ? 'Yes' : 'No'},${flag.url ? flag.url: 'url'}`
      console.log(this['flag_' + flag.keyFlag]);
      console.log('save flag: ' + flag.keyFlag + ' -> ', this['flag_' + flag.keyFlag]);
      return true;
    }else{
      console.log('important', 'WARNING: Flag value not found: ' + flag.keyFlag);
      return false;
    }
  }

  readFlag(extraction:string): FullValidationFlag | null{
    //console.log('readFlag: extraction -> ', extraction);
    let flagFromBackend = this['flag_' + extraction];
    let fullValidationFlag: FullValidationFlag = {
      keyFlag: extraction,
      isActive: false,
      url: '',
      metadata: {description:'', line: ''}
    }

    // Mini hack for testing / prod env.
    // Don't get the flag for people on production who aren't early access || get the flag for testing and production early access users
    //if(this.isProduction && !taxEarlyAccess.includes(localStorage.getItem('userId'))) return fullValidationFlag;

    let printError = () => {
      let message = 'WARNING: Flag value not found: ' + extraction;
      //throw(message);
      console.log('important', message);
    }
    if(Boolean(flagFromBackend)){
      // The backend data could be presented initially as an array, after save it's passed as string
      let flagValues;
      if(typeof(flagFromBackend) === 'string' && flagFromBackend.includes('[') && flagFromBackend.includes(']')){
        flagValues = JSON.parse(flagFromBackend);
      }else if(typeof(flagFromBackend) === 'string' && flagFromBackend.includes(',')){
        flagValues = flagFromBackend.split(',');
      }else{
        flagValues = flagFromBackend;
      }
      //console.log(flagValues);
      if(flagValues && flagValues.length == 2){
        fullValidationFlag.url = flagValues[1];
        fullValidationFlag.isActive = flagValues[0] === 'Yes';
        fullValidationFlag.metadata = FieldsMetadata.get(extraction)//this.flagsMetadata[extraction];
      }else{
        //printError();
      }
    }else{
      printError();
    }
    //console.log('Read flag model 1:', flagFromBackend, typeof(flagFromBackend), ' -> ', fullValidationFlag);
    return fullValidationFlag;
  }

  clearAllFlags(){
    /* Object.keys(this.flagsMetadata).map(flagKey => {
      this['flag_' + flagKey] = 'No,url';
    })*/
    taxfull_questions.getFlagKeys().map(flagKey => {
      let flagExistInModel = this['flag_' + flagKey] != undefined;
      if(flagExistInModel){
        this['flag_' + flagKey] = 'No,url';
      }

    })
  }

  static getFlagKeys(): string[] {
    let refModel = new taxfull_questions();
    let keys = Object.keys(refModel).filter(key => !refModel.getIgnoredFlags().includes(key));
    //Order the flags as ordered by the non flags values.
    let flagKeys = keys.filter(key => key.includes('flag_')); //Get flags
    let baseFlagKeys = flagKeys.map(key => key.replace('flag_','')); // Get the base part of the flag key name
    let answ = keys.filter(key => baseFlagKeys.includes(key));
    let missingFlags = ArrayAminusB(baseFlagKeys, answ);
    if(missingFlags.length > 0){
      console.warn('WARNING. Missing flags detected. Please update the model', missingFlags);
    }
    return answ //This filter orders by the main key

  }

  /* static getFlagKeys(): string[] {
    let refModel = new taxfull_questions();
    let keys = Object.keys(refModel);
    let flagKeys = keys.filter(key => key.includes('flag_'));
    return flagKeys.map(key => key.replace('flag_',''));
  }*/

}

interface FlagsMetadata {
  line: string,
  previousYear?: string,
  lineNextYear?: string

}

