import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { relationshipCatalog } from 'app/shared/components/estate-lab-v2/estate-lab-v2.utilities';
import { RequestEditSnapshot } from 'app/shared/model/Edit-Snapshot.model';
import { emptyAgentRelationship, emptyFamiliarRelationship, familiarObjectKeys, forbiddenRelationshipsToEdit, legalObjectKeys } from './utils';

@Component({
  selector: 'app-edit-estate-snapshot',
  templateUrl: './edit-estate-snapshot.component.html',
  styleUrls: ['./edit-estate-snapshot.component.scss']
})
export class EditEstateSnapshotComponent {

  // Object to retrieve and manage the Snapshot data
  // This object is used in a two way binding form
  @Input() snapshotData: RequestEditSnapshot;

  @Output() handleOnSave: EventEmitter<RequestEditSnapshot> = new EventEmitter<RequestEditSnapshot>();

  public loading = false;

  public relationshipOptions = relationshipCatalog.filter((arg) => {
    return arg.value !== "Co-Client" && arg.value !== "Client" 
  })

  public familiarObjectKeys = familiarObjectKeys;

  public legalObjectKeys = legalObjectKeys;

  /**
   * Updates the value of a question in the Family Request object
   * on the provided parameters.
   * @param {string} newValue - The new value that you want to assign to the question's value property.
   * @param {Question} item - The "item" parameter is an object that represents a specific question within a
   * group. It likely has properties such as "datapointKey" and "value".
   * @param {string} familyKey - The `familyKey` parameter is a string that represents the key of the
   * family in the `snapshotData` object. It is used to access the specific family within the object.
   * @param {number} sectionIndex - The section index refers to the index of the section within the
   * family request object. It is used to access the specific section within the family request object.
   * @param {number} groupIndex - The `groupIndex` parameter is the index of the group within the
   * section. It is used to access the specific group within the `groups` array in the `snapshotData`
   * object.
   */
  changeQuestionValue(newValue: string, item: any, familyKey: string, sectionIndex: number, groupIndex: number){
    this.snapshotData[familyKey][sectionIndex].groups[groupIndex].questions.forEach((question)=>{
      if (question.datapointKey === item.datapointKey) {
        question.value = newValue
      }
    })
  }

  /**
   * The function creates a new relationship object and adds it to the relationships array in the
   * snapshotData object. This function is used only if the section of the family request have no 
   * relationships yet.
   * @param {string} eventChange - It could contain the string with the new value of the item.
   * @param {string} field - The "field" parameter is a string that represents the field or property of
   * the relationship object that you want to create.
   * @param {string} familyKey - The `familyKey` parameter is a string that represents the key or
   * identifier of a specific family in the `snapshotData` object.
   */
  createNewRelationship(eventChange: string, field: string, familyKey: string){
    if (this.snapshotData[familyKey].relationships.length == 0) {
      if (!this.snapshotData[familyKey].questions) {
        this.snapshotData[familyKey].relationships.push(emptyFamiliarRelationship)
      }
      if (this.snapshotData[familyKey].questions) {
        this.snapshotData[familyKey].relationships.push(emptyAgentRelationship)
      }
    }
  }

  /**
   * The function filters out specific relationship options from a list of select options.
   * It is used to show only the valiid relationships for the Others section.
   * @returns an array of select options that do not include the forbidden values to edit.
   */
  filterRelationshipOptions(){
    return this.relationshipOptions.filter(item=>{
      return !forbiddenRelationshipsToEdit.includes(item.value)
    })
  }

  /* Custom sorting function that is used to return the order of
  items in a key-value pair in the same order was received */
  keyValueInOriginalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  /**
   * The save function emits an event with the snapshotData data and sets the loading variable to true.
   */
  save(){
    this.handleOnSave.emit(this.snapshotData);
    this.loading = true
  }

 /**
  * The function `customChange` updates a specific relationship value in an array based on user input.
  * @param {any} newValue - newValue is the new value that is being passed to the customChange
  * function. It likely contains information about the change that triggered the function, such as the
  * target element and its value.
  * @param relation - The `relation` parameter in the `customChange` function is used to specify the
  * relationship type that will be assigned to the `newValue` for a particular index in the
  * `this.snapshotData.familyOther.relationships` array.
  * @param index - The `index` parameter in the `customChange` function likely refers to the position
  * or index of the item in the `relationships` array that you want to update with the new value. It
  * helps identify which specific item in the array should have its `relation` property changed.
  * @returns If the condition `newValue.target.value === ' ' || (newValue.target.value.length + 1 ===
  * newValue.target.value.split(' ').length)` is met, then nothing will be returned as the function
  * will exit early due to the `return` statement. If the condition is not met, the function will
  * update the `relation` property of the `relationships` array at the specified `index` with the
  */
  customChange(newValue: any, relation, index){
    if(newValue.target.value === ' ' || (newValue.target.value.length + 1 === newValue.target.value.split(' ').length) ){
      return
    }
    this.snapshotData.familyOther.relationships[index].relation = newValue.target.value;

  }

/**
 * The function `getCustomValue` checks if a specific value exists in an array of options and returns
 * either an empty string or the value itself based on the result.
 * @param options - Options is an array of objects containing various values.
 * @param relation - The `relation` parameter in the `getCustomValue` function is used to specify a
 * value that we are checking for in the `options` array. If the `relation` value is found in any of
 * the `options`, the function returns an empty string. Otherwise, it returns the `relation
 * @returns If the `options` array contains an object with a `value` property equal to the `relation`
 * parameter, an empty string will be returned. Otherwise, the `relation` parameter itself will be
 * returned.
 */
  getCustomValue(options, relation){
    const optionsHasValue = options.filter((option) => {
      return option.value == relation;
    }).length > 0
    if (optionsHasValue) {
      return ''
    }
    else{
      return relation
    }
  }

}
