import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-record',
  templateUrl: './activity-record.component.html',
  styleUrls: ['./activity-record.component.scss']
})
export class ActivityRecordComponent implements OnInit {
  @Input() activityList = []
  @Input() showText = 'Show'
  @Input() hideText = 'Hide'
  @Input() showByDefault = false
  @Input() classToTrackClick = ''

  private showList;

  currentDate = new Date();

  constructor() {

  }

  isListVisible(){
    return this.showList === undefined ?
        this.showByDefault :
        this.showList
  }

  getButtonText(){
    if ( !this.activityList) {
      return  this.showText
    }
    return !this.isListVisible() ?  
      this.showText :
      this.activityList.length > 0 ?  
        this.hideText  :
        this.showText;
  }

  toggleVisibility() {
    this.showList = this.showList === undefined ?
       !this.showByDefault:
       !this.showList
  }

  getDate(timeStamp){
    var d = new Date(timeStamp),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
  }

  ngOnInit(): void {
  }

  getFormatedDate(updatingDate){
    //@ts-ignore
    const miliseconds = updatingDate - this.currentDate;
    if (Math.abs(miliseconds)<3600000) {
      return Math.ceil(Math.abs(miliseconds)/1000/60) + ( Math.ceil(Math.abs(miliseconds)/1000/60) < 2 ?   " minute ago" : " minutes ago")
    }
    if (Math.abs(miliseconds)<86400000) {
      return Math.ceil(Math.abs(miliseconds)/1000/60/60) + (Math.ceil(Math.abs(miliseconds)/1000/60/60) < 2 ?  " hour ago" : " hours ago")
    }
    if (Math.abs(miliseconds)<604800000) {
      return Math.ceil(Math.abs(miliseconds)/1000/60/60/24) + (Math.ceil(Math.abs(miliseconds)/1000/60/60/24) < 2 ? " day ago": " days ago")
    }
    if (Math.abs(miliseconds)>604800000 && Math.abs(miliseconds)<1209600000) {
      return "Last week"
    }
    else{
      return this.getDate(updatingDate)
    }
  }

}
