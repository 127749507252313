import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogGenericNoticeComponent } from 'app/shared/components/dialog-generic-notice/dialog-generic-notice.component';
import { SequentialJobsDispatcher } from 'app/shared/helpers/SequentialJobsTimerDispatcher';
import { UrlParamEditor } from 'app/shared/helpers/url.helper';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { FileItem, ParsedResponseHeaders } from 'ng2-file-upload';

import { AppSocketIoService } from '../../../shared/services/socket.io.service';
import { FileUploaderExtended } from '../FileUploaderExtended.model';
import { showUploadGuideComponent } from '../uploader/showUploadGuide.component';
import { BehaviorSubject } from 'rxjs';

/* case 'initial': inital(); break;
case 'uploading': uploading(); break;
case 'uploadError': uploadError(); break;
case 'validating': validating(); break;
case 'validatedValid': validatedValid(); break;
case 'validatedVoid': validatedVoid(); break; */

export const UPLOADER_STATUS = {
  INITIAL: 'initial',
  UPLOAD_UPLOADING: 'uploading',
  UPLOAD_ERROR: 'uploadError',
  FILE_VALIDATING: 'validating',
  FILE_PROGRESS: 'progress',
  FILE_VALID: 'validatedValid',
  FILE_INVALID: 'validatedVoid',
  FILE_UNDER_REVIEW: 'fileUnderReview',
  INCORRECT_PASSWORD: 'uploadedPasswordProtected',
}

@Component({
  selector: 'app-file-uploader-component',
  templateUrl: './file-uploader-component.component.html',
  styleUrls: ['./file-uploader-component.component.scss']
})
export class FileUploaderComponentComponent {
  @Input() validationState: string = "";
  @Input() options: FileUploaderOptions;
  @Input() displayAsNotReadyIf: boolean = false;
  @Input() customLabel: string = ''
  @Input() clientIDTaxYear: string = '';
  @Input() hidePasswordProtectedParagraph: boolean = false;
  @Output() onUploaderReset: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFileLoad = new EventEmitter<string>();
  @Output() onFileLoadChange = new EventEmitter<string>();
  @Output() onEndOfInteraction = new EventEmitter<string>();
  @Input() public enabledInput: boolean = true;

  //public validationState: string = '';
  public fileID: string = null;
  public fileVal: string = '';
  public documentReadableName: string = 'document';
  public uploader: FileUploaderExtended;
  public loadedPercentage: number = 0;
  public currentState: number;
  public sectionA: string = ''; //The data displayed in the file name of the uploader.
  public sectionB: string = ''; //The data to display in the last part of the file name section.
  public isEnabled: boolean = false;
  public isComponentEnabled: boolean = true;
  public uploaderStatusConsts = UPLOADER_STATUS;
  // START DEBUG DATA
  public token1: string = '';
  public token2: string = '';
  // END DEBUG DATA
  public currentExtractionResponse: FileExtractionsResponse = new FileExtractionsResponse('', '');
  private hasBeenDisplayedInvalidModal: number = 0;
  private isTimerSet: boolean = false;
  private isAdvisor: boolean = false;
  public processingFileSubject = new BehaviorSubject(false);
  public isProcessing = false;
  public lastNotificationIsFromSocket: boolean = false;
  @Output() handleProcessingFile = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    private socketService: AppSocketIoService,
    public authService: AuthenticationService
  ){
    this.isAdvisor = this.authService.isAdvisor();
    this.processingFileSubject.subscribe((res)=>{
      console.log('processingFileSubject', res);
      this.isProcessing = res;
      this.handleProcessingFile.emit(res);
    })
   }

  ngOnChanges(changes: SimpleChanges): void {
    if(Boolean(changes.enabledInput)){
      this.isEnabled = changes.enabledInput.currentValue;
    }
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Detect when external component set the validation state propertie, not internal change
    this.lastNotificationIsFromSocket = false;
    const validationState: SimpleChange = changes.validationState;
    const options: SimpleChange = changes.options;
    if (validationState != undefined && validationState.currentValue != '') {
      this._extractionResponseToAction(validationState.currentValue);
    }
    if (options != undefined) {
      this.options = options.currentValue;
      if (this.options._areOptionsReady()) {
        this.options.isComponentAlive = true;
        this.options.componentRef = this;
        this._load();
      } else {

      }
    }

  }

  private _load() {

    // Load the data from options
    Object.keys(this.options).forEach(option => {
      //No validate has own prop due properties definde to null do not belong  if(this.hasOwnProperty(option))
      this[option] = this.options[option];
    });

    // Set the uploader url set the filename value as same as the options fileId
    // These overrides the uploader factory behaviour
    let uploaderUrlWithfileName = UrlParamEditor(this.options.uploader.currentUrl, 'policyFilename', this.options.fileID);
    this.options.uploader.setUrl(uploaderUrlWithfileName);


    //Load the inital state, if is there is a previous data then ngonchanges detects it an load the specific state
    if (this.validationState == '' || this.validationState == undefined) {
      this.setState(UPLOADER_STATUS.INITIAL);
    }

    //Loading file
    this.uploader.onProgressAll = (progress => {
      this.loadedPercentage = progress;
      this.setState(UPLOADER_STATUS.UPLOAD_UPLOADING);
    });

    //Loading error detected
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.setState(UPLOADER_STATUS.UPLOAD_ERROR);
    }

    //Loaded, waiting for validation
    this.uploader.onCompleteAll = () => {
      // An upload error can get here, so, avoid entering in validation state
      if (this.options.status != UPLOADER_STATUS.UPLOAD_ERROR){
        this.setState(UPLOADER_STATUS.FILE_VALIDATING);
      }
    }

    //Listen to validation response

     //Subscribe to socket listener
     this.socketService.listenToAreaExtractions.push(this.fileID);
     this.socketService.notificationFileExtractions.subscribe((extractionResponse: any) => {
      this.lastNotificationIsFromSocket = true;

       let extractionMeta: FileExtractionsResponse = extractionResponse.metadata
       //let componentResponse = data.filter(extractionResponse => { return extractionResponse.fileId == this.fileID })[0];

       if (extractionMeta.fileID == this.fileID) {
         this.currentExtractionResponse = extractionMeta;
         if(this.options.isComponentAlive && !this.options.ignoreIsComponentAlive){
          this._extractionResponseToAction(extractionMeta.validationStatus);
          //this.processingFileSubject.next(true)
         }
       }
     });
  }

  _extractionResponseToAction(response: string) {


    if (response == undefined || response == null) {
      this.setState(UPLOADER_STATUS.INITIAL);
      return;
    };

    switch (response) {
      case 'COMPLETE': this.setState(UPLOADER_STATUS.FILE_VALID); break;
      case 'PENDING': this.setState(UPLOADER_STATUS.FILE_VALIDATING); break;
      case 'PROGRESS': this.setState(UPLOADER_STATUS.FILE_PROGRESS); break;
      case 'EMPTY': this.setState(UPLOADER_STATUS.INITIAL); break;
      case 'INVALID': this.setState(UPLOADER_STATUS.FILE_INVALID); break;
      case 'INCORRECT_PASSWORD': this.setState(UPLOADER_STATUS.INCORRECT_PASSWORD); break;
      case 'UNDER_REVIEW': this.setState(UPLOADER_STATUS.FILE_UNDER_REVIEW); break;
      case 'NUM_OF_PAGES': break;
      default:
        this.setState(UPLOADER_STATUS.FILE_INVALID);
    }



  }

  async _onFileLoad() {


    // If the file coudn't be loaded (Maybe isn't the request type of file)
    if(this.uploader.queue[0] === undefined){
      this.setState(UPLOADER_STATUS.UPLOAD_ERROR);
      this.sectionA = 'Wrong file, please retry';
      return;
    }

    this.token1 = this.getJWT(); //DEBUG LINE
    let fileRef: any  = this.uploader.queue[0]._file;

    // Declare the upload function
    let uploadData = () => {
      // Read and append all the extra query params.
      Object.keys(this.options.extraQueryParams).map(paramKey => {
        this.uploader.setUrl(UrlParamEditor(this.uploader.currentUrl, paramKey, this.options.extraQueryParams[paramKey]));
      })

      // Upload the file and send notification of status change
      this.processingFileSubject.next(true)
      this.uploader.uploadAll();
      this.onFileLoad.emit(this.fileID);
      this.onFileLoadChange.emit(this.fileID);
    }


    //Detect password protected PDF if the option is active
    if(this.options.blockPasswordProtectedPDF){
      let rawPdfData = await fileRef.text();
      if(false /* isPDFEncryptedFromRawData(rawPdfData) */){
        let modal = this.openInputPasswordModal();
        modal.afterClosed().subscribe(result => {
          if(modal.componentInstance.data.outputFieldsData['password']){
            this.options.extraQueryParams['password'] = modal.componentInstance.data.outputFieldsData['password'];
            uploadData();
          }
        })
      }else{
        uploadData();
      }
    }else{
      delete(this.options.extraQueryParams['password']);
      uploadData();
    }

  }

  _onFileDelete() {
    this.processingFileSubject.next(false)
    this.onFileLoad.emit(this.fileID);
    this.onFileLoadChange.emit(this.fileID);
    this.setState(UPLOADER_STATUS.INITIAL);
  }

  resetUploader() {
    if (this.uploader.queue[0] != undefined) {
      this.uploader.queue[0].remove();
    }
    this.uploader.clearQueue();
    this.onUploaderReset.emit(this.fileID);
    this._onFileDelete();
    this.hasBeenDisplayedInvalidModal = 0;
    this.onEndOfInteraction.emit(this.uploaderStatusConsts.INITIAL);

  }

  openInputPasswordModal(modalTitle: string = 'File password is required'){
    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-review-extractions',
      width: '55vw',
      data: {
        title: modalTitle,
        buttonsContainerClass: 'align-left',
        body:
          `<strong>Please input your file's password.</strong><br>`,
        inputFields: [
          {type: 'password', key: 'password', text: "File's password"}
        ],
        actionButtons: [

          {
            text: 'Cancel',
            class:'button-secondary-on-white',
            action: () => {
              dialogRef.componentInstance.data.outputFieldsData['password'] = '';
              dialogRef.close();
            }
          },
          {
            text: "Submit",
            class: 'button-primary',
            action: () => {
              dialogRef.close();
            }
          }
        ]
      }
    });
    return dialogRef;

  }

  showUploaderGuideModal() {
    const dialogRef = this.dialog.open(showUploadGuideComponent, {
      data: "message"
    });
  }

  setState(status: any = null) {

    this.options.status = status;
    console.log('status', status);


    let inital = () => {
      this.fileVal = '';
      this.loadedPercentage = 0;
      this.validationState = '';
      this.sectionA = 'Select ' + this.options.documentReadableName;
      this.sectionB = '';
      this.isEnabled = this.enabledInput || true;
      this.isComponentEnabled =  true;
      this.processingFileSubject.next(false);
    }

    let uploading = () => {
      this.sectionA = this.uploader.queue[0].file.name;
      this.sectionB = this.loadedPercentage + ' %';
      this.isEnabled = false;
      this.isComponentEnabled =  true;
      this.processingFileSubject.next(true);

    }

    let uploadError = () => {
      this.token2 = this.getJWT(); //DEBUG LINE

      this.sectionA = 'Please retry upload';
      this.sectionB = 'ERROR';
      this.isEnabled = false;
      this.onEndOfInteraction.emit(UPLOADER_STATUS.UPLOAD_ERROR);
      this.processingFileSubject.next(false)
      this.isComponentEnabled =  true;
    }

    let uploadedPasswordProtected = () => {
      this.validationState = 'VALIDATING';
      this.sectionA = this.fileVal != "" ? this.fileVal : this.documentReadableName;
      this.sectionB = 'PASSWORD REQUIRED';
      this.isEnabled = false;
      this.onEndOfInteraction.emit(UPLOADER_STATUS.INCORRECT_PASSWORD);
      this.processingFileSubject.next(false)
      this.isComponentEnabled =  true;
    }

    let validating = () => {
      this.validationState = 'VALIDATING';
      this.sectionA = this.isAdvisor ? this.validationState : 'Successfully uploaded';
      this.sectionB = this.isAdvisor ? 'Please wait' : 'Uploaded';
      this.isEnabled = false;
      this.processingFileSubject.next(true)
      this.isComponentEnabled = this.isAdvisor ? true : false;
    }

    let progress = () => {
      this.validationState = 'VALIDATING';
      this.sectionA = this.currentExtractionResponse.number + '%';
      this.sectionB = this.validationState;
      this.isEnabled = false;
      this.processingFileSubject.next(true)
      this.isComponentEnabled =  true;
    }

    let fileUnderReview = () => {
      this.validationState = 'VALIDATING';
      this.sectionA = ''
      this.sectionB = 'Pending validation'
      this.isEnabled = false;
      this.processingFileSubject.next(true)
      this.isComponentEnabled =  false;
    }

    let validatedValid = () => {
      this.validationState = 'VALID';
      this.sectionA = this.fileVal != "" ? this.fileVal : this.documentReadableName;
      this.sectionB = 'SUBMITTED';
      this.isEnabled = false;
      this.onEndOfInteraction.emit(UPLOADER_STATUS.FILE_VALID);
      this.processingFileSubject.next(false)
      this.isComponentEnabled =  true;
    }

    let validatedVoid = () => {
      this.validationState = 'INVALID';
      this.sectionA = '';
      this.sectionB = 'SUBMITTED'; //this.validationState;
      this.isEnabled = false;
      this.isComponentEnabled =  true;
      //Display invalid modal only when whe have en invalid - socket extraction - result. Not at loading
      this.hasBeenDisplayedInvalidModal += 1;

      if (this.hasBeenDisplayedInvalidModal === 1 && this.currentExtractionResponse.validationStatus == 'INVALID') {
        if (this.options.actionsAfterResponse[UPLOADER_STATUS.FILE_INVALID] && typeof this.options.actionsAfterResponse[UPLOADER_STATUS.FILE_INVALID] === 'function') {
          this.options.actionsAfterResponse[UPLOADER_STATUS.FILE_INVALID]();
        }
        // this.showPDFErrorModal();
      }

      this.onEndOfInteraction.emit(UPLOADER_STATUS.FILE_INVALID);
      this.processingFileSubject.next(false)

    }

    switch (status) {
      case UPLOADER_STATUS.INITIAL: inital(); break;
      case UPLOADER_STATUS.UPLOAD_UPLOADING: uploading(); break;
      case UPLOADER_STATUS.UPLOAD_ERROR: uploadError(); break;
      case UPLOADER_STATUS.FILE_VALIDATING: validating(); break;
      case UPLOADER_STATUS.FILE_PROGRESS: progress(); break;
      case UPLOADER_STATUS.FILE_VALID: validatedValid(); break;
      case UPLOADER_STATUS.FILE_INVALID: validatedVoid(); break;
      case UPLOADER_STATUS.FILE_UNDER_REVIEW: fileUnderReview(); break;
      case UPLOADER_STATUS.INCORRECT_PASSWORD: uploadedPasswordProtected(); break;
      default:
        //throw {message: "Error. Undefined state."}
        console.log("Error. Undefined state: ", status);
    }

    //Stop counter if status is an end of interaction
    if (this.isTimerSet &&
      (
        this.options.status == UPLOADER_STATUS.INITIAL ||
        this.options.status == UPLOADER_STATUS.FILE_VALID ||
        this.options.status == UPLOADER_STATUS.FILE_INVALID ||
        this.options.status == UPLOADER_STATUS.UPLOAD_ERROR
      )) {
      SequentialJobsDispatcher.getInstance().stopOneJob(this.options.fileID);
      this.isTimerSet = false;
    }

    //Execute an external lambda function if any is defined and correlated to the current validation state
    if (this.options.status != UPLOADER_STATUS.FILE_INVALID && this.options.actionsAfterResponse[this.options.status] != undefined && typeof this.options.actionsAfterResponse[this.options.status] === 'function') {

      this.options.actionsAfterResponse[this.options.status]();
    }

  }

  isRequiredValid(): boolean {
    if (this.options.isRequired) {
      return this.options.status == UPLOADER_STATUS.FILE_VALID;
    } else {
      return true;
    }
  }

  //Debug functions only
  getJWT(): string {
    return localStorage.getItem('SessionToken');
  }

  // Detect When the component ends its life for validation propouses
  ngOnDestroy() {
    this.options.isComponentAlive = false;
    SequentialJobsDispatcher.getInstance().stopOneJob(this.options.fileID);

    let areaIndexInSocketList = this.socketService.listenToAreaExtractions.findIndex(area => {return area == this.fileID});
    this.socketService.listenToAreaExtractions.splice(areaIndexInSocketList, 1);
    //this.socketService.notificationFileExtractions.unsubscribe();
  }
}

export class FileExtractionsResponse {

  constructor(
    public fileID: string,
    public validationStatus: string,
    public fileVal: string = '',
    public number: number = 0,
  ) { }
}

/**
 * File uploader interface
 */

export interface FileUploaderOptionsInterface {
  fileID: string,
  fileVal: string,
  uploader: FileUploaderExtended,
  documentReadableName?: string
  validationRule?: ((() => boolean) | validationRuleSet),
  isRequired?: boolean,
  status?: string,
  actionsAfterResponse?: any,
  blockPasswordProtectedPDF?: boolean;
  extraQueryParams?: any;
  ignoreIsComponentAlive?: boolean;
}

/**
 * Class to initialize and comunicate between parent component and FileUploader component
 */

export class FileUploaderOptions implements FileUploaderOptionsInterface {
  public fileID: string;
  public fileVal: string;
  public uploader: FileUploaderExtended;
  public documentReadableName?: string;
  public validationRule?: (() => boolean) | validationRuleSet;
  public isRequired?: boolean;
  public status?: string;
  public actionsAfterResponse?: any;
  public isComponentAlive: boolean = false;
  public blockPasswordProtectedPDF: boolean = false;
  public extraQueryParams?: any = {};
  public componentRef: FileUploaderComponentComponent;
  public ignoreIsComponentAlive?: boolean;

  constructor(
    public initializer: FileUploaderOptionsInterface,
    ) {
    this.fileID = initializer.fileID;
    this.fileVal = initializer.fileVal;
    this.uploader = initializer.uploader;
    this.documentReadableName = initializer.documentReadableName ? initializer.documentReadableName : 'document';
    this.validationRule = initializer.validationRule;
    this.isRequired = initializer.isRequired ? initializer.isRequired : false;
    this.status = initializer.status ? initializer.status : UPLOADER_STATUS.INITIAL;
    this.actionsAfterResponse = initializer.actionsAfterResponse ? initializer.actionsAfterResponse : {};
    this.blockPasswordProtectedPDF = (initializer.blockPasswordProtectedPDF != undefined) ? initializer.blockPasswordProtectedPDF : this.blockPasswordProtectedPDF;
    this.extraQueryParams = initializer.extraQueryParams != undefined ? initializer.extraQueryParams : this.extraQueryParams;
    this.ignoreIsComponentAlive = initializer.ignoreIsComponentAlive || false;
  }

  /**z
   * _areOptionsReady
   * Validate that the options required to initialice the FileUploaderComponent are ready
   */
  _areOptionsReady(): boolean {
    let requiredOptions: string[] = [
      'fileID',
      'fileVal',
      'uploader',
    ];
    return requiredOptions.map(option => this[option] != null).reduce((a, b) => a && b);
  }

  /**
   * executeValidationRule
   * Execute and validate that the predefined set of rules are met in order to aprove the form submit
   */
  private _executeValidationRule(): boolean {

    if (this.validationRule != null && typeof this.validationRule == 'function') {
      return this.validationRule();
    } else {
      console.log("Invalid type for validationRule function");
    }

    return true;
  }

  isAllValid(): boolean {
    if (typeof this.validationRule == 'function') {
      return this._executeValidationRule();
    }
    return this.isUploaderValid(this.validationRule);
  }

  isUploaderValid(validationSet: validationRuleSet = null): boolean {
    let defaultValidationSet: validationRuleSet = {
      ignoreValidationByRule: !this.isComponentAlive,
      validationRule: this.status == UPLOADER_STATUS.FILE_VALID
    }
    validationSet = (validationSet != null) ? validationSet : defaultValidationSet;
    return validationSet.ignoreValidationByRule ? true : validationSet.validationRule
  }




}

export interface validationRuleSet {
  ignoreValidationByRule: boolean;
  validationRule: boolean;
}

export function isPDFEncryptedFromRawData(rawData: string): boolean{

  // Find all the texts between << ... >>
  let metaDataSections = rawData.match(/<<([\s\S]*?)>>/gm);
  // For each found text, search for the word /Encrypt
  return Boolean(
    metaDataSections.find(section => /\/Encrypt/.test(section)) ||
    metaDataSections.find(section => /\/AuthEvent\/DocOpen\//.test(section))
    )

}

