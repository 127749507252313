import { style } from '@angular/animations';
import { HttpHeaders } from "@angular/common/http";
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from "./url.helper";

export function getIndexBy(array: Array<{}>, { name, value }): number {
  for (let i = 0; i < array.length; i++) {
    if (array[i][name] === value) {
      return i;
    }
  }
  return -1;
}


export function colorHexToRgb(hexColor: string): ({ r: number, g: number, b: number }) {
  if(!Boolean(hexColor)){ //No color attribute
    hexColor = "#FFF";
    console.warn('colorHexToRgb. No color was detected, returning white as default.');
  }
  let result = null;
  if (hexColor.length == 7) { result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor); }
  else if (hexColor.length == 4) { result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hexColor); }
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function rgba2rgb( RGBA_color: rgba, RGB_background: rgb = {r: 255, g: 255, b: 255}): rgb
{
    var alpha = RGBA_color.a;

    return {
        r: (1 - alpha) * RGB_background.r + alpha * RGBA_color.r,
        g: (1 - alpha) * RGB_background.g + alpha * RGBA_color.g,
        b: (1 - alpha) * RGB_background.b + alpha * RGBA_color.b
    }
}

interface rgb { r: number, g: number, b: number };
interface rgba { r: number, g: number, b: number, a: number };

export function areAreasWithRecsInLocalStorage(): boolean {
  let response = false;
  let areasWithRecs = sessionStorage.getItem('areasWithRecommendationsReady');

  if (areasWithRecs) {
    let areasWithRecsObj = JSON.parse(areasWithRecs);
    for (let area in areasWithRecsObj) {
      response = response || Boolean(areasWithRecsObj[area]);
    }
  }
  return response;
}

export function utilsCalculateAge(DOB: Date): number {
  var today = new Date();
  var birthDate = new Date(DOB);

  if (today.toISOString().split('T')[0] == birthDate.toISOString().split('T')[0]) return 0;

  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1;
  }

  // Add months passed in decimal
  let todayMonth = today.getMonth();
  let birthdayMonth = birthDate.getMonth();
  let monthsCount = (todayMonth < birthdayMonth) ? (12 - (birthdayMonth - todayMonth)) : (todayMonth > birthdayMonth) ? todayMonth - birthdayMonth : /* Same month, count month */  1;
  let monthsInDecimal = + Number(monthsCount / 12).toFixed(2);
  age = age + monthsInDecimal;

  if (isNaN(age) || age < 0) return 0;
  if (age > 120) return 120;

  return age;
}

export function imageToB64FromUrl(url: string, w?: number, h?: number): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    img.addEventListener("load", () => {
      // Set the default values for w anf h if not present
      if(w == undefined && h == undefined){
        w = img.width;
        h = img.height;
      }

      let canvas = document.createElement('canvas');
      canvas.width = w;
      canvas.height = h;
      // get the scale
      var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
      // get the top left position of the image
      var x = (canvas.width / 2) - (img.width / 2) * scale;
      var y = (canvas.height / 2) - (img.height / 2) * scale;

      //if(img.naturalWidth > 150)
      let ctx = canvas.getContext("2d");
      //Delete all transparency with a white color brackground
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //Draw image
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

      resolve(canvas.toDataURL("image/png"));
    });
    img.addEventListener("error", err => reject(err));
    img.src = url;
  });
};

export async function imageToB64FromUrlReport(imageUrl): Promise<string> {
  return new Promise(async (resolve, reject) => {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(''+reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}

export function imageToCanvasFromUrl(url: string, destinationWidth?: number, destinationHeight?: number): Promise<any> {
  return new Promise((resolve, reject) => {
    const img = new Image(); // Here the image will be loaded using the url param
    img.crossOrigin = 'Anonymous';

    img.addEventListener("load", () => {

    //Calculate the image scale factor and the final widht and height.

      //Box (W x H) -> medidas destino.
      // img -> medidas origen.
      // canvas -> donde se aplicarán las medidas destino.

      //Casos:

      // 1. Sin medidas para dimensiones de destino: canvas utiliza las mismas que la imagen, escala: 1.
      // 2. Ambas medidas establecidas por argumento: canvas utiliza las dimensiones de los argumentos, escala calculada utilzando las escala mas pequeña.
      // 3. Una solo medida para el argumento: canvas utiliza las dimensiones escaladas, se toma la escala relacionada al argumento dado.

      let scale: number = 1; //Initial scale factor.

      let scaleWidth = destinationWidth / img.width; //Scale factor using width
      let scaleHeight = destinationHeight / img.height; //Scale factor using height

      if(!Boolean(destinationWidth) && !Boolean(destinationHeight)){
        scale = 1;
        destinationWidth = img.width;
        destinationHeight = img.height;

      }else if(Boolean(destinationWidth) && Boolean(destinationHeight)){
        scale = Math.min(scaleWidth, scaleHeight); //The proportional scale is the minimum between the scaleWidth and scaleHeight
        //destinationWidth = The specified by the function argument;
        //destinationHeight = The specified by the function argument;

        //If only one dimension is used as input, the image needs to be scaled using the correspondent scale
      }else if(!Boolean(destinationWidth) || !Boolean(destinationHeight)){
        scale = Boolean(destinationWidth) ? scaleWidth : scaleHeight;
        destinationWidth = img.width * scale;
        destinationHeight = img.height * scale;

      }

      // Define the destination canvas.
      let canvas = document.createElement('canvas');
      canvas.width = destinationWidth;
      canvas.height = destinationHeight;

      // get the top left position of the image to be printed on the canvas.
      var x = (canvas.width / 2) - (img.width / 2) * scale;
      var y = (canvas.height / 2) - (img.height / 2) * scale;

      //if(img.naturalWidth > 150)
      let ctx = canvas.getContext("2d");
      //Delete all transparency with a white color brackground
      ctx.fillStyle = "rgba(255, 255, 255, 0)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //Draw image
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

      resolve(canvas);
    });
    img.addEventListener("error", err => reject(err));
    img.src = url;
  });
};

export function imageFromUrl(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    img.addEventListener("load", () => {
      resolve(img);
    });
    img.addEventListener("error", err => reject(err));
    img.src = url;
  });
};

export function findInsideArrayOfMonoObjectsByKey(objsArray: any[], key: string = 'key'): any | undefined{
  let index = objsArray.findIndex(obj => {return Object.keys(obj)[0] == key});
  return index > 0 ?  objsArray[index] : undefined;
}

export function ArrayAminusB(A: any[], B: any[]): any[]{
  return A.filter(n => !B.includes(n));
}

export function ArrayOfObjsAminusB(A: any[], B: any[], compareValue: string): any[]{
  if(A === undefined || B === undefined){
    console.warn('ArrayOfObjsAminusB. Invalid operands', A, B);
    return [];
  }
  return A.filter(a => !B.find(b => b[compareValue] == a[compareValue]));
}

/**
 * Deletes an element from an array of objects, if one of the elements(object) match the indicated key->value
 * @param arrayRef
 * @param key
 * @param value
 * @returns
 */
export function deleteOneFromArrayOfObjs(arrayRef: any[], key: string, value: any): boolean{
  let itemIndex = arrayRef.findIndex( obj => obj[key] == value);
    if(itemIndex >= 0){
      return Boolean(arrayRef.splice(itemIndex, 1));
    }
  return false;
}

export function getRandomInt(min, max): number {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getRandomStr(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


/**
 * Gets a prop value from an Object inside the Local Storage or Session Storage.
 * @param objectName The key inside the storage
 * @param prop The prop key inside the object
 * @param scope Storage type: 'Local' | 'Session'. Default value: 'Local'
 * @returns The prop value from the requested object or undefined
 */

export function readFromStoragedObject(objectName: string, prop: string, scope: ('Local' | 'Session') = 'Local'): any{
  try{
    let storagedValue = scope == 'Local' ? localStorage.getItem(objectName) : sessionStorage.getItem(objectName);
    let obj = storagedValue != null ? JSON.parse(storagedValue) : null;
    return obj[prop];
  }catch{
    console.log('ALERT', `The local object couldn't be read: scope: ${scope}Storage, objectName: ${objectName}, prop: ${prop}`)
    return undefined;
  }
}

export function setObjectToStorage(propName: string, value: any, scope: string = 'Local'): any{
  const valueToSet = typeof value === 'object' ? JSON.stringify(value) : value;
  scope == 'Local' ? localStorage.setItem(propName, valueToSet) : sessionStorage.setItem(propName, valueToSet);
}

/**
 * Deletes and return the elements from the array that match the lamda condition.
 * This operation will modify the original array!.
 * @param array
 * @param lamdaCondition
 * @optional foundedElementsStorage
 * @returns array of founded elements.
 */
export function substractFromArray(array: any[], lamdaCondition:((arrayElement: any)=>boolean), foundedElementsStorage: any[] = []){

  //Buscar en array
  let foundIndex = array.findIndex((element)=>{return lamdaCondition(element)});
  if(foundIndex >= 0){
    foundedElementsStorage.push(...array.splice(foundIndex, 1));
  }else{
    return foundedElementsStorage;
  }
  substractFromArray(array, lamdaCondition, foundedElementsStorage)

}

/**
 * pushIntoArrayIfObjetUnique
 * @param array
 * @param elementToPush
 * @returns
 */
export function pushIntoArrayIfObjectIsUnique(array: any[], elementToPush: any, key: string = 'id', updateIfCoincidence: boolean = false): boolean{
  let foundIndex = array.findIndex(element => element[key] == elementToPush[key]);
  let isInsideArray: boolean = foundIndex >= 0;
  if(!isInsideArray){
    array.push(elementToPush);
  }else if(updateIfCoincidence){
    array[foundIndex] = {... elementToPush};
  }
  return !isInsideArray;
}

/**
 * pushIntoArrayIfUnique
 * @param array
 * @param elementToPush
 * @returns
 */
 export function pushIntoArrayIfUnique(array: any[], elementToPush: any): boolean{
  let isInsideArray:boolean = (array.findIndex(element => { return element == elementToPush})) >= 0;
  if(!isInsideArray){
    array.push(elementToPush);
  }
  return !isInsideArray;
}


/**
 * Caculate the dimensions of a 2 dimension array with varible content
 * @param array2D
 * @returns x: max inner size, y: max outer size
 */
export function calculate2DArrayMaxDimensions(array2D: any[][]):({x: number, y: number}){
  return {
    x: array2D.reduce((x, y) => Math.max(x, y.length), 0),
    y: array2D.length
  }
}

/**
 * The collapse function simillar to the ?? op in php.
 * Returns the first argument if the first exists val != null
 * @param toValidate
 * @param ifInvalid
 * @returns
 */
export function _collapseOp(toValidate: any, ifInvalid: any){
  return Boolean(toValidate) ? toValidate : ifInvalid;
}

export interface ObjDiffList{
  key: string,
  value1?: any,
  value2?: any,
  objDiffList?: ObjDiffList[]
}

export  async function delay_ms(ms: number, instance?){
  return  new Promise(res => setTimeout(res, ms, instance));
}

/*export function obtainObjectDiferences(obj1, obj2, forceComparsion: boolean = true): ObjDiffList[]{

  let diferencesList: ObjDiffList[] = [];
  if(typeof obj1 !== 'object' || obj1 == null || typeof obj2 !== 'object' || obj2 == null){
    console.warn('obtainObjectDiferences. No fair comparsion one of the operands isnt an object', obj1, obj2);
    return [];
  }


  let obj1Keys = Object.keys(obj1);
  let obj2Keys = Object.keys(obj2);

  if(obj1Keys.length == 0 && obj2Keys.length == 0){ return []}

   if(!forceComparsion && obj1Keys.length !== obj2Keys.length){
    console.warn('obtainObjectDiferences. The objects are not the same');
    return []
  }

  obj1Keys.map(objKey => {

    if(typeof obj1[objKey] === 'object' && typeof obj2[objKey] === 'object'){

      let objDiffList = obtainObjectDiferences(obj1[objKey], obj2[objKey]);

      if(objDiffList.length > 0){
        diferencesList.push({key: objKey, objDiffList})
      }

    }else{

      let key = objKey;
      let value1 = obj1[objKey];
      let value2 = obj2[objKey] !== undefined ? obj2[objKey] : null;

      if(value1 !== value2){
        diferencesList.push({key, value1, value2})
      }
    }
  })

  return diferencesList;

}*/

export const delay = ms => new Promise(res => setTimeout(res, ms));

/**
 *
 * @param clientsName
 * @returns
 */
export const cleanNameForPDF = (clientsName: string) => {
  let aux = clientsName.split('.');
  aux = aux.join('').split(' ');
  return aux.join('_');
}

export function sizeOfNDimensionArray(arrayRef){

}

/**
 * Paty's way of resolving the filling status other
 * @param maritalStatus
 * @param filingStatus
 * @returns
 */
export function findTablesFilingStatus(maritalStatus, filingStatus){
  const maritalFilingRelations = {
      'Single': {
          'Single': 'Single',
          'Joint': 'Single',
          'Other': 'Single',
      },
      'Married': {
          'Single': 'Married Filing Separately',
          'Joint': 'Married Filing Jointly',
          'Other': 'Married Filing Separately',
      },
      'Widowed': {
          'Single': 'Single',
          'Joint': 'Single',
          'Other': 'Qualifying Widow(er)',
      },
      'Divorced': {
          'Single': 'Single',
          'Joint': 'Single',
          'Other': 'Single',
      },
      'Domestic Partner': {
          'Single': 'Single',
          'Joint': 'Single',
          'Other': 'Single',
      },
  };

  return maritalFilingRelations[maritalStatus][filingStatus];
};

/**
 * Returns the address (x,y) of an element inside an homogeneous 2D array.
 * @param array2D
 * @param key
 * @param value
 */
export function findIndexOfElementInside2DArrary(array2D: any[][], value: any, key?: string,){
  let col: number = 0;
  let row: number = 0;
  let found: boolean = false;

  array2D.every((currentRow, _y) =>{
    currentRow.every((elementInRow, _x) => {
      col = _x; row = _y;
      found = (key ? (elementInRow[key] != undefined && elementInRow[key] === value) : elementInRow === value);
      //console.log('el', row, col, elementInRow, key, value, found)
      return !found
    })
    return !found
  })

  if(found){
    return {col, row}
  }else{
    return null
  }

}

export function getElementHeight(HTML_element): number {
  let node_styles = window.getComputedStyle(HTML_element);
  let node_height =
    parseInt(node_styles.height) +
    parseInt(node_styles.marginTop) +
    parseInt(node_styles.marginBottom);
  return node_height;
}


export async function advisorTemporalSingleToken(httpClientInstance): Promise<string> {

  let currentSessionToken = sessionStorage.getItem('sessionToken');
  let advisorId = localStorage.getItem('userId');
  let advisorName = readFromStoragedObject('advisorInfo', 'fullName', 'Local');
  let advisorEmail = readFromStoragedObject('advisorInfo', 'email', 'Local');


  let options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${currentSessionToken}`,
    }),
    responseType: 'text' as 'text'
  };

  let clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');

  let body = {
      "clientId": clientId,
      index: "1",
      "advisor": {
        "_id": advisorId,
        "email": advisorEmail,
        "fullName": advisorName
      },
  }

  let domain = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://auth.fpalpha.com' : 'https://auth.beta.fpalpha.app';
  return httpClientInstance.post(`${domain}/single-token`, body, options).toPromise();

}

export async function tokenValidationRequest(token: string, httpClientInstance): Promise<Object>{
  let body = {
    token: token
  };
  let options = {
  };

  let domain: string = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://auth.fpalpha.com'  : 'https://auth.beta.fpalpha.app'


  return httpClientInstance.post(`${domain}/single-token/validate`, body).toPromise();

}

export function scaleCanvas(canvas, targetWidth: number, targetHeight: number, type:('DOWNNSIZE' | 'FIT') = 'DOWNNSIZE'){

  let scale_factor = Math.min(targetWidth / canvas.width, targetHeight / canvas.height);
  canvas.style.width = (scale_factor * canvas.width) + 'px';
  canvas.style.height = (scale_factor * canvas.height) + 'px';
}
