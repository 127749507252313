
import { Bfs, Node } from './BFS.class';
import { Component, OnInit, Input, SimpleChanges, HostListener, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { calculate2DArrayMaxDimensions, findIndexOfElementInside2DArrary, getRandomInt } from 'app/shared/helpers/utils';
import { GraphMap, GraphNode as MainNode, NodeAnchors, Point, MARGIN_PX, GraphMapConnection, GraphNode, ConnectionNode } from './GraphTool.class';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
@Component({
  selector: 'app-graph-tool',
  templateUrl: './graph-tool.component.html',
  styleUrls: ['./graph-tool.component.scss']
})
export class GraphToolComponent implements OnInit {

  @Input() isPrintable: boolean = false;
  @Input() currentGraph: GraphMap;
  @Input() applyPrintableStyle: boolean;
  @Input() redrawAtEvent: EventEmitter<any> ;
  @Input() globalFlags?: string //String with flags separated by commas
  @Input() size: string = "regular";
  @Input() nodeMinWidthPx: number;
  @Input() tag: string;
  @Input() secondaryTag: string;
  @Output() onAfterRedraw: EventEmitter<any> = new EventEmitter();
  @Output() nodeClicked: EventEmitter<any> = new EventEmitter();

  public internalCurrentGraph: GraphMap;
  public containerDomRect: DOMRect | ClientRect;
  public connectionLines: htmlDivLine[] = [];
  public connectionArrows: htmlDivLine[] = [];
  public connectionsGrid: GraphConnectionNode[] = [];
  public pristineConnectionsGrid: GraphConnectionNode[] = [];
  public mainContainerHtlId: string = '';
  public gridDimensions: ({x: number, y: number});
  public staticNodesStylesDictionary: [] = [];
  public nodeFixedWidth: number;
  public drawEventsCounter: number = 0;
  public hasBeenRedrawn: boolean = false;
  public docTypesArray: string[] = ['Irrevocable', 'wills', 'Revocable'];

  constructor(public sanitizer: DomSanitizer, private cd: ChangeDetectorRef){

  }

  ngOnInit(){
    //console.log('currentGraph**', this.currentGraph);
    if(this.redrawAtEvent !== undefined){
      this.redrawAtEvent.subscribe(data=>{
        //console.log('redrawAtEvent Detected', this.currentGraph);
        this.draw();
        this.hasBeenRedrawn = true;
      })
    }

    if(this.applyPrintableStyle != undefined){
      this.isPrintable = this.applyPrintableStyle
    }

    /*
    let testingData =[
      [{key: 'aa1'}, {key: 'aa2'}, {key: 'aa3'}],
      [{key: 'bb1'}, {key: 'bb2'}, {key: 'bb3'}]
    ]

    console.log('findIndexOfElementInside2DArrary', findIndexOfElementInside2DArrary(testingData, 'key', 'bb3'));
    */

  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }


  /**
   * Recalculate the lines and node positions after a window resize event.
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //this.connectionLines = [];
    //this.connectionArrows = [];
    this.draw();
  }


  /**
   * On any change from the inputed graph data, recalculate and draw
   * @param changes
   * @returns
   */

  ngOnChanges(changes: SimpleChanges) {

    //console.log('changes', changes);

    if (Boolean(changes.currentGraph)){
      let graphMapClone = JSON.parse(JSON.stringify(changes.currentGraph.currentValue));
      if (!validateGraphMap(graphMapClone)) {
        console.log('ERROR. Invalid GraphMap structure')
        return;
      }

      // Each component has its own working copy of the inputed graph data.
      this.internalCurrentGraph = graphMapClone;

      //If the node width is passed as component prop, then use same as the grid prop.
      if(this.nodeMinWidthPx != undefined){
        this.internalCurrentGraph.grid.fixedNodeWidthInPx = this.nodeMinWidthPx;
      }

      //delete this.internalCurrentGraph.grid.fixedNodeWidthInPx;
      /* this.internalCurrentGraph?.grid?.rowsNodesById.map(row =>{
        row.map(idText =>{
          let auxNode: any = this.getNodeById(idText).content;
          if (auxNode.hasOwnProperty('htmlBody')) {
            if (auxNode.htmlBody.contains('<b>')) {
              auxNode.replace('<b>',
              this.isPrintable ? '<b style="font-size: 17px">' : '<b style="font-size: 20px">');
              console.log('AUX NODE CHANGED*', auxNode);

            }
          }
          console.log('Info nodo*', auxNode);
        });
      });
      console.log('current graph*', this.internalCurrentGraph?.grid?.rowsNodesById);
 */

      this.determineGridDimensions();


      //Set the node/box width
      //this.nodeFixedWidth = (this.internalCurrentGraph.grid.fixedNodeWidthInPx === undefined) ?

      //Build static node styles
      this.updateNodesStylesDictionary();

      this.draw();

      setTimeout(()=>{
        //this.connectionLines = [];
        //this.connectionArrows = [];
        this.draw();
      }, 0)
    }

    if(Boolean(changes.globalFlags)){
        //this.connectionLines = [];
        //this.connectionArrows = [];
        this.draw();
    }

  }

  public determineGridDimensions(){
    //Inital calculation, natural dimension by nodes amount
    this.gridDimensions = calculate2DArrayMaxDimensions(this.internalCurrentGraph.grid.rowsNodesById);

      //Account for nodes that have a nodeWidth in the metada.
      let widthInNodesByRow = [];
      this.internalCurrentGraph.grid.rowsNodesById.map(row => {
        widthInNodesByRow.push(0);
        row.map(nodeID =>{
          let node = this.getNodeById(nodeID);
          let nodeWidthMeta = GraphToolComponent.getOrUpdateNodeMetadata(node, 'widthInNodes');
          let nodeWidth = nodeWidthMeta ? nodeWidthMeta : 1;
          widthInNodesByRow[widthInNodesByRow.length-1] += nodeWidth;
        })
      })
      //Account for specific ammount of cols.
      if(this.internalCurrentGraph.grid.maxGridCols == undefined){
        this.internalCurrentGraph.grid.maxGridCols = 0
      }
      // Use the bigges of all the previous
        let theBiggest = Math.max(this.gridDimensions.x, this.internalCurrentGraph.grid.maxGridCols, ...widthInNodesByRow)
        this.gridDimensions.x = theBiggest

      // The minimum required are 2 columns to fit an element that has a width of a quarter.
      if(this.gridDimensions.x == 1 && !this.internalCurrentGraph.grid.fixedNodeWidthInPx) { this.gridDimensions.x = 2; }


      //console.log("Grid Dimentions", this.gridDimensions);
  }

  public draw(){

    this.connectionLines = [];
    this.connectionArrows = [];

    // Set unique htmlIDs for the data html divs. Avoid repeated ids across diferent instances of the same GraphMap.
    this.internalCurrentGraph.nodes.map(node => {this.getHtmlNodeId(node.id);});


    setTimeout(() => { // Wait for the angular template to render before calculating elements position.

      // Get the container rendered properties.
      this.containerDomRect = document.getElementById(this.getContainerHtmlID()).getBoundingClientRect();


      //Calculate the node width as a fixed* value.
      this.nodeFixedWidth = (this.internalCurrentGraph.grid.fixedNodeWidthInPx === undefined) ?
        this.calculateNodeMinWidthBasedOnCurrentGrid() :
        this.internalCurrentGraph.grid.fixedNodeWidthInPx;


      //console.log('nodeFixedWidth', this.nodeFixedWidth);

      //Upadate the nodes width to fit in the grid
      if(this.internalCurrentGraph.nodesType === 'BoxNode' || this.internalCurrentGraph.nodesType === 'FlowChartBox'){
        this.setNodesMinwidth();
        this.updateNodesStylesDictionary()
      }

      //setTimeout(() => {
        // Set the grid for possible path connections.
      this.displayGridPoints();
      // Disconnect from the grid the nodes that overlap with the html data nodes (GraphMap nodes).
      this.disconnectInvasiveNodes();
      // Calculate and generate solution paths inside the grid according to the specified in the GraphMap.
      this.generatePathsFromConnections();
      //console.log('finalGrid', this.connectionsGrid);
      this.drawEventsCounter++;
      if(this.drawEventsCounter > 1 && this.hasBeenRedrawn){
        //console.log('afterRedraw 1');
        this.onAfterRedraw.emit(true);
      }

      //}, 200)

    }, 0);

  }

  public getNodeById(nodeId: string, graphMap: GraphMap = this.internalCurrentGraph): MainNode {
    return graphMap.nodes.find(grapNode => {
      return grapNode.id == nodeId
    })
  }

  public getContainerHtmlID(): string{
   if(!Boolean(this.mainContainerHtlId)){
    this.mainContainerHtlId = 'graph_' + String(getRandomInt(1, 2500))
   }
    return this.mainContainerHtlId;
  }

  public getHtmlNodeId(nodeId: string, graphMap: GraphMap = this.internalCurrentGraph): string {
    let node: MainNode = this.getNodeById(nodeId, graphMap);
    let nodeMetadata = Boolean(node.metaData) ? node.metaData : {};

    if (!(Boolean(nodeMetadata.htmlId))) {
      let htmlUniqueId = nodeId + '_' + this.getContainerHtmlID();
      nodeMetadata['htmlId'] = htmlUniqueId;
      node.metaData = nodeMetadata;
    }

    return node.metaData.htmlId;
  }

  public setNodeStyles(nodeId: string, autoAdjust: boolean = true): any {
    let node = this.internalCurrentGraph.nodes.find(node => node.id == nodeId);
    let inRowStyles = {'z-index': 3}
    let rowNumber = this.internalCurrentGraph.grid.rowsNodesById.findIndex(nodesInRow => { return nodesInRow.findIndex(nodeId_ => { return nodeId_ == nodeId }) >= 0 });

    if (Boolean(this.internalCurrentGraph.grid) && Boolean(this.internalCurrentGraph.grid.rowsMetadata) && Boolean(this.internalCurrentGraph.grid.rowsMetadata[rowNumber]) && Boolean(this.internalCurrentGraph.grid.rowsMetadata[rowNumber].style)) {
      inRowStyles = this.internalCurrentGraph.grid.rowsMetadata[rowNumber].style;
    }

    let adjustedStyles = {}

    /*if(autoAdjust){
      let containerDomRect = document.getElementById(this.getContainerHtmlID()).getBoundingClientRect()
      adjustedStyles = {'width': (containerDomRect.width / this.gridDimensions.x) + 'px'};
      //adjustedStyles['width'] = (this.containerDomRect.width / this.gridDimensions.x) + 'px'
    }*/

    let currentNodeStyleMetadata = GraphToolComponent.getOrUpdateNodeMetadata(node, 'style');
    //Merge row styles with the current node styles. Current node style superoses to row style
    return { ...inRowStyles, ...adjustedStyles, ...currentNodeStyleMetadata }

  }

  public sanitizeNodeStyles(nodeId: string, autoAdjust: boolean = true): SafeStyle{
    return this.sanitizer.bypassSecurityTrustStyle(`${JSON.stringify(this.setNodeStyles(nodeId, autoAdjust))}`)
  }



  public getNodeAnchorPoints(node: MainNode): NodeAnchors {
    if (Boolean(GraphToolComponent.getOrUpdateNodeMetadata(node, 'anchorPoints'))) {
      return GraphToolComponent.getOrUpdateNodeMetadata(node, 'anchorPoints');
    }
    let nodeHTMLElement = document.getElementById(node.metaData.htmlId);
    let nodeDomRect = nodeHTMLElement.getBoundingClientRect();
    let nodeAnchorPoints: NodeAnchors = { T: { x: 0, y: 0 }, R: { x: 0, y: 0 }, B: { x: 0, y: 0 }, L: { x: 0, y: 0 } };
    let nodePosition = { x: nodeDomRect.left - this.containerDomRect.left, y: nodeDomRect.top - this.containerDomRect.top }
    nodeAnchorPoints.T = { x: nodePosition.x + (nodeDomRect.width / 2), y: nodePosition.y };
    nodeAnchorPoints.R = { x: nodePosition.x + nodeDomRect.width, y: nodePosition.y + (nodeDomRect.height / 2) };
    nodeAnchorPoints.B = { x: nodePosition.x + (nodeDomRect.width / 2), y: nodePosition.y + nodeDomRect.height };
    nodeAnchorPoints.L = { x: nodePosition.x, y: nodePosition.y + (nodeDomRect.height / 2) };

    return GraphToolComponent.getOrUpdateNodeMetadata(node, 'anchorPoints', nodeAnchorPoints);
  }

  public getOrUpdateNodeMetadata(node: MainNode, metaKey: string, metaValue?: any): any {
    return GraphToolComponent.getOrUpdateNodeMetadata(node, metaKey, metaValue)
  }

  public static getOrUpdateNodeMetadata(node: MainNode, metaKey: string, metaValue?: any): any {

    let nodeMetadata = {}
    if(node['metaData'] === undefined) return nodeMetadata;

    nodeMetadata = node.metaData;
    if (Boolean(metaValue)) {
      nodeMetadata[metaKey] = metaValue;
      node.metaData = nodeMetadata;
    }

    return nodeMetadata[metaKey];

  }

  /*public generateMapConnections(graphMap: GraphMap = this.internalCurrentGraph) {

    graphMap.connections.forEach(nodeRelatedConnections => {
      nodeRelatedConnections.nodeConnections.forEach(connection => {

        let difX: number = connection.vertices[1].x - connection.vertices[0].x;
        let difY: number = connection.vertices[1].y - connection.vertices[0].y;

        let width: number;
        let height: number;
        let connectionMetadataStyles = connection.metaData['style'] !== undefined ? connection.metaData['style'] : {};

        if (difX == 0) { // Vertical case
          width = 2;
          height = Math.abs(difY);
        } else { // Horizontal case
          width = Math.abs(difX);
          height = 2;
        }

        let line: htmlDivLine = {
          id: nodeRelatedConnections.sourceNodeId + '_' + connection.pointsToNodeId,
          style: {
            'left.px': difX < 0 ? connection.vertices[0].x - Math.abs(difX) : connection.vertices[0].x,
            'top.px': difY < 0 ? connection.vertices[0].y - Math.abs(difY) : connection.vertices[0].y,
            'width.px': width,
            'height.px': height,
            'z-index': "2 important!",
            ... connectionMetadataStyles
          }
        }



        this.connectionLines.push(line);
      })


    })
  }*/

  public generatePathFromNodes(nodes: (MainNode[] | Node[]), color?: string, connectionMetadata?) {

    let connectionMetadataStyles = (connectionMetadata !== undefined && connectionMetadata['style']) ? connectionMetadata['style'] : {};
    let connectionMetadataClasses = (connectionMetadata !== undefined && connectionMetadata['class']) ? connectionMetadata['class'] : {};
    if(connectionMetadata !== undefined && connectionMetadata['class']){
      //console.log('metadata', connectionMetadata);
    }


    nodes.reverse();

    nodes.forEach((n, index) => {

      if (nodes[index + 1] == undefined) return;

      let nodeA = nodes[index];
      let nodeB = nodes[index + 1];
      let difX: number = nodeB.coordinates.x - nodeA.coordinates.x;
      let difY: number = nodeB.coordinates.y - nodeA.coordinates.y;
      let isVertical: boolean = false;

      let width: number;
      let height: number;

      isVertical = (difX == 0) // Vertical case

      if (isVertical) {
        width = this.isPrintable ?  1.4 : 2;
        height = Math.abs(difY);
      } else { // Horizontal case
        width = Math.abs(difX);
        height = this.isPrintable ?  1.4 : 2;
      }

      let line: htmlDivLine = {
        id: 'path_' + nodes[index].id + '_to_' + nodes[index + 1].id + '_u' + getRandomInt(100, 999),
        style: {
          'left.px': (difX < 0 ? nodeB : nodeA).coordinates.x,
          'top.px': (difY < 0 ? nodeB : nodeA).coordinates.y,
          'width.px': width,
          'height.px': height,
          'background-color': color,

          ...connectionMetadataStyles
        },
        class: connectionMetadataClasses
      }

      this.connectionLines.push(line);
    })

    nodes.reverse();
  }

  public addArrowToNodePoint(node: Node, anchorPosition?: string){
    let arrowDirection: string = 'keyboard_arrow_down';
    if(anchorPosition == 'B') {arrowDirection = 'keyboard_arrow_up'}
    else if(anchorPosition == 'R') {arrowDirection = 'keyboard_arrow_left'}
    else if(anchorPosition == 'T') {arrowDirection = 'keyboard_arrow_down'}
    else if(anchorPosition == 'L') {arrowDirection = 'keyboard_arrow_right'}

    let arrow: htmlDivLine = {
      id: 'arrow_' + node.id + getRandomInt(100, 999),
      style: {
        'display': 'block',
        'position': 'absolute',
        'left.px': node.coordinates.x - 10.5,
        'top.px': node.coordinates.y - ((anchorPosition == 'R' ||  anchorPosition == 'L') ? 11 : (anchorPosition == 'B') ? 8.5 : 15),
        'color': this.isPrintable ? 'black' : 'white',
        'z-index': 3
      },
      class: 'graph-tool-arrow',
      content: arrowDirection
    }

    //keyboard_arrow_right left up

    this.connectionArrows.push(arrow);
  }

  public generatePathsFromConnections() {

    let connections = this.internalCurrentGraph.connections;

    connections.map(connection => {

      let sharedCommonNodeSource = null;

      // If the connection has a shared source, we need to determine a common point for the shared source.
      if(Array.isArray(connection.sourceNodeId) && connection.sourceNodeId.length == 2){
        sharedCommonNodeSource = this.determineMidPointForSharedConnectionSource(connection);
      }

      let sourceNodeID = Array.isArray(connection.sourceNodeId) ? connection.sourceNodeId[0] : connection.sourceNodeId;

      let originMainNode = this.getNodeById(sourceNodeID);



      connection.nodeConnections.map(connectionToNode => {

        // For each connection to, see if is needed to avoid the first section to complete
        let jumpFirstSection: boolean = sharedCommonNodeSource != null;

        let destMainNode = this.getNodeById(connectionToNode.pointsToNodeId);

        let originAnchor = connectionToNode.anchorOrigin ? connectionToNode.anchorOrigin : 'B';
        let destAnchor = connectionToNode.anchorDestination ? connectionToNode.anchorDestination : 'T';

        let originNode = (sharedCommonNodeSource != null) ? sharedCommonNodeSource : this.getNearestGridNode(originMainNode, originAnchor);
        let destNode = this.getNearestGridNode(destMainNode, destAnchor);

        if(Boolean(originNode) && Boolean(destNode)){
          let pathSolution = (new Bfs(originNode.id, destNode.id, (connection.isForced ? this.pristineConnectionsGrid : this.connectionsGrid))).solution;
          let rearNode = this.nodeToGridCalculator2(destAnchor, pathSolution[0], destMainNode);//this.nodeToGridCalculator(destAnchor, pathSolution[0]); //this.nodeToGridCalculator2(destAnchor, pathSolution[0], destMainNode);
          let initalNode = this.nodeToGridCalculator2(originAnchor, pathSolution[pathSolution.length - 1], originMainNode); //this.nodeToGridCalculator(originAnchor, pathSolution[pathSolution.length - 1]); //this.nodeToGridCalculator2(originAnchor, pathSolution[pathSolution.length - 1], originMainNode);

          pathSolution.unshift(rearNode); //Add lines for inside the Main Node(Box node) margin
          //Handle the multiple orgin for the first section of the connection.
          if(!jumpFirstSection){
            pathSolution.push(initalNode); //Add lines for inside the Main Node(Box node) margin
          }else{
            jumpFirstSection = false;
          }
          let color = ['', 'green', 'white', 'yellow', 'gray', 'limegreen', 'darkred', 'blue', 'orange', ''][getRandomInt(1, 8)];
          this.generatePathFromNodes(pathSolution, this.isPrintable ? '#C9C9C9' : 'gray', connectionToNode.metaData);
          //if(sharedCommonNodeSource){ console.log('Final pathSolution', pathSolution)}
          //Add arrows for connection type

          if(connectionToNode.type !== undefined){
            //console.log('connectionToNode.type', connectionToNode.type);
            if(connectionToNode.type === 'INFLOW'){
              this.addArrowToNodePoint(rearNode, destAnchor);
            }else if(connectionToNode.type === 'OUTFLOW'){
              this.addArrowToNodePoint(initalNode, originAnchor);
            }
          }
        }else{
          //console.log("WARNING. The system couldn't find a near grid node.");
        }
      });
    });

  }

  /**
   * Generates a path from a Box/Grid Node to a Box/Grid node over the specfic grid.
   * @param originNode boxNode | grid node. ID, anchor
   * @param destNode boxNode | grid node. ID, anchor
   * @param grid The grid as a real graph.
   */
  public generateAlternativePath(originNode: ConnectionNode, destNode: ConnectionNode, grid: Node[]): Node[]{
    let originGridNode: any;
    let destGridNode: any;

    originGridNode = (originNode.anchor === undefined) ? originNode.nodeID :
       this.getNearestGridNode(
        this.getNodeById(originNode.nodeID),
        originNode.anchor
      ).id;

      originGridNode = (destNode.anchor === undefined) ? destNode.nodeID :
      this.getNearestGridNode(
       this.getNodeById(destNode.nodeID),
       destNode.anchor
     ).id;

    return (new Bfs(originGridNode, destGridNode, grid)).solution;
  }

  /**
   * Returns a new node that compensates the margins on the inside of the boxNode / MainNode
   * @param anchor
   * @param nearNode
   * @param margin
   * @returns
   */
  public nodeToGridCalculator(anchor: string, nearNode: Node, margin: number = (MARGIN_PX / 2)): Node {
    let newNode: Node = {
      id: `${nearNode.id}_${anchor}_`,
      coordinates: {x:0, y:0},
      links:{ T: null, R: null, B: null, L: null }
    }

    if(anchor == 'T' || anchor == 'B'){
      newNode.coordinates.x = nearNode.coordinates.x;
      newNode.coordinates.y = nearNode.coordinates.y + ((anchor == 'T' ? 1 : -1 ) * margin);
    }else{
      newNode.coordinates.x = nearNode.coordinates.x + ((anchor == 'L' ? 1 : -1 ) * margin);
      newNode.coordinates.y = nearNode.coordinates.y;
    }

    return newNode

  }

  /**
   * Complete the path from inside a box node element to an anchor point.
   * @param anchor
   * @param nearNode
   * @param boxNode
   * @returns
   */
  public nodeToGridCalculator2(anchor: string, nearNode: Node, boxNode: GraphNode): Node {
    //Project inner lines from p1(near node) to p2 boxNode inner box inside the node.
    let boxNodeHtmlID = this.getHtmlNodeId(boxNode.id);
    let externalBox = document.getElementById(boxNodeHtmlID);
    let innerBox = document.getElementById('innerBox_' + boxNodeHtmlID);

    let externalMargin_x = parseInt(window.getComputedStyle(externalBox).marginTop, 10);
    let externalMargin_y = parseInt(window.getComputedStyle(externalBox).marginRight, 10);

    let internalMargin_x: number = 0;
    let internalMargin_y: number = 0;


    if(innerBox){
      // B or T, depending on anchor the inner meassuremnet changes to be from bottom anchor to the diff in boxes position by its lower position (bottoms). Same for upper part (tops).
      let height_ext = externalBox.getBoundingClientRect()[anchor == 'B' ? 'bottom' : 'top'];
      let height_int = innerBox.getBoundingClientRect()[anchor == 'B' ? 'bottom' : 'top'];
      let width_ext = externalBox.getBoundingClientRect().left;
      let width_int = innerBox.getBoundingClientRect().left;

      internalMargin_y = anchor == 'B' ? (height_ext - height_int) : (height_int - height_ext);
      internalMargin_x = width_int - width_ext;

      /*
      if(anchor == 'B'){
        console.log('BOTTOM CASE:', boxNodeHtmlID ,'external: ', externalBox.getBoundingClientRect().bottom, ',inner:', innerBox.getBoundingClientRect().bottom)
      }
      */
    }

    let newNode: Node = {
      id: `${nearNode.id}_${anchor}_`,
      coordinates: {x:0, y:0},
      links:{ T: null, R: null, B: null, L: null }
    }

    if(anchor == 'T' || anchor == 'B'){

      newNode.coordinates.x = nearNode.coordinates.x;
      newNode.coordinates.y = nearNode.coordinates.y + ((anchor == 'T' ? 1 : -1 ) * (externalMargin_y + internalMargin_y));
    }else{
      newNode.coordinates.x = nearNode.coordinates.x + ((anchor == 'L' ? 1 : -1 ) * (externalMargin_x + internalMargin_x));
      newNode.coordinates.y = nearNode.coordinates.y;
    }

    /*
    if(boxNode.content){
      console.log('nodeToGridCalculator2', 'y: ', externalMargin_y, internalMargin_y, 'x: ', externalMargin_x,  internalMargin_x);
    }
    */

    return newNode

  }

  public getNearestGridNode(mainNode: MainNode, anchorPoint: string): GraphConnectionNode {



    let mainNodeRect = document.getElementById(this.getHtmlNodeId(mainNode.id)).getBoundingClientRect();
    // Get position relative
    let nearsetGridPoint: Point = { x: mainNodeRect.left - this.containerDomRect.left, y: mainNodeRect.top - this.containerDomRect.top };
    // The points are (width and height) + margin
    let width = mainNodeRect.width;
    let height = mainNodeRect.height;
    const adjustedMarign = (MARGIN_PX / 2);

    let adjustedX: number = 0;
    let adjustedY: number = 0;
    if (anchorPoint == 'T') { adjustedX = (width / 2); adjustedY = (adjustedMarign * -1) };
    if (anchorPoint == 'B') { adjustedX = (width / 2); adjustedY = (height + adjustedMarign) };
    if (anchorPoint == 'R') { adjustedX = (width + adjustedMarign); adjustedY = (height / 2) };
    if (anchorPoint == 'L') { adjustedX = (adjustedMarign * -1); adjustedY = (height / 2) };

    let coordinates: Point = { x: nearsetGridPoint.x + adjustedX, y: nearsetGridPoint.y + adjustedY }

    // Find the nearest grid point to the given anchor points.
    // A sensibility range is required due to decimal pixel inaccuracy
    let pxSensibility = 12;

    return this.connectionsGrid.find(n => {
      //Find inside the accuracy range
      let max = {x: n.coordinates.x + pxSensibility, y: n.coordinates.y + pxSensibility};
      let min = {x: n.coordinates.x - pxSensibility, y: n.coordinates.y - pxSensibility};
      return (
        ((coordinates.x >= min.x) && (coordinates.x <= max.x))
        &&
        ((coordinates.y >= min.y) && (coordinates.y <= max.y))
      )
    });
  }

  public displayGridPoints() {


    this.connectionsGrid = this.generateGrid(this.gridDimensions.x, this.gridDimensions.y);
    this.pristineConnectionsGrid = JSON.parse(JSON.stringify(this.connectionsGrid));
    this.connectionsGrid.map(point => {
      let line: htmlDivLine = {
        id: point.id,
        style: {
          'left.px': point.coordinates.x - 1,
          'top.px': point.coordinates.y - 1,
          'width.px': 2,
          'height.px': 2
        }
      }
      // #DEBUG Uncoment the line above to display the grid dots.
      //this.connectionLines.push(line);
    })
  }

  public generateGrid(gridHorizontalNodes: number, gridVerticalNodes: number, gridPointsMultiplier: number = 2): GraphConnectionNode[] {

    let graph: GraphConnectionNode[] = [];
    let horizontalNodes: number = (gridHorizontalNodes * gridPointsMultiplier) + 1;
    let verticalNodes: number = (gridVerticalNodes * gridPointsMultiplier) + 1;

    // Initialize the grid, coordinates will be set later
    for (let nodePosY = 0; nodePosY < verticalNodes; nodePosY++) {

      for (let nodePosX = 0; nodePosX < horizontalNodes; nodePosX++) {

        let links = {
          T: (nodePosY > 0) ? `${nodePosX}_${nodePosY - 1}` : null,
          R: (nodePosX < (horizontalNodes - 1)) ? `${nodePosX + 1}_${nodePosY}` : null,
          B: (nodePosY < (verticalNodes - 1)) ? `${nodePosX}_${nodePosY + 1}` : null,
          L: (nodePosX > 0) ? `${nodePosX - 1}_${nodePosY}` : null,
        }
        graph.push({ id: `${nodePosX}_${nodePosY}`, coordinates: { x: 0, y: 0 }, links })
      }
    }

    //Obtain for each row the node with max height
    let rowsHeight = [];
    this.internalCurrentGraph.grid.rowsNodesById.map(row => {
      let nodesHeight = [];
      row.map(nodeId => {
        let nodeHTMLElement = document.getElementById(this.getHtmlNodeId(nodeId));
        let node_styles = window.getComputedStyle(nodeHTMLElement);
        let node_height = parseInt(node_styles.height) + parseInt(node_styles.marginTop) + parseInt(node_styles.marginBottom);
        nodesHeight.push(node_height);
      })
      rowsHeight.push(Math.max(...nodesHeight));
    })

    this.setGridNodeCoordinates(rowsHeight, graph);
    return graph;

  }

  private setGridNodeCoordinates(gridRowsHeight: number[], grid: GraphConnectionNode[], useNodeWidthPx: number = this.nodeFixedWidth, useCanvasWidthPx?: number) {

    //console.log('setGridNodeCoordinates grid before', JSON.parse(JSON.stringify(grid)))


    // Only a grid with fixed node width requires a specifc margin.
    //Otherwise the margin determined/considered in the nodeFixedWidth. calculateNodeMinWidthBasedOnCurrentGrid()
    let margin = (this.internalCurrentGraph.grid.fixedNodeWidthInPx != undefined) ? 40 : 0;

    let gridWidthInPx = (useCanvasWidthPx === undefined) ? this.containerDomRect.width : useCanvasWidthPx;

    let gridDimensionsInInnerNodes = determineGridDimensionsInNodes(grid);

    // Determine the columns by filter using the first row and counting elements inside the row
    let gridColumns: number = grid.filter(node=>node.id.includes('_0')).length;

    // gridWidth / gridColumns
    let width = (useNodeWidthPx == undefined ? gridWidthInPx : ((useNodeWidthPx + margin) * this.gridDimensions.x))
    let gridInnerNodeSpacer: number = width / (gridDimensionsInInnerNodes.w - 1);
    //console.log(`${width} / ${gridDimensionsInInnerNodes.w} = ${gridInnerNodeSpacer}. GridDimensions ${this.gridDimensions.x}, ${this.gridDimensions.y}`);

    let xPos = 0;
    let yPos = 0;

    let cummulativeHeight: number = 0;

    grid.map((node, index) => {

      //Sumar al acumulado siempre que se cambie de row.
      //El acumulado es el mismo para cada 2 bloques internos.
      //El acumulado es la mitad de lo que indica el bloque superior.

      if (index % gridDimensionsInInnerNodes.w == 0) { xPos = 0 };

      if ((index % gridDimensionsInInnerNodes.w == 0) && index >= gridDimensionsInInnerNodes.w) { //Cambio de row, aplicar logica a partir del 2do row
        let rowIndex = ((yPos / 2) - ((yPos % 2) / 2));
        cummulativeHeight += gridRowsHeight[rowIndex] / 2;
        yPos++;
      }

      node.coordinates = { x: gridInnerNodeSpacer * xPos, y: cummulativeHeight };

      xPos++;
    })

    //console.log("Grid nodes map", grid);

  }

  private disconnectInvasiveNodes() {

    this.internalCurrentGraph.nodes.map(node => {
      if (Boolean(GraphToolComponent.getOrUpdateNodeMetadata(node, 'pathBlocker'))) { return }
      let nodePoints = determineHtmlElementPoints(this.getHtmlNodeId(node.id));
      if(!nodePoints){
        console.warn('The node with the ID ', node.id, ' doesn\'t exist inside the grid');
        return;
      }

      if(nodePoints === undefined) return;
      this.connectionsGrid.map(lineNode => {
        let isLineNodeInside: boolean = false;
        let nodeLinePoints = { x: this.containerDomRect.left + lineNode.coordinates.x, y: this.containerDomRect.top + lineNode.coordinates.y };

        isLineNodeInside = isPointInisideRectangle(nodePoints.p1, nodePoints.p2, nodeLinePoints);

        if (isLineNodeInside) {
          //console.log('Disconnect from BoxNode: ', node.id, 'rectanglePoints:', nodePoints.p1, nodePoints.p2, 'the grid point', lineNode.id, 'at', nodeLinePoints);
          this.disconnetNodeFromGrid(lineNode.id, this.connectionsGrid);
        }
      })
    });
  }

  private disconnetNodeFromGrid(nodeId: string, graph: GraphConnectionNode[]) {
    let nodeIndex = graph.findIndex(node => { return node.id == nodeId });
    let nodeToDelete = graph[nodeIndex];
    let neighborNodes = Object.values(nodeToDelete.links)
      .filter(value => value != null)
      .map(nodeId => graph.find(node => { return node.id == nodeId }));

    neighborNodes.map(neighborNode => {
      Object.keys(neighborNode.links).map(positionKey => {
        if (neighborNode.links[positionKey] == nodeId) neighborNode.links[positionKey] = null;
      })
    })

    graph[nodeIndex]['isDisconnected'] = true;

  }

  public updateNodesStylesDictionary(){
    this.internalCurrentGraph.nodes.map(node=>{
      this.staticNodesStylesDictionary[node.id] = this.setNodeStyles(node.id);
    })
    //console.log(this.staticNodesStylesDictionary);
  }

  // Functions to determine and update a node width to perfect fit inside the grid

  public setNodesMinwidth(minWidth?: number, padding: number = 40){
    minWidth = (minWidth === undefined) ? this.nodeFixedWidth : minWidth;

    this.internalCurrentGraph.nodes.map(node=>{
      let widthInNodes: number = GraphToolComponent.getOrUpdateNodeMetadata(node, 'widthInNodes') !== undefined ? GraphToolComponent.getOrUpdateNodeMetadata(node, 'widthInNodes') : 1;
      //let widthInPx = (q*u)+(u-1)p; u = units in nodes; p = padding; q = unitSizeInPx(minWidth);
      let widthInPx = (this.internalCurrentGraph.grid.fixedNodeWidthInPx != undefined) ?
        (((minWidth + padding) * widthInNodes) - padding ) :
        ((minWidth * widthInNodes) - padding );

        GraphToolComponent.addStyleToNode(node, {width: widthInPx + 'px'});
    })
  }

  public calculateNodeMinWidthBasedOnCurrentGrid(containerWidth: number = this.containerDomRect.width): number{
    //console.log('calculateNodeMinWidthBasedOnCurrentGrid', 'containerWidth:', containerWidth, this.gridDimensions.x)
    return (containerWidth / this.gridDimensions.x);
  }

  public static addStyleToNode(node: MainNode, stylesToBeAdded: any ){
    let oldStyles = GraphToolComponent.getOrUpdateNodeMetadata(node, 'style') !== undefined ? GraphToolComponent.getOrUpdateNodeMetadata(node, 'style') : {};
    let newStyles = {...oldStyles, ...stylesToBeAdded}
    GraphToolComponent.getOrUpdateNodeMetadata(node, 'style', newStyles);
     // console.log(`Update node styles: node[${node.id}], values: ${JSON.stringify(newStyles)}, meta: ${JSON.stringify(node.metaData)}, func: ${this.getOrUpdateNodeMetadata(node, 'style')}`)
  }

  public isFlagDetected(flag: string): boolean{
    return this.globalFlags !== undefined && this.globalFlags.includes(flag);
  }

  showBuilderFromFlowchart(info) {
    //console.log('showBuilderFromFlowchart**', info);
    if (info.docType === 'Irrevocable' || info.docType === 'Revocable' || info.docType === 'wills'){
      this.nodeClicked.emit(info)
    }
  }

  /**
   * Returns a common point in the grid that addresses the case for a mutiple sourceNodeId GraphMapConnection.
   * @param connection
   */
  determineMidPointForSharedConnectionSource(connection: GraphMapConnection): GraphConnectionNode{
    //console.log("Inside determineMidPointForSharedConnectionSource");
    //1. Validate that the source is an array of 2 elements, else return error.
    if(!Array.isArray(connection.sourceNodeId) || connection.sourceNodeId.length !== 2){
      throw new TypeError("The connection doesn't have a common source of two elements");
    }

    let node1ID: string = String(connection.sourceNodeId[0]);
    let node2ID: string = String(connection.sourceNodeId[1]);
    let node1Box: MainNode = this.getNodeById(node1ID);
    let node2Box: MainNode = this.getNodeById(node2ID);

    //2. Find the position for each source node inside the 2D grid.
    let positionNode1 = findIndexOfElementInside2DArrary(this.currentGraph.grid.rowsNodesById, node1ID);
    let positionNode2 = findIndexOfElementInside2DArrary(this.currentGraph.grid.rowsNodesById, node2ID);



    // Horizontal case: The 2 source nodes are in the same row.
    if(positionNode1.row == positionNode2.row){

      let initiaGridNode = this.getNearestGridNode(node1Box, 'R');
      let finalGridNode = this.getNearestGridNode(node2Box, 'L');
      let pathSolution = (new Bfs(initiaGridNode.id, finalGridNode.id, this.connectionsGrid)).solution;
      //console.log('pathSolution', pathSolution);
      this.generatePathFromNodes(pathSolution, 'blue');
      return pathSolution[0];
      //Hacer conexión entre ambos nodos principales

    // Vertical case: The 2 source nodes are in the same column.
    }else if(positionNode1.col == positionNode2.col){

    }else{

    }

    return
  }
}


export interface GraphConnectionNode {
  id: string,
  coordinates: Point,
  links: ({ T: string, R: string, B: string, L: string });
}

export interface htmlDivLine {
  id: string,
  style: any,
  class?: any,
  content?: string //used for the arrows
}


export function isPointInisideRectangle(rectangleP1: Point, rectangleP2: Point, point: Point): boolean {

  rectangleP1.x = Math.round(rectangleP1.x);
  rectangleP1.y = Math.round(rectangleP1.y);
  rectangleP2.x = Math.round(rectangleP2.x);
  rectangleP2.y = Math.round(rectangleP2.y);
  point.x = Math.round(point.x);
  point.y = Math.round(point.y);


  return (point.x > rectangleP1.x && point.x < rectangleP2.x && point.y > rectangleP1.y && point.y < rectangleP2.y)
}

export function determineGridDimensionsInNodes(grid: GraphConnectionNode[]): ({ w: number, h: number }) {
  let response = { w: 0, h: 0 }
  let nextNode = grid[0];
  do {
    response.w++;
    nextNode = grid.find(node => node.id == nextNode.links.R);
  } while (nextNode != undefined)

  nextNode = grid[0];
  do {
    response.h++;
    nextNode = grid.find(node => node.id == nextNode.links.B);
  } while (nextNode != undefined)

  return response;
}

/**
 * Determine the 2 represenative points of the rectangle container
 * @param elementId The HTML id of the element
 * @returns 2 points: p1 top left, p2 bottom right.
 */
export function determineHtmlElementPoints(elementId: string): ({ p1: Point, p2: Point }) {
  let elementById = document.getElementById(elementId);
  if(!elementById){
    console.warn('determineHtmlElementPoints. HTMl element doesn\'t exist', elementId);
    return null;
  }
  let marginTop = parseInt(window.getComputedStyle(elementById).marginTop, 10);
  let marginRight = parseInt(window.getComputedStyle(elementById).marginRight, 10);
  let marginBottom = parseInt(window.getComputedStyle(elementById).marginBottom, 10);
  let marginLeft = parseInt(window.getComputedStyle(elementById).marginLeft, 10);
  if(elementById === null){return undefined}
  let elementRect = elementById.getBoundingClientRect();

  let p1: Point = { x: elementRect.left - marginLeft, y: elementRect.top - marginTop};
  let p2: Point = { x: elementRect.left - marginLeft + elementRect.width + marginRight, y: elementRect.top - marginTop + elementRect.height + marginBottom }
  return { p1, p2 }
}

export function validateGraphMap(graphMap: GraphMap): boolean{
  let allValid: boolean = true;

  if(!graphMap.nodes) return false;

  // Validate that the nodes have a unique array
  let nodes_copy = [... graphMap.nodes];
  graphMap.nodes.map(node => {

   let index = nodes_copy.indexOf(node);
   if(index >= 0){
    nodes_copy.splice(index, 1);
   }

  })

  if(nodes_copy.length > 0){ allValid = false }


  // Validate Grid
  graphMap.grid.rowsNodesById.map(gridRow => {
    gridRow.map(nodeID => {
      if(graphMap.nodes.findIndex(_node => _node.id === nodeID) == -1){
        console.log(`The grid node [${nodeID}], doesn't exist in the node catalog`)
        allValid = false
      }
    })
  })

  //Validate connections

  graphMap.connections.map(connection => {
    if(!Array.isArray(connection.sourceNodeId) && graphMap.nodes.findIndex(_node => _node.id === connection.sourceNodeId) == -1){
      console.log(`Error in connection. The source node [${connection.sourceNodeId}] doesn't exist in the node catalog`)
        allValid = false
    }
    connection.nodeConnections.map(nodeConnection => {
      if(graphMap.nodes.findIndex(_node => _node.id === nodeConnection.pointsToNodeId) == -1){
        console.log(`Error in connection. The destination node [${connection.sourceNodeId} -> ${nodeConnection.pointsToNodeId}] doesn't exist in the node catalog`)
        allValid = false
      }
    })
  })


  return allValid;
}

export function deleteNodesRowWitNoConnections(graph: GraphMap): GraphMap{

  let _graph = JSON.parse(JSON.stringify(graph));

  let nodesIDsInConnection = [];
  _graph.connections.map(connection => {
    nodesIDsInConnection.push(connection.sourceNodeId);
    connection.nodeConnections.map(connectionsTo => {
      nodesIDsInConnection.push(connectionsTo.pointsToNodeId);
    })
  })

  let connectedRowsRelation: boolean[] = [];
  _graph.grid.rowsNodesById.map(row => {
    let currentRowHasNodesConnected: boolean = nodesIDsInConnection.some(connectedID => row.includes(connectedID));
    connectedRowsRelation.push(currentRowHasNodesConnected);
  })

  for(let i = 0; i < _graph.grid.rowsNodesById.length; i++){
    if(connectedRowsRelation[i] == false){
      _graph.grid.rowsNodesById[i] = [];
    }
  }

  _graph.grid.rowsNodesById = _graph.grid.rowsNodesById.filter(row => row.length > 0)


  return _graph;

}

