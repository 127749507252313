import { filter } from "rxjs/operators";
import { Component, Input, OnInit } from "@angular/core";
import { AdvisorService } from "app/shared/services/advisor.service";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import {
  RothV2ReportDataInterface,
  ROTH_PDF_DATA,
  YearByYearComparisionTable1,
  YearByYearComparisionTable2,
} from "./roth-v2-pdf.datainterface";
import {
  cleanNameForPDF,
  delay_ms,
  readFromStoragedObject,
} from "../../../helpers/utils";
import {
  PageData,
  TaxProjectionstReportTemplate,
} from "../../../../views/tax-projections/pdf-report/PdfReport.class";
import { saveAs } from "file-saver";
import { ESettingsService } from "../../../services/e-settings.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { stringify } from "querystring";
import {
  MagicStickyTableInterface,
  MagicStickyTable_EXAMPLE,
} from "../../magic-sticky-table/magicStickyTable.interface";
import { CalculaatorService } from "app/shared/services/calculaator.service";

@Component({
  selector: "app-roth-v2-pdf",
  templateUrl: "./roth-v2-pdf.component.html",
  styleUrls: ["./roth-v2-pdf.component.scss"],
})
export class RothV2PdfComponent implements OnInit {
  @Input() scenario: number = 1;
  @Input() clientId: any;
  @Input() scenarioName: any;
  @Input() pdfTableData: any;
  @Input() scenariosNames: any;

  public DATA_PDF_CONSTANT = ROTH_PDF_DATA;

  public dataPDF: RothV2ReportDataInterface;

  public lineChartType: ChartType = "line";

  public isLoadingPDF: boolean = false;
  public pagesTable1: YearByYearComparisionTable1[][] = [];
  public pagesTable2: YearByYearComparisionTable2[][] = [];
  public summary: any = [null, null, null];
  public onlyOneScenario: boolean = false;
  public stickyTables = [];
  constructor(
    public settingsService: ESettingsService,
    public snackBar: MatSnackBar,
    private advisorService: AdvisorService,
    private calculatorService: CalculaatorService
  ) {}

  ngOnInit(): void {
    setTimeout(async () => {
      const scenarios = await this.getSavedScenarios();
      if (scenarios) {
        await Promise.all(
          scenarios.map(async (scenario) => await this.getRothData(scenario))
        );
        if (this.dataPDF) {
          this.dataPDF.scenarioResume = this.summary;
          this.checkScenarioResume();
        }
      }
    }, 1000);
    this.getReportInfo();
    console.log("transformed DataTable:", this.stickyTables);
  }

  async getReportInfo() {
    try {
      if (!this.pdfTableData) {
        const ans = await this.advisorService.getRothV2PdfData(
          this.clientId,
          this.scenario
        );
        this.pdfTableData = ans?.content;
      }
      this.stickyTables = this.divideDataInTables(this.pdfTableData);
    } catch (e) {
      console.log("ERROR: ", e);
    }
  }

  checkScenarioResume() {
    let nullCount = 0;
    this.summary.map((scenarioResume) => {
      if (scenarioResume === null) {
        nullCount += 1;
      }
    });
    this.onlyOneScenario = nullCount === 2;
  }

  async getSavedScenarios() {
    try {
      const data: any = await this.advisorService.getGeneratedScenarios(
        this.clientId
      );
      const filteredScenarios = [this.scenario];
      if (data) {
        let { scenarios } = data;
        scenarios = scenarios.map((sce) => parseInt(sce, 10));
        scenarios.forEach((sce) => {
          if (sce !== this.scenario) {
            filteredScenarios.push(sce);
          }
        });
        console.log("**", filteredScenarios);
      }
      return filteredScenarios;
    } catch (e) {
      console.log(e);
    }
  }

  labelFormatter(tooltipItem, data) {
    return (
      "$" +
      Number(tooltipItem.yLabel)
        .toFixed(0)
        .replace(/./g, function (c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        })
    );
  }

  async getRothData(scenario: number) {
    try {
      const ans: any = await this.advisorService.getRothV2PdfData(
        this.clientId,
        scenario
      );
      console.log("INFO", ans);
      if (ans) {
        this.summary[scenario - 1] = ans.scenarioResume[0];
        if (scenario === this.scenario) {
          console.log("EL normal");
          this.dataPDF = ans;

          for (
            let i = 0;
            i < this.dataPDF.yearByYearComparisionTable1.length;
            i += 25
          ) {
            this.pagesTable1.push(
              this.dataPDF.yearByYearComparisionTable1.slice(i, i + 25)
            );
            this.pagesTable2.push(
              this.dataPDF.yearByYearComparisionTable2.slice(i, i + 25)
            );
          }
        }
        console.log("this.pagesTable1**", this.pagesTable1);
        console.log("this.pagesTable2**", this.pagesTable2);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async printReport() {
    this.isLoadingPDF = true;

    let scenarios = await this.getSavedScenarios();
    await Promise.all(
      scenarios.map(async (scenario) => await this.getRothData(scenario))
    );
    this.dataPDF.scenarioResume = this.summary;
    console.log("SUMMARY: ", this.summary);
    this.dataPDF.scenarioResume.forEach((_, index) => {
      if (this.dataPDF.scenarioResume[index] != undefined) {
        this.dataPDF.scenarioResume[index].scenarioName =
          this.scenariosNames[index + 1];
      }
    });
    await delay_ms(3000);

    try {
      //let canvases = await this.estateSnapshotPrintableRender.sectionsToCanvas();
      let pagesData: PageData[] = await this.responseToPagesData();

      // await delay_ms(1500);

      if (pagesData.length == 0) {
        throw "No data to be printed";
      }

      let clientsName = `${readFromStoragedObject(
        "currentClient",
        "fullName",
        "Session"
      )}`;

      let companyData = await this.settingsService.getCompanyData().toPromise();
      let fullPageDisclaimer =
        companyData.settings.isFullDisclaimerActive === true
          ? companyData.fullDisclaimer
          : undefined;

      await TaxProjectionstReportTemplate.generateReport({
        clientName: clientsName,
        disclaimer: readFromStoragedObject("advisorPreferences", "disclaimer"),
        logoUrl: companyData.logo,
        reportTitle: "Tax Planning • Roth Conversion Simulator",
        //canvases: canvases,
        //canvasesTitle: this.estateSnapshotPrintableRender.getSectionsTitle(),
        resolved: {},
        pagesData: pagesData?.filter(
          (pageData) => pageData.canvases.length > 0 && pageData.canvases[0]
        ),
        AuxHTMLContainerID: "tempForCanvasRothPDF",
        fullPageDisclaimer,
      }).then((report) => {
        saveAs(
          report.doc.output("blob"),
          `${cleanNameForPDF(clientsName)} - Roth IRA Report`
        );
        this.isLoadingPDF = false;
      });
    } catch (error) {
      this.isLoadingPDF = false;
      console.log(error);
      this.snackBar.open("Unavailable report", "Ok", {
        duration: 7000,
        panelClass: "error-snackbar",
      });
    }
  }

  /**
   * Divide the data of a platform view table in multiple tables adapted for the pdf view
   * @param tableData
   * @returns
   */
  divideDataInTables(
    tableData: MagicStickyTableInterface
  ): MagicStickyTableInterface[] {
    tableData = JSON.parse(JSON.stringify(tableData));

    const rowsMax = 30;
    let tablesDataResult: MagicStickyTableInterface[] = [];

    let newTable = (intialData): MagicStickyTableInterface => {
      let tableCopy: MagicStickyTableInterface = JSON.parse(
        JSON.stringify(intialData)
      );
      tableCopy.fixedToTheRight = [];
      return tableCopy;
    };

    //Add helper category "value to heir and marginal tax rate"

    tableData.categories.push("Value to Heir and Marginal Tax Rate");
    if (tableData.columns["Value to Heir"] != undefined) {
      tableData.columns["Value to Heir"].category =
        "Value to Heir and Marginal Tax Rate";
    }
    if (tableData.columns["Marginal Tax Rate"] != undefined) {
      tableData.columns["Marginal Tax Rate"].category =
        "Value to Heir and Marginal Tax Rate";
    }

    //Rearrange table to fit each category in a new MagicStickyTable with all content inside the fixedToTheLeft prop.
    tableData.categories.map((categoryKey) => {
      //Divide by category

      let tableDataSection: MagicStickyTableInterface = newTable(tableData); //new table with base data
      tableDataSection.categories = [categoryKey];

      let firstKey = Object.keys(tableDataSection.content)[0];
      let rowsCount: number = tableDataSection.content[firstKey].length;
      let chunks = Math.ceil(rowsCount / rowsMax);

      if (chunks == 1) {
        tablesDataResult.push(tableDataSection);
        return;
      }

      //Create as many tables as chunks of the same table are needed
      for (let i = 0; i < rowsCount; i += rowsMax) {
        let _newTable = newTable(tableDataSection);
        _newTable.content = {};
        Object.keys(tableDataSection.content).map((contentKey) => {
          _newTable.content[contentKey] = tableData.content[contentKey].slice(
            i,
            i + rowsMax
          );
        });
        tablesDataResult.push(_newTable);
      }
    });

    //Second Tables: Right Sections of the magic Sticky Table.

    return tablesDataResult;
  }

  async responseToPagesData(): Promise<PageData[]> {
    //let pagesData: PageData[] = [];
    if (!this.dataPDF) {
      return null;
    }

    let pagesData: PageData[] = [
      {
        pageTitle: "",
        canvases: [
          "first-page-section-one",
          "rothGraphPDF",
          "first-page-section-three",
        ],
      },
      { pageTitle: "", canvases: ["assumptions"] },
    ];

    for (let i = 0; i < this.stickyTables.length; i++) {
      pagesData.push({ pageTitle: "", canvases: [`pdfTable_${i}`] });
    }

    /*
    let pages1 = Math.ceil(this.dataPDF.yearByYearComparisionTable1.length / 28);
    for (let i = 0; i< pages1; i++){
      pagesData.push({pageTitle: '', canvases: [`yearByYearTable1_${i}`]})
    }

    let pages2 = Math.ceil(this.dataPDF.yearByYearComparisionTable2.length / 28);
    for (let i = 0; i< pages2; i++){
      pagesData.push({pageTitle: '', canvases: [`yearByYearTable2_${i}`]})
    }
    */

    pagesData.push({ pageTitle: "", canvases: ["other-scenarios"] });

    pagesData.map((pageData) => {
      //Filter for the sections that are visible (not collpsed)
      pageData.canvases = pageData.canvases.map((canvasId) => {
        //Map to the correspondent element in the DOM
        return document.getElementById(canvasId);
      });
    });
    // Avoid blank pages

    pagesData = pagesData.filter(
      (pageData) => pageData.canvases.length >= 1 && pageData.canvases[0]
    );

    console.log("pagesData", pagesData);

    return pagesData;
  }
}
