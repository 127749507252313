import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'util';


@Component({
    selector: 'app-sso',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

    constructor(
        private readonly http: HttpClient,
        private readonly activeRoute: ActivatedRoute,
        private readonly router: Router,
    ) { }

    ngOnInit() {
        const ssoExchangeHost = window.location.host === 'localhost:4400'
            ? 'http://localhost:6060'
            : window.location.host === 'app.beta.fpalpha.app' || window.location.host.includes('localhost')
                ? 'https://api.beta.fpalpha.app/v1'
                : 'https://api.fpalpha.com/v1';

        this.activeRoute.queryParams.subscribe(params => {
            const { token } = params;
            if (token) {
                this.http.post(`${ssoExchangeHost}/sso/exchange`, { token }).toPromise().then((data: any) => {
                    const { accessToken, refreshToken, metadata } = data;
                    console.log({ ssoData: data });
                    if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
                    if (accessToken) localStorage.setItem('sessionToken', accessToken);

                    // MoneyGuidePro Integration Flow
                    if (metadata && metadata.origin === 'integration' && metadata.source === 'mgp') {
                        let data = {
                            fullName: metadata['fullName'],
                            email: metadata['email'],
                            clientStatus: metadata['clientStatus'],
                            status: metadata['status'],
                            origin: metadata['source']
                        };

                        localStorage.setItem("mgp", 'true');
                        localStorage.setItem("mgpData", JSON.stringify(data));

                        this.router.navigate(['/advisor/clients']);
                    }

                    // Orion Integration Flow
                    if (metadata && metadata.origin === 'integration' && metadata.source === 'orion') {
                        let data = {
                            fullName: metadata['fullName'],
                            email: metadata['email'],
                            clientStatus: metadata['clientStatus'],
                            status: metadata['status'],
                            origin: metadata['source']
                        };

                        localStorage.setItem("orion", 'true');
                        localStorage.setItem("orionData", JSON.stringify(data));

                        this.router.navigate(['/advisor/clients']);
                    }
                    
                    if (metadata && metadata.origin === 'integration' && metadata.source === 'salesforce') {
                        this.router.navigate(['/advisor/clients']);
                    }
                }).catch(err => {
                    console.log('ERROR', err);
                });
            }
        });
    }
}
