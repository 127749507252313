import { Component, OnInit, Input } from '@angular/core';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';

@Component({
  selector: 'app-riskalyze-instructions',
  templateUrl: './riskalyze-instructions.component.html',
  styleUrls: ['./riskalyze-instructions.component.css']
})
export class RiskalyzeInstructionsComponent implements OnInit {
  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;
  public isProduction: any;
  public location: any;
  public isRiskalyzeConnected: boolean;
  

  constructor() { }

  ngOnInit() {

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;

    let integrations = JSON.parse(localStorage.getItem('external-providers-interactions'));
    this.isRiskalyzeConnected = integrations.riskalyze;
  }

  connectRiskalyze(){

    let client_id = '';
    let redirectUri = '';

    if(this.location == 'localhost:4200'){
      //Local
      client_id = 'test-fp-alpha'
      redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/riskalyze/callback&'
    }else if(this.isProduction){
      //Production
      client_id = 'fp-alpha'
      redirectUri = 'https://app.fpalpha.com/riskalyze/pending'
    }else{
       //Testing
      client_id = 'test-fp-alpha'
      redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/riskalyze/callback&'
      //redirectUri = 'https://app.beta.fpalpha.app/riskalyze/pending'
    }

    let riskalyzeState = (new Date().getTime()).toString();
    localStorage.setItem('riskalyzeState', riskalyzeState);

    window.location.replace(`https://pro.riskalyze.com/oauthconnect?response_type=code&state=${riskalyzeState}&client_id=${client_id}&redirect_uri=${redirectUri}`)

}

}
