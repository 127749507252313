import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { IntegrationStepService } from 'app/shared/services/integration/integration-step.service';
import { INTEGRATION_STEPS } from 'app/shared/services/integration/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-salesforce-instructions',
  templateUrl: './salesforce-instructions.component.html',
  styleUrls: ['./salesforce-instructions.component.css']
})
export class SalesforceInstructionsComponent implements OnInit {


  private _isIntegrated: any;
  @Input() set isIntegrated(value: any) {
    this._isIntegrated = value;
  }
  
  get isIntegrated(): any {
    return this._isIntegrated;
  }
  @Input() stepper: any;
  @Input() comeFromLogin: any;

  public isLoaded = false

  public isSalesforceConnected: boolean;

  public isCheckedTaskSync:boolean = false;
  public isDisabledCheckedTaskSync: boolean = false;
  public clientListView: boolean = false;
  public isProduction: any;
  public location: any;
  public integrationSteps = INTEGRATION_STEPS

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar,
    public integrationStepService: IntegrationStepService
  ) {}

  ngOnInit() {
    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
    let integrations = JSON.parse(localStorage.getItem('external-providers-interactions'));
    this.isSalesforceConnected = integrations.salesforce;
    
  }

  // When back to itegrations list hide client view
  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  connectSalesforce(){

      let client_id = '';
      let redirectUri = '';

      if(this.location == 'localhost:4200'){
        //Local
        client_id = '3MVG9cHH2bfKACZbB8Nt_VtGsizUKrafz9qYDyjhcV1ClaimPxWd7b3AfSEwvoyvGJs.uzV53Iqd5q1ek9Iku&'
        redirectUri = 'https://api.beta.fpalpha.app/v1/devtools/salesforce/callback&'
      }else if(this.isProduction){
        //Production
        client_id = '3MVG9cHH2bfKACZbB8Nt_VtGsi09B1P80fkx1fr.frSAG.8jFypwJNdqJPtu7JZkGgP0u8A8Mu6u1Z3o4zRyP'
        redirectUri = 'https://app.fpalpha.com/salesforce/pending'
      }else{
         //Testing
        client_id = '3MVG9cHH2bfKACZbB8Nt_VtGsizUKrafz9qYDyjhcV1ClaimPxWd7b3AfSEwvoyvGJs.uzV53Iqd5q1ek9Iku&'
        redirectUri = 'https://app.beta.fpalpha.app/salesforce/pending'
      }

      window.location.replace(`https://login.salesforce.com/services/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirectUri}&response_type=code`)

  }

  syncTaskManager(e){

    this.isDisabledCheckedTaskSync = true;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('syncTaskmanagerAndSalesforce', e.checked);

    this.http.post<any>(`${environment.apiAdvisorAdvisorPreferences}`, body.toString(), options)
      .subscribe(
        success => {
          console.log(success, 'Preferences updated');
          this.isDisabledCheckedTaskSync = false;

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
            duration: 5000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }
}
