import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-redtail-instructions',
  templateUrl: './redtail-instructions.component.html',
  styleUrls: ['./redtail-instructions.component.css']
})
export class RedtailInstructionsComponent implements OnInit {
  @Input() isIntegrated: any;
  @Input() stepper: any;
  @Input() comeFromLogin: any;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public redtailIntegrated: boolean = false;
  public advisorData;
  redtailForm: FormGroup;
  public connecting: boolean = false;

  public isCheckedTaskSync:boolean = false;
  public isDisabledCheckedTaskSync: boolean = false;
  public clientListView: boolean = false;

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarWarning: MatSnackBar,
    public snackBarError: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    //If come from login
    if(this.comeFromLogin){
      this.goToClientListAfterRedtailIntegration();
    }

    //Get actual advisor data
    this.getAdvisorData();

    /*** Redtail ***/
    this.redtailForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
    });

  }

  // When back to itegrations list hide client view
  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  getAdvisorData() {
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          this.advisorData = data;
          this.advisorData.integrations.redtail === true ? this.redtailIntegrated = true : this.redtailIntegrated = false;
          this.advisorData.settings.syncTaskmanagerAndRedtail === true ? this.isCheckedTaskSync = true : this.isCheckedTaskSync = false
        },
        error => {
          console.log(error);
          this.snackBarError.open("Cannot get your data. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        });
  }


  connectNewRedtail(){

    this.connecting = true;
    let connectRedtailAccount = this.redtailForm.value;
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('username', connectRedtailAccount.username);
    body.set('password', connectRedtailAccount.password);

    this.http.post<any>(`${environment.apiRedtailV2}`, body.toString(), options)
      .subscribe(
        success => {
          this.redtailIntegrated = true;
          this.connecting = false;

          console.log(success, 'Redtail Connected');

          this.snackBarSuccess.open("Your Redtail account is now connected to fp alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          let integrations = JSON.parse(
            localStorage.getItem("external-providers-interactions")
          );
          integrations.redtail = true;
          localStorage.setItem("external-providers-interactions", JSON.stringify(integrations))

          // this.router.navigate(['/redtail/success']);
          this.goToClientListAfterRedtailIntegration();
        },
        error => {
          console.log('Error!!!');
          this.connecting = false;
          this.snackBarError.open("Something went wrong connecting your Redtail account. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  disconnectRedtail() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiRedtailDisconnectAccountV2}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'Redtail disconnected');
          this.snackBarSuccess.open("Your Redtail account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.redtailIntegrated = false;
          this.isIntegrated = false;
        },
        error => {
          console.log('Error: Redtail disconnect');
          this.snackBarError.open("Couldn't disconnect your Redtail account. Try again in a moment, if the problem persists contact support." + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
  /* `goToClientListAfterRedtailIntegration` is a method in the `RedtailInstructionsComponent` class
  that sets the `clientListView` property to true and navigates to a specific route in the
  application. In this case, it navigates to the route
  `./settings/external-providers-interactions/redtail` using the Angular `Router` service. This
  method is typically called after successfully integrating a Redtail account to show the client
  list view in the application. */
        }
      )

  }

  goToClientListAfterRedtailIntegration(){
    this.clientListView = true;
    this.router.navigate(['./settings/external-providers-interactions/redtail']);
  }

  syncTaskManager(e){

    this.isDisabledCheckedTaskSync = true;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('syncTaskmanagerAndRedtail', e.checked);

    this.http.post<any>(`${environment.apiAdvisorAdvisorPreferences}`, body.toString(), options)
      .subscribe(
        success => {
          console.log(success, 'Preferences updated');
          this.isDisabledCheckedTaskSync = false;

        },
        error => {
          // console.log'Error!!!');
          this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
            duration: 5000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }
}
