import { filter } from 'rxjs/operators';
import { reset } from '@angular-devkit/core/src/terminal';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, QueryList, ViewChildren } from '@angular/core';
import { element } from 'protractor';
import { cleanNameForPDF, getRandomInt, getRandomStr, readFromStoragedObject } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { exampleEstateV2, responseExample } from './responseExample';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import * as saveAs from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestEditSnapshot } from 'app/shared/model/Edit-Snapshot.model';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

import { MatDialog } from '@angular/material/dialog';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { Router } from '@angular/router';
import { EditEstateSnapshotComponent } from 'app/views/new-summary/areas/estate/edit-estate-snapshot/edit-estate-snapshot.component';
import { GraphMap } from '../graph-tool/GraphTool.class';
import { FLOWCHART_EXAMPLE } from './flowchart-box/flowchart-box.interface';
import { createNode, updateFamilyGraph } from './family-box/updateFamilyGraph';
import { TEST_UPDATED } from './graph-test/estateSnapshotExample';
import { GraphToolComponent } from '../graph-tool/graph-tool.component';
import { graphVerticalCutter } from '../graph-tool-iterative-cutter/graphVerticalCutter';


const SECTIONS_INDEX_ORDERED: string[] = ['Will', 'POA', 'Healthcare', 'Revocable', 'Irrevocable', 'Other'];
const SECTIONS_INDEX_ORDERED_SECOND_SECTION: string[] = ['Revocable', 'Irrevocable'];

interface DocumentType {
  section: ('Will' | 'Revocable' | 'Irrevocable' | 'POA' | 'Healthcare' | 'Other');
  nameDocument: string;
  date: (Date | 'Unknown' | null);
  type: ('Client' | 'Coclient' | 'Joint');
  iconType: string;
}

interface FrontDocs {
  sectionName: string;
  documents: DocumentType[]
}

interface TableFiduciaryPDF {
  section: ('Will' | 'Revocable' | 'POA' | 'Healthcare' | 'Irrevocable');
  type: ('Client' | 'Coclient' | 'Joint');
  title: string;
  titleClass?: string;
  date?: (Date | 'Unknown' | null);
  rows: Row[];
  materialInformation?: string;
}

interface Row {
  textA: string;
  classA: string;
  textB?: string[];
  classB?: string;
  displayBorder?: boolean
}

interface DataTablePDF {
  name: string;
  data: TableFiduciaryPDF[];
}
@Component({
  selector: 'app-estate-snapshot-v3',
  templateUrl: './estate-snapshot-v3.component.html',
  styleUrls: ['./estate-snapshot-v3.component.scss']
})
export class EstateSnapshotV3Component implements OnInit {

  @Input() setData: any;

  @Output() onUpdateStatus: EventEmitter<string> = new EventEmitter();
  @Output() onLoaderStatusChange: EventEmitter<string> = new EventEmitter();
  @Output() onPagesReady: EventEmitter<any> = new EventEmitter();

  @ViewChildren(GraphToolComponent) viewChildren!: QueryList<GraphToolComponent>;

  public documentListOrdered: any;

  public documentList: DocumentType[] = [];
  public dataResponseTables: TableFiduciaryPDF[] = [];

  //public sectionsToShow: string[] = ['Will', 'Revocable', 'POA', 'Healthcare', 'Irrevocable'];
  public sectionsToShow: string[] = ['FIDUCIARIES:WILL', 'FIDUCIARIES:TRUST', 'FIDUCIARIES:DIRECTIVES'];

  public firstSectionPages: any[] = [];
  public dataTable: any = [];
  public dataTableByPages: any = [];
  public allPagesData: any = [];
  public graphMixSectionPages: any = [];
  public responseFromServer: any;
  public isLoading: boolean = true;
  public isLoadingPDF: boolean = false;
  public status: string = 'Not requested';
  public clientName: string = '';
  //public clientID: any;
  public estateSnapshotStatus: any = {};
  public advisorComments: any;
  public disclaimer: any;
  public snapshotVersion: any;
  public flowchartData: GraphMap = FLOWCHART_EXAMPLE;
  public aiGenerated: boolean = false;
  public isProduction;
  public getDownloadOptions = [
    {
      label: "Download Snapshot",
      value: "pdf",
    },
    {
      label: "Download Extractions",
      value: "xls",
    },
  ];
  public isVisibleDownloadOptions = false;
  public numOfDocuments: number = 0;
  public clientId: string = '';

  constructor(
   public advisorService: AdvisorService,
   private http: HttpClient,
   public snackBar: MatSnackBar,
   private advisorServices: AdvisorService,
   private authService: AuthenticationService,
   private dialog: MatDialog,
   public settingsService: ESettingsService,
   public router: Router
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    //this.clientID = this.authService.isAdvisor() ? storagedClient.clientId : localStorage.getItem('userId');
    //this.getAllData();
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    this.loadData(this.setData);
    console.log('flowchartData*', this.flowchartData);
    this.viewChildren.changes.subscribe((r) => {
      console.log('viewChildren', this.viewChildren.length)
    })
  }

  ngAfterViewInit() {
    //Load
    this.viewChildren.changes.subscribe((r) => {
      console.log('viewChildren', this.viewChildren.length)
      if(this.viewChildren.length > 0){
        setTimeout(()=>{
          let withTag = [];
          let splitedMaps = []
          this.viewChildren.map(graphToolComponent => {
          if(graphToolComponent.tag === 'pdfGraphPreview'){
              withTag.push(graphToolComponent);
              console.log('in graph instance', graphToolComponent.getContainerHtmlID());
              try{
                let splitedGraph = graphVerticalCutter(graphToolComponent, 630);
                splitedMaps.push(...splitedGraph);
              }catch(err){
                console.log('doooup');
              }
            }
          })
          console.log('withTag', withTag);
          console.log('splitedMaps', splitedMaps);
          }, 3000)
      }
    })
  }


  /**
   * Loads the data and process it.
   * @param existentData
   */
  async loadData(existentData?){
    console.log('data in loadData***', existentData);
    // this.updateIsLoadingStatusTo(true);
    this.responseFromServer = undefined;

    let handleData = async(data) => {
      console.log('data in handleData**', data);
      this.responseFromServer = await data;

      this.clientName = data.client.fullName;
      console.log('clientName', this.clientName);

      console.log('this.responseFromServer handle**', this.responseFromServer);

      //Transformation for the family tree
      this.responseFromServer.components.map((section: any) => {
        if(section.sectionId === 'FAMILY_TREE'){
          section.data.map(async (familyTreeGraph: any, indexfamilyTreeGraph) => {
            console.log('graph to update**', indexfamilyTreeGraph, familyTreeGraph);
            section.data[indexfamilyTreeGraph] = await updateFamilyGraph(familyTreeGraph);
            console.log('familyTreeGraph updated**', indexfamilyTreeGraph, JSON.parse(JSON.stringify(section.data[indexfamilyTreeGraph])));
          });
        }
      });

      console.log('this.responseFromServer*', JSON.parse(JSON.stringify(this.responseFromServer)));

      let docListServer = this.responseFromServer.components.filter(element => element.sectionType  === 'DOCUMENT_LIST');
      if(docListServer){
        this.documentList = docListServer[0].data;
        console.log('this.documentList server*', this.documentList);
        this.firstSectionPages = this.distributeDocsPerPage(this.documentList);
        if (this.firstSectionPages) {
          this.allPagesData=[...this.firstSectionPages];
        }
      }

      let fiduciariesResponse = this.responseFromServer.components.filter(element => element.sectionType  === 'FIDUCIARIES');
      //console.log('fiduciariesResponse server*', fiduciariesResponse);

      this.dataResponseTables = [];
      fiduciariesResponse.map((section: any) => {
        section['dataTable'] = [{section: section.sectionId, data: section.data}];
        section['data'].map((tableData: any) => {
          tableData['sectionTitle'] = section.sectionTitle;
          tableData['section'] = section.sectionId
        });
        this.dataResponseTables.push(...section.data);
      });

      this.dataTable = this.compileDataBySection(this.dataResponseTables);
      console.log('this.dataResponseTables**', this.dataResponseTables, this.dataTable);;

      //Load Comments section
      let commentsSection = this.responseFromServer.components.filter(element => element.sectionType  === 'COMMENTS');
      if(commentsSection.length > 0){
        let comment = commentsSection[0];
        this.advisorComments = {
          title: comment.data.title,
          comments: comment.data.paragraph,
          type: 'advisorComments'
        }

      }

      //Load Disclaimer section
      let disclaimerSection = this.responseFromServer.components.filter(element => element.sectionType  === 'DISCLAIMER');
      if(disclaimerSection.length > 0){
        let disclaimer = disclaimerSection[0];
        this.disclaimer = {
          isActive: true,
          title: disclaimer.title,
          body: disclaimer.data.paragraph,
          type: 'disclaimer'
        }
      }

      //Load GraphMix sections
      let graphMixSections = this.responseFromServer.components.filter(element => element.sectionType  === 'GRAPH_MIX');
      let graphMixSectionPages = [];
      graphMixSections.map(graphMix => {
        graphMix.data.map((graphMixElement, index) => {
          graphMixSectionPages.push(
            {
              type: 'GRAPH_MIX',
              data: graphMixElement,
              title: graphMix.sectionTitle + ` (${index+1}/${graphMix.data.length})`,

            }
          )
        })
      })
      this.graphMixSectionPages = JSON.parse(JSON.stringify(graphMixSectionPages));
      this.graphMixSectionPages.map(page => {
        page['tag'] = "pdfGraphPreview"
      });
      console.log('graphMixSectionPages', this.graphMixSectionPages);

      setTimeout(()=>{
        this.dataTableByPages = this.orderTablesInPages(this.dataTable);
        console.log('orderTablesInPages', this.dataTableByPages);
        this.allPagesData = this.allPagesData.concat(this.dataTableByPages);
        if(graphMixSectionPages.length){
          this.allPagesData = this.allPagesData.concat(graphMixSectionPages);
        }
        if(this.advisorComments) {
          this.allPagesData = this.allPagesData.concat(this.advisorComments);
        }
        if(this.disclaimer){
          this.allPagesData = this.allPagesData.concat(this.disclaimer);
        }
        console.log('this.allPagesData*****', JSON.parse(JSON.stringify(this.allPagesData)));
        //console.log('advisorComments***', JSON.parse(JSON.stringify(this.advisorComments)));
        this.onPagesReady.emit(this.allPagesData);
        this.updateIsLoadingStatusTo(false);
      }, 800)
      this.updateIsLoadingStatusTo(false);

    }

    // If the data is loaded from the component input, as in the external snapshot.

    if(existentData){
      this.snapshotVersion = existentData.client.version;
      handleData(existentData);
    }else{
      this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
      let isMarried = readFromStoragedObject('currentClient', 'isMarried', 'Session') === 'Married';
      let clientIndex = 1;
      if(isMarried){
        let serviceResponse: any = await this.advisorService.getAreaSnapshotMetaData(this.clientId, 1, 'estate');
        if(serviceResponse.status === 'Not requested'){
          clientIndex = 2;
        }
      }
      const serviceResponse: any = await this.advisorService.getAreaSnapshotMetaData(this.clientId, clientIndex, 'estate');
      console.log('serviceResponse**', serviceResponse);
      this.aiGenerated = serviceResponse.aiGenerated;
      this.estateSnapshotStatus = serviceResponse.status;
      await this.advisorService.getEstateSnapshotData(this.clientId, clientIndex).then( async (response: any) => {
        this.snapshotVersion = response.client?.version;
        //this.estateSnapshotStatus = response.status;
        console.log('this.snapshotVersion*', this.snapshotVersion, this.estateSnapshotStatus, response);
        if(this.snapshotVersion !== 4){
          this.isLoading = false;
          this.updateIsLoadingStatusTo(false);
        }else if(this.snapshotVersion === 4){
          this.numOfDocuments = response.hasOwnProperty('components') ? response.components.length : response.documents.length;;
          if(!this.numOfDocuments){
            this.estateSnapshotStatus = 'In review';
            this.updateIsLoadingStatusTo(false);
          }else{
            handleData(response);
          }

        }
      })
    }
  }

  updateIsLoadingStatusTo(isLoading: boolean){
    this.isLoading = isLoading;
  }


  distributeDocsPerPage(listDocs: any){
    console.log('All sections', JSON.parse(JSON.stringify(listDocs)));
    let pages = [[]];
    let pagesFormatted = [];
    let maxDocsPerPage: number = 11;

    let distributeDocsOfSameSectionInPages = (docsListInPairs: any[]) =>{
      let currentPage = pages[pages.length - 1];
      let remainRowsInPage = maxDocsPerPage - currentPage.length;
      // console.log('docsListInPairs*', docsListInPairs);

      let subsequentElementsQty = docsListInPairs.length - remainRowsInPage;

      //Los elementos entran en la pagina actual.
      if(remainRowsInPage > 0){
        //Insertar elementos tantos como espacio disponible tenga la pagina actual.
        currentPage.push(...docsListInPairs.slice(0,remainRowsInPage));
        if(subsequentElementsQty > 0){ //Todavia restan elememtos por distribuir?
          distributeDocsOfSameSectionInPages(docsListInPairs.slice(-subsequentElementsQty))
        }else{ //Ya no hay elemento por procesar, retornar
          return;
        }
      }else{
        pages.push([]);
        distributeDocsOfSameSectionInPages(docsListInPairs)
      }
    }

    SECTIONS_INDEX_ORDERED.map(sectionIndex => {
      let docsListInPairs: any[] = [];
      let sectionDocs = listDocs.filter(doc => doc.section === sectionIndex);
      let docsClient = sectionDocs.filter(doc => doc.type === 'Client');
      let docsCoClient = sectionDocs.filter(doc => doc.type === 'Co-Client');
      let longestDocsList = docsClient.length > docsCoClient.length ? docsClient : docsCoClient;

      longestDocsList.map((doc, index) => {
        docsListInPairs.push([docsClient[index], docsCoClient[index]]);
      });

      if(longestDocsList.length === 0){
        let emptyDocument: any = {
          section: sectionIndex,
          nameDocument: 'No document uploaded',
          date: new Date(),
          type: 'No docs',
          iconType: 'pdf',
        };
        // insert doc with other type
        docsListInPairs.push([emptyDocument])
        // console.log('Section without items*', sectionIndex, docsListInPairs);
      }

      distributeDocsOfSameSectionInPages(docsListInPairs);
    });


    let docsJoint = listDocs.filter(doc => doc.type === 'Joint');
    // console.log('joint docs*', docsJoint, listDocs);
    if(docsJoint.length) {
      pages.push([]);
      SECTIONS_INDEX_ORDERED_SECOND_SECTION.map( section => {
        let sectionDocs = listDocs.filter(doc => doc.section === section && doc.type === 'Joint');
        distributeDocsOfSameSectionInPages(sectionDocs);
      });
    }


    pages.map((page: any) => {
      let auxItemsPerPage: any = {
        type:'listDocs',
        itemsPerPage: []
      };
      page.map((row: any) => {
        if(row.length){
          let items = row.filter(item => item !== null && item != undefined);
          auxItemsPerPage.itemsPerPage.push(...items);
        }else{
          auxItemsPerPage.itemsPerPage.push(row)
        }
      });
      pagesFormatted.push(auxItemsPerPage);
    });
    // console.log('pagesFormatted*****', JSON.parse(JSON.stringify(pagesFormatted)));


    // console.log('Pages*****', JSON.parse(JSON.stringify(pages)));
    return pagesFormatted;

  }

  compileDataBySection(dataTable): DataTablePDF[] {
    // console.log('compileDataBySection*', dataTable);
    let dataPDF = [];
    this.sectionsToShow.map(section => {
      let auxPage = {
        type: 'table',
        section,
        itemsPerPage: []
      }
      let auxSection = dataTable.filter(section_ => section_.section === section);
      if (auxSection.length > 0) {
        auxPage.itemsPerPage.push({
          section,
          data: auxSection
        });
        dataPDF.push(auxPage)
        // console.log('SECTION*', JSON.parse(JSON.stringify(auxPage)));
      }
    });
    return dataPDF;
  }

  distributeTableSectionInPages(sectionData): Page[]{
    console.log('sectionData', sectionData);
    let pages: Page[] = [new Page()];
    const subSectionsKeys = ["Joint", "Client", "Co-Client"];

    subSectionsKeys.map(subSectionKey =>{
      let subSectionKeyLowerCase = subSectionKey.toLowerCase();
      let subSectionTables = sectionData.filter(section=>{return section.type === subSectionKey} );
      // console.log('subSectionTables', subSectionKey, subSectionTables);

      subSectionTables.map((element: any, i) => {
        let itemAdded: boolean = false;
        // console.log(`element* -> ${element.section}_${subSectionKeyLowerCase}_${i}`);
        pages.map((page: Page, indexPage)=> {
          // console.log('Page*', indexPage, page, 'added', itemAdded);
          page.section = element.section;
          if(!itemAdded){
            let htmlElement = document.getElementById(`${element.section}_${subSectionKeyLowerCase}_${i}`);
            if(htmlElement == undefined){ return }
            let elementHeigth = htmlElement.clientHeight;
            let spaceFree = page.getAvailableSpace(subSectionKeyLowerCase) - elementHeigth;
            // console.log('spaceFree', spaceFree, 'spacePage', page.getAvailableSpace(subSectionKeyLowerCase), 'htmlElement' + element.section +'_'+ subSectionKeyLowerCase +'_'+ i, htmlElement.clientHeight);
            if (spaceFree >= 0) {
              page.updateSpace(subSectionKeyLowerCase, elementHeigth);
              page.data.push(element)
              itemAdded = true;
            }else if (indexPage === pages.length - 1){
              let newPage = new Page();
              newPage.data.push(element);
              newPage.section = element.section;
              newPage.updateSpace(subSectionKeyLowerCase, elementHeigth);
              pages.push(newPage);
              // console.log('page.getSpace', page.getAvailableSpace(subSectionKeyLowerCase), 'Pages updated', pages);
              itemAdded = true;
            }
            // console.log('Se añadió?', itemAdded , 'Estamos en page', indexPage);
          }
        });
      });
    })
    return pages;
  }

  async advisorTemporalSingleToken(): Promise<string> {

    let currentSessionToken = sessionStorage.getItem('sessionToken');
    let advisorId = localStorage.getItem('userId');
    let advisorName = readFromStoragedObject('advisorInfo', 'fullName', 'Local');
    let advisorEmail = readFromStoragedObject('advisorInfo', 'email', 'Local');


    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentSessionToken}`,
      }),
      responseType: 'text' as 'text'
    };

    let clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');

    let body = {
        "clientId": clientId,
        index: "1",
        "advisor": {
          "_id": advisorId,
          "email": advisorEmail,
          "fullName": advisorName
        },
    }

    let domain = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://auth.fpalpha.com' : 'https://auth.beta.fpalpha.app';
    return this.http.post(`${domain}/single-token`, body, options).toPromise();

  }

  orderTablesInPages(dataTables){
    let pages: Page[] = [];
    let pagesFormatted = [];

    Object.keys(dataTables).map((key)=> {
      dataTables[key].itemsPerPage.map((item: any) => {
        //se itera por cada seccion encontrada
        // console.log('dataTables[key].itemsPerPage*', dataTables[key].section,  dataTables[key].itemsPerPage);
        let distributedElement = this.distributeTableSectionInPages(item.data);
        console.log('distributedElement', distributedElement);
        pages.push(...distributedElement)
      });
    });
    // console.log('PAGES**', JSON.parse(JSON.stringify(pages)));
    pages.map((page: Page) => {
      //console.log('page to format*', page);
      let auxPage = {
        type: 'table',
        section: page.section,
        itemsPerPage: [{
          section: page.section,
          data: page.data
        }]
      }
      // console.log('auxPage order**', JSON.parse(JSON.stringify(auxPage)));
      pagesFormatted.push(auxPage);
    });

    return pagesFormatted;
  }

  getTablesByType(type: string, tables: TableFiduciaryPDF[]): TableFiduciaryPDF[] {
    if (tables) {
      return tables.filter(table_ => table_.type === type);
    }
  }

  changeStatusSection(section){
    section.isCollapsed = !section.isCollapsed;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(Boolean(changes.setData)){
      this.loadData(changes.setData.currentValue);
      console.log('setData Updated:', changes.setData.currentValue);
    }
  }

  /**
   * Downloads the PDF from the PDF service.
   * The PDF service requires the url from the external review page.
   * The external review page requires an advisor single token.
   */
  async downloadPDF(){
    this.isLoadingPDF = true;
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      params: null,
      responseType: 'blob'
    };

    let token = await this.advisorTemporalSingleToken();

    console.log('Temp token', token);

    let body = new URLSearchParams();
    //https://app.beta.fpalpha.app/external-snapshot-preview?area=estate&token=${token}`);
    body.set('remoteURI', `https://${this.isProduction ? 'app.fpalpha.com' : 'app.beta.fpalpha.app'}/external-snapshot-preview?area=estate&token=${token}`);

      this.http.post(`https://api-beta.${this.isProduction ? 'fpalpha.com' : 'fpalphatesting.com'}/v2/report/estate-snapshot`, body.toString(), options)
      .subscribe((blob:any) => {
        this.isLoadingPDF = false;
        saveAs(blob, `Estate_Snapshot_Report_${cleanNameForPDF(this.clientName)}.pdf`);
      },
      error => {
        this.snackBar.open("Error: The report isn't available in this format at the moment", "Ok", {
          duration: 7000,
          panelClass: "error-snackbar",
        });
        this.isLoadingPDF = false;
      });

  }

  editEstateSnapshot(){
    let component = this.dialog.open(EditEstateSnapshotComponent, {
      panelClass: "modal-dialog-black",
      width: "100%",
      disableClose: true
    })
    component.componentInstance.handleOnSave.subscribe((resp: RequestEditSnapshot)=>{
      this.updateSnapshotData(resp).then((resp: RequestEditSnapshot | any)=>{
        this.estateSnapshotStatus = ''
        //this.estateSnapshotPrintableRender.loadData();
        setTimeout(() => {
          this.estateSnapshotStatus = 'Ready'
        }, 100);
        if (resp.message) {
          this.snackBar.open(resp.message, "OK", {
            duration: 2000,
            panelClass: 'success-snackbar'
          }).afterDismissed().subscribe(()=>{
            if (resp.changesDenied.length > 0) {
              resp.changesDenied.forEach((element, i) => {
                setTimeout(() => {
                  this.snackBar.open(element.message, "Dismiss", {
                    duration: 2000,
                    panelClass: 'error-snackbar'
                  })
                }, 2000*i);
              });
            }

          })
          component.close()
        }
      }).catch((arg) => {
        component.close()
        this.snackBar.open(arg.message, "Dismiss", {
          duration: 2000,
          panelClass: 'error-snackbar'
        })
      })
    })
    component.afterOpened().subscribe(()=>{
      this.loadSnapshotData().then((resp: RequestEditSnapshot)=>{
        let editedResponse = this.calculateRequiredProps(resp);
        component.componentInstance.snapshotData = editedResponse;
      }).catch((arg) => {
        component.close()
        this.snackBar.open(arg.message, "Dismiss", {
          duration: 2000,
          panelClass: 'error-snackbar'
        })
      })
    })
    component.afterClosed().subscribe(()=>{
      this.isLoading =  true;
      //<--- Update data from sources. Delete maybe?
      this.settingsService.getCompanyData().toPromise().then((resp: any) => {
        console.log('resp disclaimer', resp);
        this.disclaimer ={
          isActive: resp.settings.isFullDisclaimerActive,
          title: resp.fullDisclaimer.title,
          body: resp.fullDisclaimer.disclaimer,
          type: 'disclaimer'
        }
      });
      this.loadSnapshotData().then((resp: any) => {
        this.advisorComments = resp.advisorComments;
        this.advisorComments['type'] = 'advisorComments'
      });

      this.loadData(this.setData);
      //-->

    });
  }

  calculateRequiredProps( obj : RequestEditSnapshot) {
    // If the first value of first/lastName is empty,
    // the field is not required
    obj.familyTree.relationships = obj.familyTree.relationships.map((item) => {
      let obj = item
      obj.firstNameIsRequired = obj.firstName != '';
      obj.lastNameIsRequired = obj.lastName != '';
      return obj;
    })
    obj.familyOther.relationships = obj.familyOther.relationships.map((item) => {
      let obj = item
      obj.firstNameIsRequired = obj.firstName != '';
      obj.lastNameIsRequired = obj.lastName != '';
      return obj;
    })
    return obj
  }

  loadSnapshotData(){
    let clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    return this.advisorComments = this.advisorServices.getEstateSnapshot(clientId)
  }

  updateSnapshotData(body){
    let clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    return this.advisorServices.updateEstateSnapshot(clientId, body)
  }

  toogleDownloadOptions() {
    this.isVisibleDownloadOptions = !this.isVisibleDownloadOptions;
  }


  getDownloadText() {
    return this.isLoading
      ? "Waiting for data "
      : this.isLoadingPDF
      ? "Downloading..."
      : "Download File";
  }

  goBack() {
    this.router.navigate(["/advisor/summary_new"]);
  }

  download(type: string) {
    if (type == "pdf") {
      this.downloadPDF();
    }
    if (type == "xls") {
      this.downloadEstateSnapshotExtractions();
    }
    this.toogleDownloadOptions();
  }

  downloadEstateSnapshotExtractions() {
    this.isVisibleDownloadOptions = false;
    this.isLoadingPDF = true;
    this.advisorServices
      .getXLSReport(this.clientId, 1)
      .then((arg) => {
        this.isLoadingPDF = false;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(arg);
        link.download =
          "FP Alpha - Estate Snapshot extractions for " +
          this.clientName +
          ".xlsx";
        link.click();
      })
      .catch((arg) => {
        this.isLoadingPDF = false;
        this.snackBar.open("Error: Extractions are not available in this format at the moment", "Ok", {
          duration: 7000,
          panelClass: "error-snackbar",
        });
      });
  }
}

export class Page {
  jointSpace: number = 0;
  clientSpace: number = 0;
  'co-clientSpace': number = 0;
  data: any[] = [];
  maxHeigth = 620 - 55;
  section: string;

  getAvailableSpace(type: ('joint' | 'client' | 'co-client' | string)){
    let spaceOfType = type !== 'joint' ? this.jointSpace + this[type + 'Space'] : this.jointSpace;
    return this.maxHeigth - spaceOfType;
  }

  updateSpace(type: ('joint' | 'client' | 'co-client' | string), space: number){
    this[type + 'Space'] = this[type + 'Space'] + space;
  }

}

